<ng-container *ngIf="config" [ngSwitch]="config.type">

    <lib-safire-button-group *ngSwitchCase="'button-group'" [title]="config.config.title" [settingsId]="config.config.settingsId"
                             [btnIcon]="config.config.iconUrl" [showAll]="config.config.showAll !== false">
      <app-control-wrapper *ngFor="let _config of config.config.buttons" [config]="_config"
                           [state]="state[_config.id]"
      ></app-control-wrapper>

    </lib-safire-button-group>

    <ng-container *ngSwitchCase="'utm-menu-table'">
    <lib-utm-menu-table *libSafireMenuContent ></lib-utm-menu-table>
    </ng-container>

     <ng-container *ngSwitchCase="'utm-table-overlay'">


    <lib-safire-button *ngSwitchCase="'utm-table-overlay'"
                       [embedded]="false"
                       [state]="state?ON:OFF"
                       [title]="config.config.title"
                       [btnAlt]="config.config.title"
                       [btnIcon]="config.config.iconUrl"


    >
      <lib-utm-table-overlay *libSafireButtonContent [active]="true" (closed)="setState(OFF, true)"></lib-utm-table-overlay>
    </lib-safire-button>
     </ng-container>

    <lib-safire-button *ngSwitchCase="'btn.czml.stomp'"
                       [embedded]="false"
                       [state]="state?ON:OFF"
                       [title]="config.config.title"
                       [btnAlt]="config.config.title"
                       [btnIcon]="config.config.iconUrl"


    >
      <lib-stomp-listener *libSafireButtonContent [exchange]="config.config.exchange"
                          [legendConfig]="config.config.legendConfig"
                          (terminated)="handleBtnClose()"
      ></lib-stomp-listener>

    </lib-safire-button>


    <lib-safire-button *ngSwitchCase="'btn.czml.rest'"
                       [embedded]="false"
                       [state]="state?ON:OFF"
                       [title]="config.config.title"
                       [btnAlt]="config.config.title"
                       [btnIcon]="config.config.iconUrl"
    >
      <lib-czml-rest-loader *libSafireButtonContent [url]="config.config.url"
                            [refreshInterval]="config.config.refreshInterval"
                            [legendConfig]="config.config.legendConfig"
                            (terminated)="handleBtnClose()"
      ></lib-czml-rest-loader>

    </lib-safire-button>


    <lib-safire-button *ngSwitchCase="'btn.measure.distance-finder'"
                       [embedded]="false"
                       [displayMode]="config.config.showLabel === false? CELL :CELLTEXT"
                       [state]="state?ON:OFF"
                       [title]="config.config.title"
                       [btnAlt]="config.config.title"
                       [btnIcon]="config.config.iconUrl"

    >
      <lib-distance-finder *libSafireButtonContent [distanceFinderActive]="true" (onClose)="setState($event?ON:OFF)"></lib-distance-finder>
    </lib-safire-button>
    <lib-safire-button *ngSwitchCase="'btn.measure.range-ring'"
                        [displayMode]="config.config.showLabel === false? CELL :CELLTEXT"
                       [embedded]="false"
                       [state]="state?ON:OFF"
                       [title]="config.config.title"
                       [btnAlt]="config.config.title"
                       [btnIcon]="config.config.iconUrl"

    >
      <lib-range-ring *libSafireButtonContent [active]="true"></lib-range-ring>
    </lib-safire-button>
    <lib-safire-button *ngSwitchCase="'btn.measure.point-selection'"
                       [embedded]="false"
                       [displayMode]="config.config.showLabel === false? CELL :CELLTEXT"
                       [state]="state?ON:OFF"
                       [title]="config.config.title"
                       [btnAlt]="config.config.title"
                       [btnIcon]="config.config.iconUrl"

    >
      <lib-point-selection *libSafireButtonContent [pointSelectActive]="true" (onClose)="setState($event?ON:OFF)"></lib-point-selection>
    </lib-safire-button>

    <lib-attribution-icon *ngSwitchCase="'attribution'"
                          [title]="config.config.title"
                          [iconAlt]="config.config.iconAlt"
                          [linkUrl]="config.config.linkUrl"
                          [iconUrl]="config.config.iconUrl">

    </lib-attribution-icon>

    <ng-container *ngSwitchCase="'bookmarks'">
      <lib-bookmarks
        [loadFromAssetServer]="config.config.loadFromAssetServer !== false"
        [bookmarks]="config.config.bookmarks"
                     (closed)="handleBtnClose()"
      ></lib-bookmarks>
      <!--<lib-bookmarks *ngIf="!config.config.bookmarks || config.config.bookmarks.length === 0"
                     [loadFromAssetServer]="config.config.loadFromAssetServer !== false"
                     (closed)="handleBtnClose()"
      ></lib-bookmarks>-->
    </ng-container>
    <!--<lib-dart-config *ngSwitchCase="'dart-config'">

    </lib-dart-config>-->
    <!--<ng-container *ngSwitchCase="'dart-config'">

    </ng-container>-->

    <!--<lib-safire-button *ngSwitchCase="'czml.stomp'"
                       [embedded]="false"
                       [state]="state"
                       [title]="config.config.title"
                       [btnAlt]="config.config.title"
                       [btnIcon]="config.config.iconUrl"

    >
      <lib-stomp-listener *libSafireButtonContent [exchange]="config.config.exchange"
                          [legendConfig]="config.config.legendConfig"></lib-stomp-listener>

    </lib-safire-button>-->
    <!--<lib-safire-button *ngSwitchDefault
                       [embedded]="false"
                       [state]="state"
                       [title]="'error'"
                       [btnAlt]="'error'"
                       [btnIcon]="config.config.iconUrl"

    ></lib-safire-button>-->

</ng-container>

import {Injectable} from '@angular/core';
import {StompListenerComponent} from '../czml/stomp-listener/stomp-listener.component';
import * as Cesium from "cesium";
import {GeoidHeightService} from "./geoid-height.service";
// import {CesiumService} from '@ax/ax-angular-map-cesium';
// import {Cartesian3, CzmlDataSource, HeadingPitchRoll, Viewer} from 'cesium';
// import {CesiumComponent} from './CesiumComponent';

@Injectable({
  providedIn: 'root'
})
export class DartService {
  // Created a global list for use in clearing stale data/entities by ID
  public allIDs = [];
  private alertTone = new Audio('/assets/audio/acas_alert.mp3');
  static mutedAlerts = []; // A list of individual IDs to not play alerts for (format: <sensor>-<Track ID>-alert)
  static playTone = true; // A bool for global muting
  constructor(private heightService: GeoidHeightService) {
  }

  // Parse the incoming data and serialize it into CZML for cesium drawing display
  parseSerialize(obj): any {
    let show3 = false;
    let show2 = false;
    let show1 = false;
    // Format the incoming IDs to be used for various processes below
    let trackId = obj.track_alert_id;
    let muteId = obj.track_alert_id.split('-');
    muteId.pop();
    muteId.shift();
    muteId = muteId.join('-');
    let posData = [{cartographicDegrees: [obj.longitude, obj.latitude, obj.altitude]}];
    const zoneIncursions: any = this.generateIncursions(obj);

    // Initialize all show variables to true if bool in incoming data object is true
    if (obj.threat_lvl_1_draw === true) {
      show1 = true;
    }
    if (obj.threat_lvl_2_draw === true) {
      show2 = true;
    }
    if (obj.threat_lvl_3_draw === true) {
      show3 = true;
    }
    // Serializing the IDs into a consistent format
    trackId = obj.track_alert_id.replace('-alert', '');
    // if (obj.track_alert_id.includes('ninja') || obj.track_alert_id.includes('dowding')) {
    //   trackId = trackId + '-track';
    // }
    if (trackId in StompListenerComponent.posData) {
      posData = [];
      posData.push(StompListenerComponent.posData[trackId].pos);
    }

    // Play audible alerts if the global bool is true and the track ID is not ignored
    if (DartService.playTone) {
      if (!DartService.mutedAlerts.includes(muteId.replace('-track', '')
        .replace('_heading', '')
        .replace('_tail', ''))) {
        this.alertTone.play();
      }
    }


    // Assign new IDs to each alert radius bubble prepending track ID
    this.allIDs.push(String(obj.track_alert_id) + '_radius_1');
    this.allIDs.push(String(obj.track_alert_id) + '_radius_2');
    this.allIDs.push(String(obj.track_alert_id) + '_radius_3');

    // Construct CZML for DART alert bubbles
    const czml = [
      {
        id: String(obj.track_alert_id) + 'anti_flicker',
        position: posData,
        ellipsoid: {
          show: true,
          radii: {
            cartesian: [obj.radius_1, obj.radius_1, obj.radius_1],
          },
          fill: true,
          material: {
            solidColor: {
              color: {
                rgba: [0, 0, 0, 0],
              },
            },
          },
        },
      },

      {
        id: "document",
        name: "dart alerts",
        version: "1.0",
      },
      {
        id: String(obj.track_alert_id) + '_radius_1',
        type: 'dartbubble',
        name: String(obj.track_alert_id) + ' Radius 1',
        timestamp: obj['timestamp'],
        position: posData,
        ellipsoid: {
          show: show1,
          radii: {
            cartesian: [obj.radius_1, obj.radius_1, obj.radius_1],
          },
          outline: true, // Toggle to change outlines.
          outlineColor: {
            rgba: [255, 0, 0, 145]
          },
          outlineWidth: 3.0,
          fill: true,
          material: {
            solidColor: {
              color: {
                rgba: [255, 0, 0, 128],
              },
            },
          },
        },
      },
      {
        id: String(obj.track_alert_id) + '_radius_2',
        type: 'dartbubble',
        name: String(obj.track_alert_id) + ' Radius 2',
        timestamp: obj['timestamp'],
        position: posData,
        ellipsoid: {
          show: show2,
          radii: {
            cartesian: [obj.radius_2, obj.radius_2, obj.radius_2],
          },
          outline: true, // Toggle to change outlines.
          outlineColor: {
            rgba: [255, 128, 0, 145]
          },
          outlineWidth: 3.0,
          fill: true,
          material: {
            solidColor: {
              color: {
                rgba: [255, 128, 0, 64],
              },
            },
          },
        },
      },
      {
        id: String(obj.track_alert_id) + '_radius_3',
        type: 'dartbubble',
        name: String(obj.track_alert_id) + ' Radius 3',
        timestamp: obj.timestamp,
        position: posData,
        ellipsoid: {
          show: show3,
          radii: {
            cartesian: [obj.radius_3, obj.radius_3, obj.radius_3],
          },
          outline: true, // Toggle to change outlines.
          outlineColor: {
            rgba: [255, 255, 0, 145]
          },
          outlineWidth: 3.0,
          fill: true,
          material: {
            solidColor: {
              color: {
                rgba: [255, 255, 0, 32],
              },
            },
          },
        },
      },
    ];

    // For all zone incursions cached push them to the czml
    for (let i = 0; i < zoneIncursions.length; i++) {
      czml.push(zoneIncursions[i]);
    }
    // For each infrastructure in data push the ID to the global list for clear stale
    for (let i = 0; i < obj.infrastructure.length; i++) {
      this.allIDs.push(obj.infrastructure[i].id);
      czml.push(obj.infrastructure[i]);
    }
    for (let i = 0; i < obj.utm.length; i++) {
      this.allIDs.push(obj.utm[i].id);
      czml.push(obj.utm[i]);
    }
    // This is expired code when UTM objects from dart were still getting processed thru this service
    // For all UTM objects in data set up an invisible anti-flicker duplicate and push the IDs to the global list for clear stale
    // for (let i = 0; i < obj.operations.length; i++) {
    //   const antiFlickerUTM = JSON.parse(JSON.stringify(obj.operations[i]));
    //   antiFlickerUTM.polygon.material.solidColor.color.rgba = [0,0,0,0];
    //   antiFlickerUTM.polygon.outlineColor.rgba = [0,0,0,0];
    //   antiFlickerUTM.id = String(obj.utm[i].id) + '_anti_flicker';
    //   this.allIDs.push(antiFlickerUTM.id);
    //   this.allIDs.push(obj.operations[i].id);
    //   czml.push(antiFlickerUTM);
    //   czml.push(obj.operations[i]);
    //   console.log(obj.operations[i]);
    // }
    return (czml);
  }

  // Generating czml for connecting alert lines and incurred-upon zones
  generateIncursions(obj): any {
    const incObj = [];

    for (let i = 0; i < obj.zone_incursions.length; i++) {
      const czmlCoords: number[] = [];
      const zonePoints = obj['zone_incursions'][i]['zone_geometry'];
      //for (let j = 0; j < zoneGeometries.length; j++) {
        //const zonePoints = zoneGeometries[j];
        for (let k = 0; k < zonePoints.length; k++) {
          const zonePoint = zonePoints[k];
          czmlCoords.push(
              zonePoint[0],
              zonePoint[1],
              0
                //this.heightService.getGeoidHeightLocal(zonePoint[0], zonePoint[1])

          );
        }
      //}
      // Generate dotted connecting alert lines from tracks to incurred-upon zones
      let connect_alert_color = [0, 0, 0, 0];
      if (obj['zone_incursions'][i]['zone_threat_level'] === 3) {
        connect_alert_color = [255, 255, 0, 255];
      } else if (obj['zone_incursions'][i]['zone_threat_level'] === 2) {
        connect_alert_color = [255, 128, 0, 255];
      } else if (obj['zone_incursions'][i]['zone_threat_level'] === 1) {
        connect_alert_color = [255, 0, 0, 255];
      }
      // Connect the line to the centroid of the incurred-upon zone
      //var positiongeo = Cesium.Cartesian3.fromDegrees(obj['zone_incursions'][i]['zone_centroid'][0],
      //  obj['zone_incursions'][i]['zone_centroid'][1], 0);
      //var screenLoc = Cesium.SceneTransforms.wgs84ToWindowCoordinates(scene, positiongeo)
      //var res = scene.pick(screenLoc);
      //const positions = [Cesium.Cartographic.fromDegrees(obj['zone_incursions'][i]['zone_centroid'][0], obj['zone_incursions'][i]['zone_centroid'][1])]
      //const heights = await Cesium.sampleTerrainMostDetailed(scene.terrainProvider, positions);


      //const zoneOrtho = Cesium.Cartographic.fromCartesian(Cesium.Cartesian3.fromDegrees(
      //  obj['zone_incursions'][i]['zone_centroid'][0],
      //  obj['zone_incursions'][i]['zone_centroid'][1]
      //));

      //let connect_alert_positions = [
      //  ...obj['zone_incursions'][i]['zone_centroid'],
      //  zoneOrtho.height + 60,
      //  obj['longitude'],
      //  obj['latitude'],
      //  obj['altitude']
      //];
      //connect_alert_positions.push(160.0);
      //connect_alert_positions.push(obj['longitude']);
      //connect_alert_positions.push(obj['latitude']) ;
      //connect_alert_positions.push(obj['altitude']);

      // Generate invisible anti-flicker geometry
      let antiFlicker = {
        id: String(obj['zone_incursions'][i]['zone_name']) + 'anti_flicker',
        polygon: {
          positions: {
            cartographicDegrees: czmlCoords,
          },
          material: {
            solidColor: {
              color: {
                rgba: [0, 0, 0, 0],
              },
            },
          },
          extrudedHeight: 120,
          // height: 120,
          perPositionHeight: false,
          outline: true,
          outlineColor: {
            rgba: [0, 0, 0, 0],
          },
          heightReference: 'RELATIVE_TO_GROUND',
        },
      };
      // Generate czml entities for incurred-upon zones
      const point = {
        id: obj['zone_incursions'][i]['zone_name'] + "-track",
        position: {
          cartographicDegrees: [obj['longitude'],
            obj['latitude'],
            obj['altitude']],
        },
        point: {
          color: {
            rgba: [255, 255, 255, 0],
          },
          outlineColor: {
            rgba: [255, 0, 0, 0],
          },

          pixelSize: 1,
        }
      };

      const point2 = {
        id: obj['zone_incursions'][i]['zone_name'] + "-center",
        position: {
          cartographicDegrees: [
            obj['zone_incursions'][i]['zone_centroid'][0],
            obj['zone_incursions'][i]['zone_centroid'][1],
            0
          ],
          heightReference: 'CLAMP_TO_GROUND',
        },
        point: {
          color: {
            rgba: [0, 255, 255, 0],
          },
          outlineColor: {
            rgba: [0, 255, 0, 0],
          },
          pixelSize: 1,
        }
      };
      const incursions = {
        id: obj['zone_incursions'][i]['zone_name'],
        description: obj['zone_incursions'][i]['zone_description'],
        polygon: {
          positions: {
            cartographicDegrees: czmlCoords,
          },
          material: {
            solidColor: {
              color: {
                rgba: [0, 255, 255, 100],
              },
            },
          },
          extrudedHeight: 120,
          height: -120,
          perPositionHeight: false,
          outline: true,
          outlineColor: {
            rgba: [255, 0, 0, 255],
          },
          heightReference: 'RELATIVE_TO_GROUND',
          //heightReference: 'CLAMP_TO_GROUND',
        },
        polyline: {
          positions: {
            // cartographicDegrees: connect_alert_positions,
            references: [
              `${obj['zone_incursions'][i]['zone_name']}-center#position`,
              `${obj['zone_incursions'][i]['zone_name']}-track#position`
            ]
          },
          width: 3.0,
          material: {
            polylineDash: {
              color: {
                rgba: connect_alert_color
              }
            }
          }
        }
      };

      // Push all new IDs to global list for clear stale and push all new objects to master object for drawing
      this.allIDs.push(obj['zone_incursions'][i]['zone_name']);
      this.allIDs.push(obj['zone_incursions'][i]['zone_name'] + "-center");
      this.allIDs.push(obj['zone_incursions'][i]['zone_name'] + "-track");
      incObj.push(antiFlicker);
      incObj.push(point2);
      incObj.push(point);
      incObj.push(incursions);
    }
    return incObj;
  }

}

import {Component, forwardRef, Input, OnChanges, OnDestroy, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {AXCesiumWidget, CesiumService} from '@ax/ax-angular-map-cesium';
import * as Cesium from 'cesium';
import {Cartesian2, Cartesian3, Cartographic, Ellipsoid, Entity, Event, Ray, Viewer} from 'cesium';
import {uuidv4} from '../../common/uuid';
import * as turfCircle from '@turf/circle';
import {CesiumComponent} from '../../common/CesiumComponent';

@Component({
  selector: 'lib-range-ring',
  templateUrl: './range-ring.component.html',
  styleUrls: ['./range-ring.component.css', '../../common/css/button.css'],
  providers: [{provide: AXCesiumWidget, useExisting: forwardRef(() => RangeRingComponent)}]

})
export class RangeRingComponent extends CesiumComponent implements OnChanges, OnDestroy {
  private moveEndRemoveCallback: Event.RemoveCallback;
  @Input() prettyId = '';

  @ViewChild('buttonTemplate') buttonTemplate: TemplateRef<any>;
  private rangeRingsInfoBox: Entity;

  get buttonWidget(): TemplateRef<any> {
    return this.buttonTemplate;
  }

  @Input() active = false;
  rangeText: string;

  private cartographic: Cartographic;
  private ellipsoid: Ellipsoid;

  private rangeRingsCenterAxis = 20.00;  // Pseudo hard coded value. Only important that they match each other
  private rangeRingsAxes = 1520.00;  // Pseudo hard coded value. Only important that they match each other
  private rangeRingsIncrement = 1500.00;  // Pseudo hard coded value. Only important that they match each other
  private currentZoomMeters: number;
  private id: string;
  private ringsEntities: any[];

  centerRangeRing: Entity;
  innermostRangeRing: Entity;
  innerRangeRing: Entity;
  middleRangeRing: Entity;
  outerRangeRing: Entity;
  outermostRangeRing: Entity;
  innermostLabel: Entity;
  innerLabel: Entity;
  middleLabel: Entity;
  outerLabel: Entity;
  outermostLabel: Entity;
  private coordinateIterator: number;
  private windowPosition: Cartesian2;
  private pickRay: Ray;
  private pickPosition: Cartesian3;
  private pickPositionCartographic: Cartographic;
  private centerLongitude: number;
  private centerLatitude: number;
  private viewer: Viewer;


  private getMapCenter(viewer: Viewer): void {

    this.windowPosition = new Cesium.Cartesian2(viewer.container.clientWidth / 2, viewer.container.clientHeight / 2);
    this.pickRay = viewer.scene.camera.getPickRay(this.windowPosition);
    this.pickPosition = viewer.scene.globe.pick(this.pickRay, viewer.scene);
    this.pickPositionCartographic = viewer.scene.globe.ellipsoid.cartesianToCartographic(this.pickPosition);
    this.centerLongitude = this.pickPositionCartographic.longitude * (180 / Math.PI);
    this.centerLatitude = this.pickPositionCartographic.latitude * (180 / Math.PI);

  }


  constructor(cesiumService: CesiumService) { // , private radarGroupService: ButtonGroupService) {
    super(cesiumService);
    this.id = uuidv4();
    console.debug(`Range Ring init: ${this.id}`);
  }

  onViewerInit(viewer: Viewer): void {
    console.debug(`Range Ring viewer init: ${this.id}/${this.prettyId}`);
    this.viewer = viewer;
    const scene = viewer.scene;
    this.cartographic = new Cesium.Cartographic();
    this.ellipsoid = scene.mapProjection.ellipsoid;
    this.currentZoomMeters = ((this.ellipsoid.cartesianToCartographic(scene.camera.positionWC, this.cartographic).height) * 0.1);
    this.ringsEntities = [];
    this.coordinateIterator = 0;
    this.moveEndRemoveCallback = scene.camera.moveEnd.addEventListener(() => {
      // Re-draw range rings
      if (this.active) {

        this.getRangeRingAxes(viewer);
      }
    });

    if (this.active) {
      this.displayRangeRings(viewer);
    }


  }

  displayRangeRings(viewer: Viewer): void {


    this.getMapCenter(viewer);
    this.getRangeRingAxes(viewer);
    // this.rangeRings(viewer);
  }

  getRangeRingAxes(viewer: Viewer): void {
    this.getMapCenter(viewer);
    const newCurrentZoomMeters = ((this.ellipsoid.cartesianToCartographic(viewer.scene.camera.positionWC, this.cartographic).height) * 0.1);
    const percentChangePositionValues = Math.abs((1 - (((newCurrentZoomMeters * 100) / this.currentZoomMeters) / 100)));
    if (newCurrentZoomMeters > this.currentZoomMeters) {
      this.currentZoomMeters = (this.currentZoomMeters + (this.currentZoomMeters * percentChangePositionValues));
      this.rangeRingsAxes = (this.rangeRingsAxes + (this.rangeRingsAxes * percentChangePositionValues));
      this.rangeRingsCenterAxis = (this.rangeRingsCenterAxis + (this.rangeRingsCenterAxis * percentChangePositionValues));
      this.rangeRingsIncrement = (this.rangeRingsIncrement + (this.rangeRingsIncrement * percentChangePositionValues));
    } else if (newCurrentZoomMeters < this.currentZoomMeters) {
      this.currentZoomMeters = (this.currentZoomMeters - (this.currentZoomMeters * percentChangePositionValues));
      this.rangeRingsAxes = (this.rangeRingsAxes - (this.rangeRingsAxes * percentChangePositionValues));
      this.rangeRingsCenterAxis = (this.rangeRingsCenterAxis - (this.rangeRingsCenterAxis * percentChangePositionValues));
      this.rangeRingsIncrement = (this.rangeRingsIncrement - (this.rangeRingsIncrement * percentChangePositionValues));
    }
    this.rangeRings(viewer);
  }

  // Core Range Rings Function
  rangeRings(viewer: Viewer): void {
    this.removeRangeRings(viewer);

    // Initialize axes
    const innermostAxis = this.rangeRingsAxes;
    const innerAxis = this.rangeRingsAxes * 2;
    const middleAxis = this.rangeRingsAxes * 3;
    const outerAxis = this.rangeRingsAxes * 4;
    const outermostAxis = this.rangeRingsAxes * 5;
    // Initialize coordinate arrays
    const center: [number, number] = [this.centerLongitude, this.centerLatitude];
    const centerCoordinates = createCircleCoordinateArray(this.rangeRingsCenterAxis, center);
    const innermostCoordinates = createCircleCoordinateArray(innermostAxis, center);
    const innerCoordinates = createCircleCoordinateArray(innerAxis, center);
    const middleCoordinates = createCircleCoordinateArray(middleAxis, center);
    const outerCoordinates = createCircleCoordinateArray(outerAxis, center);
    const outermostCoordinates = createCircleCoordinateArray(outermostAxis, center);
    const innermostLabelCoordinates = createLabelCoordinates(innermostAxis, this.centerLatitude);
    const innerLabelCoordinates = createLabelCoordinates(innerAxis, this.centerLatitude);
    const middleLabelCoordinates = createLabelCoordinates(middleAxis, this.centerLatitude);
    const outerLabelCoordinates = createLabelCoordinates(outerAxis, this.centerLatitude);
    const outermostLabelCoordinates = createLabelCoordinates(outermostCoordinates, this.centerLatitude);

    // Uses turf.js to draw points in a circle from a given lon/lat. Cleans the coords and returns them into an array
    function createCircleCoordinateArray(axis: number, circleCenter: [number, number]): number[] {

      const radius = axis / 1000;
      const circle = turfCircle.default(circleCenter, radius, {steps: 360, units: 'kilometers'});
      const cleanCoordinates: number[] = [];
      const turfCoordinates = circle.geometry.coordinates;
      // let coordinateIterator = 0;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < turfCoordinates.length; i++) {
        // tslint:disable-next-line:prefer-for-of
        for (let x = 0; x < turfCoordinates[i].length; x++) {
          // tslint:disable-next-line:prefer-for-of
          for (let y = 0; y < turfCoordinates[i][x].length; y++) {
            const tmp = turfCoordinates[i][x][y];
            cleanCoordinates.push(turfCoordinates[i][x][y]);
            // coordinateIterator += 1;
          }
        }
      }
      return cleanCoordinates;
    }

    // Uses turf.js to points for range rings labels
    function createLabelCoordinates(axis, centerLatitude: number): number {
      const labelCoordinates = centerLatitude - ((axis / 1000) / 110.574);
      return labelCoordinates;
    }

    // Draw polylines based on the cleaned coordinate arrays
    // this.centerRangeRing
    this.ringsEntities.push(viewer.entities.add({
      name: 'Center Range Ring',
      polyline: {
        positions: Cesium.Cartesian3.fromDegreesArray(centerCoordinates),
        width: 4,
        material: Cesium.Color.RED,
        clampToGround: true,
      },
    }));

    // this.innermostRangeRing
    this.ringsEntities.push(viewer.entities.add({
      name: 'Innermost Range Ring',
      polyline: {
        positions: Cesium.Cartesian3.fromDegreesArray(innermostCoordinates),
        width: 4,
        material: Cesium.Color.RED,
        clampToGround: true,
      },
    }));

    // this.innerRangeRing
    this.ringsEntities.push(viewer.entities.add({
      name: 'Inner Range Ring',
      polyline: {
        positions: Cesium.Cartesian3.fromDegreesArray(innerCoordinates),
        width: 4,
        material: Cesium.Color.RED,
        clampToGround: true,
      },
    }));

    // this.middleRangeRing
    this.ringsEntities.push(viewer.entities.add({
      name: 'Middle Range Ring',
      polyline: {
        positions: Cesium.Cartesian3.fromDegreesArray(middleCoordinates),
        width: 4,
        material: Cesium.Color.RED,
        clampToGround: true,
      },
    }));

    // this.outerRangeRing
    this.ringsEntities.push(viewer.entities.add({
      name: 'Outer Range Ring',
      polyline: {
        positions: Cesium.Cartesian3.fromDegreesArray(outerCoordinates),
        width: 4,
        material: Cesium.Color.RED,
        clampToGround: true,
      },
    }));

    // this.outermostRangeRing
    this.ringsEntities.push(viewer.entities.add({
      name: 'Outermost Range Ring',
      polyline: {
        positions: Cesium.Cartesian3.fromDegreesArray(outermostCoordinates),
        width: 4,
        material: Cesium.Color.RED,
        clampToGround: true,
      },
    }));

//    innermostLabel = ringsEntities.push(viewer.entities.add({
//        name: "Innermost Range Ring Label",
//        position: Cesium.Cartesian3.fromDegrees(this.centerLongitude, innermostLabelCoordinates),
//        label: {
//            text: "Range: " + String((rangeRingsIncrement / 1000).toFixed(2)) + 'km',
//            font: "14pt sans-serif",
//            style: Cesium.LabelStyle.FILL_AND_OUTLINE,
//            outlineWidth: 2,
//        }
//    }));

    // GENERATE LABEL NEXT TO BUTTON
    this.active = true;
    this.rangeText = 'Range: ' + String((this.rangeRingsIncrement / 1000).toFixed(2)) + 'km ';


    this.rangeRingsInfoBox = new Entity({name: 'Distance Between Range Rings'});
    // rangeRingsInfoBox.name = 'Distance Between Range Rings';
    // rangeRingsInfoBox.id = 'dbrr';
    (this.rangeRingsInfoBox as any).description = {
      getValue: () => {
        return '<span style=\'font-size: 18px;\'>' +
          this.rangeRingsIncrement.toFixed(2) +
          'm<br />' +
          (this.rangeRingsIncrement * .001).toFixed(2) +
          'km<br />' +
          (this.rangeRingsIncrement * 3.28084).toFixed(2) +
          'ft<br />' +
          (this.rangeRingsIncrement * 0.000621371).toFixed(2) +
          'mi</span>';
      }
    }
    ;
    viewer.selectedEntity = this.rangeRingsInfoBox;
  }

  private removeRangeRings(viewer: Viewer): void {
    // Every time the function is fired this clears the previous position's range rings and prepares to re-draw
    for (const entity of this.ringsEntities) {
      viewer.entities.remove(entity);
    }
  }


  toggleRings(): void {

    this.active = !this.active;
    if (this.active) {
      if (this.viewer) {
        this.displayRangeRings(this.viewer);
      }
    } else {
      if (this.viewer) {
        this.removeRangeRings(this.viewer);
        if (this.viewer.selectedEntity === this.rangeRingsInfoBox) {
          this.viewer.selectedEntity = null;
        }
      }
    }
  }

  ngOnDestroy(): void {
    console.debug(`Range Ring viewer destroy: ${this.id}/${this.prettyId}`);
    super.ngOnDestroy();
    if (this.moveEndRemoveCallback) {
      this.moveEndRemoveCallback();
    }

    if (this.viewer) {
      this.removeRangeRings(this.viewer);
      if (this.viewer.selectedEntity === this.rangeRingsInfoBox) {
        this.viewer.selectedEntity = null;
      }
    }
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.active) {
      if (this.active && this.viewer) {
        this.displayRangeRings(this.viewer);
      }
      if (!this.active && this.viewer) {
        this.removeRangeRings(this.viewer);
      }
    }
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit, Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {UTMEvent, UtmService} from '../utm.service';
import {AXCesiumWidget} from '@ax/ax-angular-map-cesium';
import {map} from 'rxjs/operators';
import {ButtonState, SafireButtonComponent} from '../../button/safire-button/safire-button.component';

type TabClasses = {
  tab: boolean;
  'utm-active-tab': boolean;
};


interface StyledUTMEvent extends UTMEvent {
  style: Partial<CSSStyleDeclaration>;
}

const getTabClasses = (active: boolean): TabClasses => {
  return {
    tab: true,
    'utm-active-tab': active
  };
};

const toStylizedUtmEvents = (events: UTMEvent[]): StyledUTMEvent[] => {
  return events.map(event => {

    let style: Partial<CSSStyleDeclaration> = {};
    if (event.class === 'Operation') {
      style = {
        backgroundColor: 'blue',
        color: 'white'
      };
    } else if (event.class === 'Constraint') {
      style = {
        backgroundColor: 'red',
        color: 'white'
      };
    }
    if (event.state === 'CONTINGENT' || event.state === 'ROGUE') {
      style = {
        backgroundColor: 'orange',
        color: 'black'
      };
    } else if (event.state === 'NONCONFORMING') {
      style = {
        backgroundColor: 'yellow',
        color: 'black'
      };
    } else if (event.state === 'ACTIVATED') {
      style = {
        backgroundColor: 'green',
        color: 'black'
      };
    }
    return {
      ...event,
      style
    };
  });
};

@Component({
  selector: 'lib-utm-table-overlay',
  templateUrl: './utm-table-overlay.component.html',
  styleUrls: ['./utm-table-overlay.component.css', '../../common/css/button.css'],
  providers: [{provide: AXCesiumWidget, useExisting: forwardRef(() => UtmTableOverlayComponent)}]
})
export class UtmTableOverlayComponent extends AXCesiumWidget implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(SafireButtonComponent) button: SafireButtonComponent;
  @Output() closed:EventEmitter<boolean> = new EventEmitter<boolean>();

  get buttonWidget(): TemplateRef<any> {
    return this.buttonTemplate;
  }

  get generalWidget(): TemplateRef<any> {
    return this.widgetTemplate;
  }

  constructor(private utmService: UtmService, private cdrf: ChangeDetectorRef) {
    super();
  }
  @ViewChild('buttonTemplate') buttonTemplate: TemplateRef<any>;
  @ViewChild('widgetTemplate') widgetTemplate: TemplateRef<any>;
  @ViewChild('utmWindow') utmWindow: ElementRef;
  private keyDownListener: ($event: KeyboardEvent) => void;
  private downListener: ($event: MouseEvent) => void;
  private moveListener: ($event: MouseEvent) => void;
  private upListener: ($event: MouseEvent) => void;

  utmEventTabClasses = getTabClasses(true);
  operationTabClasses = getTabClasses(false);
  constraintTabClasses = getTabClasses(false);
  @Input() active = false;
  currentTab: 'EVENTS' | 'OPERATIONS' | 'CONSTRAINTS' = 'EVENTS';
  events: StyledUTMEvent[] = [];
  operations: StyledUTMEvent[] = [];
  constraints: StyledUTMEvent[] = [];
  btnAlt = 'UTM Textual';

  windowStyle: any = {};
  state: ButtonState = ButtonState.OFF;
  ngOnDestroy(): void {
    this.close();
  }
ngOnInit(): void {
    }
  ngAfterViewInit(): void {
    const winBar = document.getElementById('utm-window');
    const winDrag = document.getElementById('utm-winbar');
    const  dragState = {
    isDragging: false,
    xDiff: 0,
    yDiff: 0,
    x: 355,
    y: 55
  };
    winDrag.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    function renderWindow(elmnt, myState): void {
      elmnt.style.transform = 'translate(' + myState.x + 'px, ' + myState.y + 'px)';
    }
    function clampX(n): any {
      return Math.min(Math.max(n, 0),
                      // container width - window width
                      window.innerWidth - winBar.offsetWidth);
  }
    function clampY(n): any {
      return Math.min(Math.max(n, 0), window.innerHeight);
  }
    function onMouseDown(event): void {
    dragState.isDragging = true;
    dragState.xDiff = event.pageX - dragState.x;
    dragState.yDiff = event.pageY - dragState.y;
    }
    function onMouseMove(event): void {
        if (dragState.isDragging) {
            dragState.x = clampX(event.pageX - dragState.xDiff);
            /** sets a lower boundary so you can't drag the infobox out-of-frame from the bottom */
            if (clampY(event.pageY - dragState.yDiff) > (window.innerHeight - 60)) {
              dragState.y = clampY(event.pageY - dragState.yDiff) - 60;
            }
            else {
              dragState.y = clampY(event.pageY - dragState.yDiff);
            }
        }
        renderWindow(winBar, dragState);
    }
    function onMouseUp(): void {
        dragState.isDragging = false;
    }
    this.utmService.watchOperations().pipe(map(toStylizedUtmEvents)).subscribe((operations) => {
      this.operations = operations;
      this.operations.sort((a, b) => {
        return Date.parse(b.update_time) - Date.parse(a.update_time);
      });
    });

    this.utmService.watchConstraints().pipe(map(toStylizedUtmEvents)).subscribe((constraints) => {
      this.constraints = constraints;
    });

    this.utmService.watchEvents().pipe(map(toStylizedUtmEvents)).subscribe((events) => {
      this.events = events;
    });

    if (this.active){
      this.handleActive();
    }

  }
  switchTab(targetTab: 'EVENTS' | 'OPERATIONS' | 'CONSTRAINTS'): void{
    this.currentTab = targetTab;
    this.utmEventTabClasses = getTabClasses(targetTab === 'EVENTS');
    this.operationTabClasses = getTabClasses(targetTab === 'OPERATIONS');
    this.constraintTabClasses = getTabClasses(targetTab === 'CONSTRAINTS');
  }

  close(): void {
    this.active = false;
    this.switchTab('EVENTS');

    if (this.keyDownListener) {
      window.removeEventListener('keydown', this.keyDownListener);
      this.upListener = null;
    }
    if (this.upListener && this.utmWindow?.nativeElement) {
      window.removeEventListener('mouseup', this.upListener);
      this.upListener = null;
    }

    if (this.moveListener) {
      window.removeEventListener('mousemove', this.moveListener);
      this.moveListener = null;
    }
    this.closed.emit(true);
    this.button?.setState(this.button?.baseState ?? ButtonState.OFF);
  }

  toggleOverlay($event: ButtonState): void {
    if ($event === ButtonState.ON) {
      this.active = true;
      this.handleActive();
    } else {
      this.close();
    }
    /*if (this.active) {
      this.close();
    } else {
      this.active = true;
      this.handleActive();
    }*/
  }

  private handleActive(): void {
    this.windowStyle = {};
    this.keyDownListener = ($event: KeyboardEvent) => {
        if ($event.key === 'Escape') {
          this.close();
        }
      };
    window.addEventListener('keydown', this.keyDownListener);
  }

  toggle($event: ButtonState): void {
    this.toggleOverlay($event);
  }
}

import {Component, ElementRef, forwardRef, Input, OnDestroy, Optional, TemplateRef, ViewChild} from '@angular/core';
import {CesiumComponent} from '../common/CesiumComponent';
import {AXCesiumWidget, CesiumService} from '@ax/ax-angular-map-cesium';
import {CzmlDataSource, GeoJsonDataSource, KmlDataSource, Viewer} from 'cesium';
import {Observable} from 'rxjs';
import {ButtonState, SafireButtonComponent} from '../button/safire-button/safire-button.component';
import {SafireButtonService} from '../button/safire-button.service';
import {FlightPlan, parse_mplan, parse_pcc, parse_swift, parse_ugcs} from '@ax/ax-mission-parser';
import {GeoidHeightService} from '../common/geoid-height.service';




@Component({
  selector: 'lib-resource-visualizer',
  templateUrl: './resource-visualizer.component.html',
  styleUrls: ['./resource-visualizer.component.css', '../common/css/button.css', '../radar-group/radar-group.component.css'],
  providers: [{provide: AXCesiumWidget, useExisting: forwardRef(() => ResourceVisualizerComponent)}]
})

export class ResourceVisualizerComponent extends CesiumComponent implements OnDestroy {

  @ViewChild('buttonTemplate') buttonTemplate: TemplateRef<any>;
  @ViewChild('widgetTemplate') widgetTemplate: TemplateRef<any>;
  @ViewChild(SafireButtonComponent) button: SafireButtonComponent;

  @ViewChild('inputElement') inputElement: ElementRef;

  @Input() btnAlt = 'Upload Geo Data';
  @Input() btnIcon = '/assets/icons/upload_button.png';
  enabled = false;
  private viewer: Viewer;
  // private dataTxt = '{"sources": []}';
  public sourceData = {sources: []} as { sources: any[] }; // JSON.parse(this.dataTxt);
  public tree = [];
  private treeHidden = [];
  // public treeHTML = ""
  private treeEl: Element;
  private currentKMLStyles = [];
  private currentKMLStyleMaps = [];
  private checked: 'checked';
  private readFile = (f: File): Observable<any> => {
    return new Observable(subscriber => {
      const reader = new FileReader();
      reader.onload = () => {
        subscriber.next(reader.result);
        subscriber.complete();
      };
      reader.readAsText(f);
    });
  }


  get buttonWidget(): TemplateRef<any> {
    return this.buttonTemplate;
  }

  get generalWidget(): TemplateRef<any> {
    return this.widgetTemplate;
  }

  constructor(cesiumService: CesiumService, @Optional() buttonService: SafireButtonService, private geoidHeightService: GeoidHeightService) {
    super(cesiumService);
  }

  onViewerInit(viewer: Viewer): void {
    this.viewer = viewer;
  }

  toggle(state: ButtonState): void {
    const elmnt = document.getElementById('upload-menu');
    this.enabled = state === ButtonState.ON;
  }

  fireUpload(): void {
    this.inputElement.nativeElement.click();
  }

  getChildTagsFromName (el, name): any [] {
    const nodes = [];
    for (let i = 0; i < el.children.length; i++) {
      if (el.children[i].tagName.toUpperCase() === name.toUpperCase()) {
        nodes.push(el.children[i]);
      }

    }
    return nodes;
  }

  destroyDataSource(file) {
    for (let i = 0; i < this.sourceData.sources.length; ++i) {
      if (this.sourceData.sources[i].name === file) {
        const source = this.sourceData.sources[i].source;
        source.entities.removeAll();
        this.viewer.dataSources.remove(source);
        this.sourceData.sources.splice(i, 1);
      }
    }
  }

  loadDataSources(fileName, ds): number {
    for (let i = 0; i < this.sourceData.sources.length; i++) {
      if (this.sourceData.sources[i].name === fileName) {
        alert('A file by the name ' + fileName + ' has already been uploaded.');
        return 0;
      }
    }
    this.sourceData.sources.push({name: fileName, source: ds});
    return 1;
  }

  setDatasourcesVisibility(tree, visible): void {
    if (tree.hasOwnProperty('datasource')) {
      tree.datasource.show = visible;
      tree.datasource.visibility = visible;
    }

    for (let x = 0; x < tree.documents?.length; x++) {
      this.setDatasourcesVisibility(tree.documents[x], visible);
    }

    for (let x = 0; x < tree.folders?.length; x++) {
      this.setDatasourcesVisibility(tree.folders[x], visible);
    }

    for (let x = 0; x < tree.placemarks?.length; x++) {
      this.setDatasourcesVisibility(tree.placemarks[x], visible);
    }

  }

  removeDatasources(tree): void {
    console.debug('removing:' + tree.name);
    console.debug(tree.hasOwnProperty('datasource'));
    if (tree.hasOwnProperty('datasource')) {
      this.viewer.dataSources.remove(tree.datasource);
    }

    for (let x = 0; x < tree.documents?.length; x++) {
      this.removeDatasources(tree.documents[x]);
    }

    for (let x = 0; x < tree.folders?.length; x++) {
      this.removeDatasources(tree.folders[x]);
    }

    for (let x = 0; x < tree.placemarks?.length; x++) {
      this.removeDatasources(tree.placemarks[x]);
    }

  }

  buildTree(doc, filename): any {
    // Save Styles for kml
    for (let x = 0; x < doc.getElementsByTagName('Style').length; x++) {
      this.currentKMLStyles.push(doc.getElementsByTagName('Style')[x].outerHTML);
    }

    for (let x = 0; x < doc.getElementsByTagName('StyleMap').length; x++) {
      this.currentKMLStyleMaps.push(doc.getElementsByTagName('StyleMap')[x].outerHTML);
    }

    let myTree: any = {filename, documents: [], folders: [], placemarks: [], name: filename, };
    const myTreeDoc = document.implementation.createDocument(null, null, null);

    myTree.myElement = myTreeDoc.createElement('li');
    myTree.myElement.innerHTML = '<span style=\'display:none;\'>F' + '::</span>' + filename;
    myTree.type = 'F';
    myTree.remove = () => {
      this.tree.splice(this.tree.indexOf(myTree), 1);
      this.removeDatasources(myTree);
    };

    // Look for documents
    doc = this.getChildTagsFromName(doc, 'kml')[0];
    const docEls = this.getChildTagsFromName(doc, 'Document');
    if (docEls.length > 0) {
      const docUl = myTreeDoc.createElement('ul');
      for (let i = 0; i < docEls.length; i++) {
        myTree.documents.push(this.buildDocumentTree(docEls[i], docUl));
      }

      myTree.myElement.appendChild(docUl);
    }

    const folderEls = this.getChildTagsFromName(doc, 'Folder');
    for (let i = 0; i < folderEls.length; i++) {
      myTree.folders.push(this.buildFolderTree(folderEls[i], myTree.myElement));
    }

    // Look for Placemarks
    const placemarkEls = this.getChildTagsFromName(doc, 'Placemark');
    for (let i = 0; i < placemarkEls.length; i++) {
      myTree.placemarks.push(this.buildPlacemark(placemarkEls[i], myTree.myElement));
    }

    /** Crates a visibility child node to account for top-level documents and folders
     * that otherwise wouldn't be accounted for in the checkbox handling.
     */
    const hasVis = doc.getElementsByTagName('visibility');
    for (const element in hasVis) {
      if (hasVis[element].innerHTML === '1') {
        myTree.visibility = '1';
        myTree.checked = true;
        break;
      } else {
        myTree.visibility = '';
        myTree.checked = false;
        // hasVis[element].innerHTML = '';
      }
    }
    myTree = this.buildTreeChildren(myTree);
    return myTree;
  }

  buildTreeChildren(myTree) {
    myTree.childrenLen = myTree.documents.length + myTree.placemarks.length + myTree.folders.length;
    myTree.toggleHide = (event) => { this.toggleHide(event, myTree); };
    return myTree;
  }

  buildTreeDisplay(tree): void {
    const treeDoc = document.implementation.createDocument(null, null, null);
    this.treeEl = treeDoc.createElement('ul');
    for (let x = 0; x < tree.length; x++) {
      this.treeEl.appendChild(tree[x].myElement);
    }
  }

  /**
   * Converts MSL altitudes to HAE for use in Cesium. Becomes necessary when flight plans use MSL instead of the
   * standard AGL that the parser was developed to handle.
   * @param czmls - Read from the inFile and processed through the external library which parses/serializes flight plans
   * into usable czmls.
   */
  private convertCzmlAlt = (czmls): any => {
    for (const item of czmls) {
      /** Checks to see if altitude is MSL instead of standard AGL for flight plans */
      if ( ['MSL', 'HOME_REL'].includes(item.plan_alt_ref)) {
        /** Checks to see if the item is a polyline, in which case it needs to be handled diffeerently than
         * the standard points.
         */
        if (item.polyline) {
          /** Grabs the longitutde and latitude in the first two positions of the coordinate array of the polyline,
           * then uses a locally stored geoid height service at those long/lat coordinates to find the undulation,
           * then modifies the current altitude in the 3rd array position by the undulation. This converts the altitude
           * to HAE so it can play nice with Cesium. We then jump 3 places in the coordinate array and continue until
           * all altitude values have been updated to HAE.
           */
          for (let coord = 0; coord < item.polyline.positions.cartographicDegrees.length - 2; coord += 3) {
            /** Find undulation */
            const geoHeight = this.geoidHeightService.getGeoidHeightLocal(
              item.polyline.positions.cartographicDegrees[coord], +
                item.polyline.positions.cartographicDegrees[coord + 1]);
            /** Modify altitude values */
            item.polyline.positions.cartographicDegrees[coord + 2] =
              (item.polyline.positions.cartographicDegrees[coord + 2] + geoHeight);
          }
        } else if (item.ellipse) {
          /** If the entity type is not a polyline, check if it is an ellipse. If so apply the
           * offset to the ellipse.height value.
           */
          const geoHeight = this.geoidHeightService.getGeoidHeightLocal(item.position.cartographicDegrees[0], +
            item.position.cartographicDegrees[1]);
          item.ellipse.height = (item.position.cartographicDegrees[2] + geoHeight);
        } else {
          /** If the entity type is not a polyline, instead we simply run the calculation to find the undulation
           * at the current lat/long value and convert the altitude to HAE. These coordinate values should only ever be
           * one array of long/lat/alt
           */
          const geoHeight = this.geoidHeightService.getGeoidHeightLocal(item.position.cartographicDegrees[0], +
            item.position.cartographicDegrees[1]);
          item.position.cartographicDegrees[2] = (item.position.cartographicDegrees[2] + geoHeight);
        }
      }
    }
    return czmls;
  }

  buildDocumentTree(docEl: Element, parentEl: Element): any {
    let branch: any = {};
    branch.name = 'Unnamed';
    this.buildNodeVisibility(branch, docEl);
    if (this.getChildTagsFromName(docEl, 'open').length > 0) {
      branch.open = this.getChildTagsFromName(docEl, 'open')[0].innerHTML;
    }
    /** Crates a visibility child node to account for top-level documents and folders
     * that otherwise wouldn't be accounted for in the checkbox handling.
     */
    const hasVis = docEl.getElementsByTagName('visibility');
    for (const element in hasVis) {
      if (hasVis[element].innerHTML === '1') {
        branch.visibility = '1';
        branch.checked = true;
        break;
      } else {
        branch.visibility = '';
        branch.checked = false;
      }
    }

    branch.folders = [];
    branch.placemarks = [];
    branch.documents = [];
    branch.style = [];
    // Create element
    branch.myElement = document.implementation.createDocument(null, null, null).createElement('li');
    branch.myElement.innerHTML = '<span style=\'display:none;\'>D::</span>' + branch.name;
    const myUlEl = document.implementation.createDocument(null, null, null).createElement('ul');

    this.buildChildList(docEl, branch, myUlEl);

    if (this.getChildTagsFromName(docEl, 'Placemark').length > 0 || this.getChildTagsFromName(docEl, 'Folder').length > 0) {
      branch.myElement.appendChild(myUlEl);
    }

    // Build Style
    for (let j = 0; j < this.getChildTagsFromName(docEl, 'Style').length; j++) {
      branch.style.push(this.getChildTagsFromName(docEl, 'Style')[j].outerHTML);
    }

    branch.toggleCollapse = (event) => { this.toggleCollapse(event); };

    branch.remove = () => { };
    parentEl.appendChild(branch.myElement);
    branch.type = 'D';
    branch = this.buildTreeChildren(branch);
    return branch;
  }

  toggleCollapse(event) {
    const myContainer = event.target.parentNode;
    const myUls = this.getChildTagsFromName(myContainer, 'ul');
    for (let x = 0; x < myUls.length; x++) {
      // Collapse Menu
      if (event.target?.innerHTML?.trim() === 'v') {
        myUls[x].style.display = 'none';
      } else {
        myUls[x].style.display = 'block';
      }
    }
    if (event.target?.innerHTML?.trim() === '&gt;') {
      event.target.innerHTML = 'v';
    } else {
      event.target.innerHTML = '&gt;';
    }
  }

  /** Accepts the currently focused node and drills into it checking for placemarks.
   * If the parent node's visibility is disabled, this disables the child
   * placemarks.
   * @param node: Currently focused node (a folder containing other folders and placemarks)
   */
  drillDownPlacemark(node, action): void{
    const childPlacemarks = this.getChildTagsFromName(node, 'Placemark');
    if (childPlacemarks.length > 0) {
      for (const placemark of childPlacemarks) {
        const vis = placemark.getElementsByTagName('visibility');
        if (action === 'uncheck') {
          for (const val of vis) {
            val.innerHTML = '';
          }
        }
        else {
          if (vis.length <= 0) {
            const newVis = document.createElement('visibility');
            newVis.innerHTML = '1';
            placemark.appendChild(newVis);
          }
        }
      }
    }
    return;
  }

  /** A recursive function that accepts the currently focused node and drills into
   * its contained folders and placemarks, disabling their visibility if their
   * parent node also has disabled visibility.
   * @param node: The currently focused node as passed by the buildNodeVisibility method. (usually
   * a folder containing other folders and placemarks.
   */
  drillDownFolder(node, action): void {
    const childFolders = this.getChildTagsFromName(node, 'Folder');
    if (childFolders.length > 0)  {
      for (const folder of childFolders) {
        const vis = folder.getElementsByTagName('visibility');
        if (action === 'uncheck') {
          for (const val of vis) {
            val.innerHTML = '';
          }
        }
        else {
          if (vis.length <= 0) {
            const newVis = document.createElement('visibility');
            newVis.innerHTML = '1';
            folder.appendChild(newVis);
          }
        }
        this.drillDownPlacemark(folder, action);
        this.drillDownFolder(folder, action);
      }
    }
    return;
  }
  buildNodeVisibility(node, nodeEl): void {
    if (this.getChildTagsFromName(nodeEl, 'name').length > 0) {
      node.name = this.getChildTagsFromName(nodeEl, 'name')[0].innerHTML;
    }

    /** Checks to see if a parent node has any visible child nodes, and if so,
     * ensures that the parent node is also visible. Disables visibility
     * if there are no visible child nodes.
     */
    if (this.getChildTagsFromName(nodeEl, 'visibility').length > 0)  {
      nodeEl.visibility = this.getChildTagsFromName(nodeEl, 'visibility')[0].innerHTML;
      if (nodeEl.visibility === '1') {
        node.checked = true;
      }
      else {
        /** Recursive function intended to drill down in to folders and placemarks to disable their visibility if
         * the parent visibility is disabled.
         */
        this.drillDownFolder(nodeEl, 'uncheck');
        node.visibility = '';
        node.checked = false;
      }
      this.getChildTagsFromName(nodeEl, 'visibility')[0].innerHTML = '1'; // Force visible control with datasource.show
    }
    else {
      this.drillDownFolder(nodeEl, 'create');
    }
  }



  buildFolderTree(folderEl: Element, parentEl: Element): any {
    const folder: any = {attributes: [], folders: [], placemarks: [], documents: []};
    folder.attributes = folderEl.attributes;

    // Fill in the boring attributes
    this.buildNodeVisibility(folder, folderEl);

    // Create element
    folder.myElement = document.implementation.createDocument(null, null, null).createElement('li');
    folder.myElement.innerHTML = 'F:' + folder.name;

    if (this.getChildTagsFromName(folderEl, 'Placemark').length > 0 || this.getChildTagsFromName(folderEl, 'Folder').length > 0) {
      const subEl = document.implementation.createDocument(null, null, null).createElement('ul');
      // Add Placemarks to Folder
      this.buildChildList(folderEl, folder, subEl);
      folder.myElement.appendChild(subEl);
    }

    parentEl.appendChild(folder.myElement);
    folder.type = 'f';
    folder.childrenLen = folder.documents.length + folder.placemarks.length + folder.folders.length;
    folder.remove = () => { };
    folder.toggleCollapse = (event) => { this.toggleCollapse(event); };
    folder.toggleHide = (event) => {
      const myContainer = event.target.parentNode;
      const mySubChecks = myContainer.getElementsByTagName('input');
      const curValue = event.target.checked;
      for (let x = 0; x < mySubChecks.length; x++) {
        mySubChecks[x].checked = curValue;
      }
      if (curValue) {
        this.setDatasourcesVisibility(folder, true);
      } else {
        this.setDatasourcesVisibility(folder, false);
      }
      return true;
    };
    return folder;
  }

  buildChildList(parentEl, node, subEl) {
    for (let x = 0; x < this.getChildTagsFromName(parentEl, 'Placemark').length; x++) {
      node.placemarks.push(this.buildPlacemark(this.getChildTagsFromName(parentEl, 'Placemark')[x], subEl));
    }

    // Add Folders to Folder
    for (let x = 0; x < this.getChildTagsFromName(parentEl, 'Folder').length; x++) {
      node.folders.push(this.buildFolderTree(this.getChildTagsFromName(parentEl, 'Folder')[x], subEl));
    }

  }

  buildPlacemark(el, parentEl) {
    const placemark: any = {name: '', visibility: 1, styleUrl: '', polygons: [], kml: null, documents: [], folders: [], placemarks: []};
    // Fill the boring parameters if specified
    this.buildNodeVisibility(placemark, el);
    if (this.getChildTagsFromName(el, 'styleUrl').length > 0) {
      placemark.styleUrl = this.getChildTagsFromName(el, 'styleUrl')[0];
    }

    if (this.getChildTagsFromName(el, 'Polygon').length > 0) {
      for (let i = 0; i < this.getChildTagsFromName(el, 'Polygon').length; i++) {
        placemark.polygons.push(this.getChildTagsFromName(el, 'Polygon')[i].outerHTML);
      }

    }

    // Build KML
    placemark.kml = this.buildPlacemarkKML(el);
    // Build HTML element
    placemark.myElement = document.implementation.createDocument(null, null, null).createElement('li');
    placemark.myElement.innerHTML = 'P:' + placemark.name;
    parentEl.appendChild(placemark.myElement);
    // Build Datasource
    const options = {camera: this.viewer.scene.camera, canvas: this.viewer.scene.canvas, clampToGround: true};
    placemark.datasource = new KmlDataSource(options);
    placemark.datasource.load(placemark.kml, {
      camera: this.viewer.scene.camera,
      canvas: this.viewer.scene.canvas,
      tessellate: true,
      clampToGround: true
    });
    if (placemark.visibility <= 0){ placemark.datasource.show = false; }
    else {
      placemark.datasource.show = '1';
    }
    this.viewer.dataSources.add(placemark.datasource)
      .catch(error => console.error('Error loading KML: ' + error)); // Promise
    placemark.type = 'P';
    placemark.childrenLen = placemark.documents.length + placemark.placemarks.length + placemark.folders.length;
    placemark.remove = () => {
    };
    placemark.toggleHide = (event) => { this.toggleHide(event, placemark); };
    return placemark;
  }

  toggleHide(event, node) {
    const myContainer = event.target.parentNode;
    const mySubChecks = myContainer.getElementsByTagName('input');
    const curValue = event.target.checked;
    for (let x = 0; x < mySubChecks.length; x++) {
      mySubChecks[x].checked = curValue;
    }

    if (curValue) {
      this.setDatasourcesVisibility(node, true);
    } else {
      this.setDatasourcesVisibility(node, false);
    }

    return true;
  }

  buildPlacemarkKML(placemarkEl) {
    const xmlDoc = document.implementation.createDocument(null, null, null);
    const kmlEl = document.createElement('kml');
    kmlEl.setAttribute('xmlns', 'http://www.opengis.net/kml/2.2');
    kmlEl.setAttribute('xmlns:gx', 'http://www.google.com/kml/ext/2.2');
    kmlEl.setAttribute('xmlns:kml', 'http://www.opengis.net/kml/2.2');
    kmlEl.setAttribute('xmlns:atom', 'http://www.w3.org/2005/Atom');
    const tEl = xmlDoc.createElement('Placemark');
    tEl.innerHTML = placemarkEl.innerHTML;
    kmlEl.appendChild(tEl);
    // Append Styles
    for (let x = 0; x < this.currentKMLStyles.length; x++) {
      const styleParser = new DOMParser();
      const doc = styleParser.parseFromString(this.currentKMLStyles[x], 'text/xml');
      kmlEl.appendChild(doc.getElementsByTagName('Style')[0]);
      // kmlEl.appendChild(doc)
    }

    for (let x = 0; x < this.currentKMLStyleMaps.length; x++) {
      const styleParser = new DOMParser();
      const doc = styleParser.parseFromString(this.currentKMLStyleMaps[x], 'text/xml');
      kmlEl.appendChild(doc.getElementsByTagName('StyleMap')[0]);
    }

    xmlDoc.appendChild(kmlEl);
    return xmlDoc;
  }


  handleInput($event: InputEvent) {

    const inputElement = ($event.srcElement as HTMLInputElement);
    for (let i = 0; i < inputElement.files.length; i++) {
      const fileExtension = inputElement.files[i].name.split('.')[1];
      const fileName = inputElement.files[i].name;
      const prettyName = fileName.split('.')[0];
      if (fileExtension === 'kml') {
        const ds = new KmlDataSource({camera: this.viewer.scene.camera, canvas: this.viewer.scene.canvas});
        this.readFile(inputElement.files[i]).subscribe(kml => {
          const parser = new window.DOMParser();
          const parsedXML = parser.parseFromString(kml, 'text/xml');
          const isClamped = parsedXML.getElementsByTagName('altitudeMode');
          const isLineString = parsedXML.getElementsByTagName('LineString');
          const isLinearRing = parsedXML.getElementsByTagName('LinearRing');
          const kmlDesc = parsedXML.getElementsByTagName('description');
          const editFileName = parsedXML.getElementsByTagName('name');
          /**
           * Append a KML identifier to the name of uploaded files with a KML file extension for
           * later evaluation. This is necessary for to properly style the infobox
           */
          for (let name = 0; name < editFileName.length; name++) {
            editFileName[name].innerHTML += " (KML)";
          }
          /**
           * check if there is any visibility child nodes in each folder, and if not, crete them and default
           * them to visible
           */
          const folderEls = this.getChildTagsFromName(parsedXML, 'Folder');
          let newDesc = '';
          // Do black magic
          const myxml = parsedXML;
          const myfilename = fileName;
          this.tree.push(this.buildTree(myxml, myfilename));
          this.buildTreeDisplay(this.tree);
          let options = {};
          /**
           * Set up the entity description string from the KML description to ensure cleaner formatting
           */
          for (const tag in kmlDesc) {
            if (String(kmlDesc[tag]) === '<![CDATA[]]>') {
              continue;
            }
            else {
              newDesc = String(kmlDesc[tag]);
            }
          }
          if (isClamped.length > 0) {
            for (let i = 0; i < isClamped.length; i++) {
              for (let x = 0; x < isClamped[i].childNodes.length; x++) {
                if (isClamped[i].childNodes[x].nodeValue.includes('clampToGround')) {
                  options = {camera: this.viewer.scene.camera, canvas: this.viewer.scene.canvas, tessellate: true, clampToGround: true};
                }

              }

            }

          }
        });

      } else if (fileExtension === 'czml') {
        const ds = new CzmlDataSource();
        this.viewer.dataSources.add(ds)
          .catch(error => console.error('Error adding new datasource: ' + error)); // Promise

        this.readFile(inputElement.files[i]).subscribe(czml => {
          const parsedCZML = JSON.parse(czml);
          if (parsedCZML.length > 1) {
            if (parsedCZML[0].id !== 'document') {
              /**
               * loop through CZML objects and append (CZML) to indicate that this file is one that has been uploaded
               */
              for (let obj = 0; obj < parsedCZML.length; obj++) {
                if (parsedCZML[obj].name) {
                  parsedCZML[obj].name += ' (CZML)';
                }
                else {
                  parsedCZML[obj].name = prettyName + ' (CZML)';
                }
              }
              const shouldLoad = this.loadDataSources(fileName, ds);
              if (shouldLoad === 1) {
                ds.process({
                  id: 'document',
                  version: '1.0'
                }).then(() => {
                  ds.process(parsedCZML)
                    .catch(error => console.error('Error processing czml: ' + error));
                }).catch(error => console.error('Error processing czml: ' + error));
              }
            } else {
              const shouldLoad = this.loadDataSources(fileName, ds);
              for (let obj = 0; obj < parsedCZML.length; obj++) {
                if (parsedCZML[obj].name) {
                  parsedCZML[obj].name += ' (CZML)';
                }
                else {
                  parsedCZML[obj].name = prettyName + ' (CZML)';
                }
              }
              if (shouldLoad === 1) {
                ds.process(parsedCZML);
              }
            }
          } else {
            const shouldLoad = this.loadDataSources(fileName, ds);
            for (let obj = 0; obj < parsedCZML.length; obj++) {
              if (parsedCZML[obj].name) {
                parsedCZML[obj].name += ' (CZML)';
              }
              else {
                parsedCZML[obj].name = prettyName + ' (CZML)';
              }
            }
            if (shouldLoad === 1) {
              ds.process({
                id: 'document',
                version: '1.0'
              }).then(() => {
                ds.process(parsedCZML)
                  .catch(error => console.error('Error processing czml: ' + error));
              }).catch(error => console.error('Error processing czml: ' + error));
            }
          }
        });
      } else if (fileExtension === 'geojson') {
        console.debug('Loading GeoJSON.');
        const ds = new GeoJsonDataSource();
        this.viewer.dataSources.add(ds);
        this.readFile(inputElement.files[i]).subscribe(geojson => {
          const newGeo = JSON.parse(geojson);
          const shouldLoad = this.loadDataSources(fileName, ds);
          for (let feature = 0; feature < newGeo.features.length; feature ++) {
            let newDesc = '<link rel="stylesheet" type="text/css"' +
              'href="/api/infobox-service/static/styles.css"><table ' +
              'style="width: 100%; font-size: 12px;' +
              'border: 1px solid white; border-collapse: collapse; word-break: break-word;">' +
              '<tr style="width:100%;border-collapse: collapse;">';
            if (newGeo.features[feature].properties.name) {
              newGeo.features[feature].properties.name += ' (GEOJSON)';
            }
            else{
              newGeo.features[feature].properties.name = prettyName + ' (GEOJSON)';
            }
            for (const property in newGeo.features[feature].properties) {
              newDesc += '<td style="border: 1px solid white; border-collapse: collapse;">' +
                property + '</td><td style="border: 1px solid white; border-collapse: collapse;">' +
                newGeo.features[feature].properties[property] + '</td></tr>';
            }
            newDesc += '</table>';
            newGeo.features[feature].properties.description = '<div id="new-desc-wrapper">' +
              newDesc +
              '</div>';
          }
          if (shouldLoad === 1) {
            ds.load(newGeo, {clampToGround: true, })
              .catch(error => console.error('Error loading new geometry: ' + error)); // Promise
          }
        });
      } else if (fileExtension === 'fp'){
        // Handles PCC flight plans.
        const ds = new CzmlDataSource();
        this.viewer.dataSources.add(ds)
          .catch(error => console.error('Error adding new datasource: ' + error));

        this.readFile(inputElement.files[i]).subscribe(waypointText => {
          console.log('Parsing .fp file: ' + fileName);
          const inFile = parse_pcc(waypointText, fileName);
          const shouldLoad = this.loadDataSources(fileName, ds);
          if (shouldLoad === 1) {
            ds.process({
              id: 'document',
              version: '1.0'
            }).then(() => {
              const czmlsFinal = this.convertCzmlAlt(inFile.toCzml());
              ds.process(czmlsFinal);
            }).catch(error => console.error('Error processing new czml from file: ' + error));
          }
        });
      } else if (fileExtension === 'waypoints'){
        // Handles Mission Planner flight plans.
        const ds = new CzmlDataSource();
        this.viewer.dataSources.add(ds)
          .catch(error => console.error('Error adding new datasource: ' + error));

        this.readFile(inputElement.files[i]).subscribe(waypointText => {
          console.log('Parsing .waypoints file: ' + fileName);
          const inFile = parse_mplan(waypointText, fileName);
          const shouldLoad = this.loadDataSources(fileName, ds);
          if (shouldLoad === 1) {
            ds.process({
              id: 'document',
              version: '1.0'
            }).then(() => {
              const czmlsFinal = this.convertCzmlAlt(inFile.toCzml());
              ds.process(czmlsFinal)
                .catch(error => console.error('Error processing new czml from file: ' + error));
            }).catch(error => console.error('Error processing new czml from file: ' + error));
          }
        });
      } else if (fileExtension === 'mission'){
        // Handles SwiftGCS flight plans.
        const ds = new CzmlDataSource();
        this.viewer.dataSources.add(ds)
          .catch(error => console.error('Error adding new datasource: ' + error));
        this.readFile(inputElement.files[i]).subscribe(waypointText => {
          console.log('Parsing .mission file: ' + fileName);
          const inFile = parse_swift(waypointText, fileName);
          const shouldLoad = this.loadDataSources(fileName, ds);
          if (shouldLoad === 1) {
            ds.process({
              id: 'document',
              version: '1.0'
            }).then(() => {
              const czmlsFinal = this.convertCzmlAlt(inFile.toCzml());
              ds.process(czmlsFinal)
                .catch(error => console.error('Error processing new czml from file: ' + error));
            }).catch(error => console.error('Error processing new czml from file: ' + error));
          }
        });
      } else if (fileExtension === 'json'){
        // Handles UgCS flight plans
        // ToDo add extra handling since .json is a standard, common format.
        const ds = new CzmlDataSource();
        this.viewer.dataSources.add(ds)
          .catch(error => console.error('Error adding new datasource: ' + error));

        this.readFile(inputElement.files[i]).subscribe(waypointText => {
          console.log('Parsing .json file: ' + fileName);
          const inFile = parse_ugcs(waypointText, fileName);
          const shouldLoad = this.loadDataSources(fileName, ds);
          if (shouldLoad === 1) {
            ds.process({
              id: 'document',
              version: '1.0'
            }).then(() => {
              const czmlsFinal = this.convertCzmlAlt(inFile.toCzml());
              ds.process(czmlsFinal);
            }).catch(error => console.error('Error processing new czml from file: ' + error));
          }
        });
      } else {
        alert('Unsupported file type.');
      }
    }
    inputElement.value = null;
  }


  closeWindow(): void {
    this.button?.setState(this.button?.baseState ?? ButtonState.OFF);
  }

}

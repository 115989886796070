<div id="{{categoryId}}" class="legend-wrapper" *ngIf="hasItems">
    <div id="title-{{categoryId}}" class="legend-title legend-text"  (click)="minimizeLegend()"><img class="hamburger" src="/assets/icons/hamburger_icon.png" /> {{name}}:</div>
    <div class="legend-body" [style.display]="legendDisplay" >
    <div class="legend-div" *ngFor="let item of legendItemConfigs" (click)="handleClick(item.legendId)">
      <img class='legend-img-icon' *ngIf="item.iconUrl" src="{{item.iconUrl}}" />
      <ng-container *ngIf="!item.iconUrl">
      <div *ngIf="item.icon == 'circle'" class="legend-button" [style]="{backgroundColor: item.color}"></div>
      <div *ngIf="item.icon == '6star'" class="legend-button-6star" [style]="{color: item.color}"></div>
      <div *ngIf="item.icon == 'square'" class="legend-button-square" [style]="{backgroundColor: item.color}"></div>
      <div *ngIf="item.icon == '4star'" class="legend-button-4star" [style]="{color: item.color}"></div>
      <div *ngIf="item.icon == 'red-cube'" class="legend-button-cube">
        <div class="red-cube-side red-cube-top"></div>
        <div class="red-cube-side red-cube-left"></div>
        <div class="red-cube-side red-cube-front"></div>
      </div>
      <div *ngIf="item.icon == 'blue-cube'" class="legend-button-cube">
        <div class="blue-cube-side blue-cube-top"></div>
        <div class="blue-cube-side blue-cube-left"></div>
        <div class="blue-cube-side blue-cube-front"></div>
      </div>
      <div *ngIf="item.icon == 'dart-circle'" class="legend-button-dart" style="display:none">
        <div class="dart-orange">
          <div class="dart-red">
          </div>
        </div>
      </div>
      </ng-container>

      <div class="legend-text" *ngIf="item.icon !== 'dart-circle'">{{item.name}}</div>
    </div>
  </div>
  </div>

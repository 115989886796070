import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StompListenerComponent } from './stomp-listener/stomp-listener.component';
import {HttpClientModule} from '@angular/common/http';
import { RestLoaderComponent } from './rest-loader/rest-loader.component';



@NgModule({
  declarations: [StompListenerComponent, RestLoaderComponent],
  exports:[StompListenerComponent, RestLoaderComponent],
  imports: [
    CommonModule,
    HttpClientModule
  ]
})
export class CzmlModule { }

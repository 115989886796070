import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {uuidv4} from '../../common/uuid';
import {AXCesiumWidget, CesiumService} from '@ax/ax-angular-map-cesium';
import {CesiumComponent} from '../../common/CesiumComponent';
import * as Cesium from 'cesium';
import {PinBuilder, Viewer} from 'cesium';
import {Subscription} from 'rxjs/internal/Subscription';
import {GeoidHeightService} from '../../common/geoid-height.service';


@Component({
  selector: 'lib-point-selection',
  templateUrl: './point-selection.component.html',
  styleUrls: ['./point-selection.component.css', '../../common/css/button.css'],
  providers: [{provide: AXCesiumWidget, useExisting: forwardRef(() => PointSelectionComponent)}]
})
export class PointSelectionComponent extends CesiumComponent implements OnInit, OnDestroy {
  @Input() pointSelectActive: boolean;
  private id: string;
  viewer: Viewer;

  pinBuilder = new PinBuilder();
  pointSelectLong;
  pointSelectLat;
  pointSelectHeight;
  private radarGroupServiceSub: Subscription;
  private pointSelMoveLabel: any;
  private pointSelClickPin: any;
  private pointSelClickLabel: any;
  @ViewChild('buttonTemplate') buttonTemplate: TemplateRef<any>;
  @Input() btnAlt = `Point Select`;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  private openSub: Subscription;
  private ds: Cesium.CustomDataSource;
  private pointSelMoveHandler: Cesium.ScreenSpaceEventHandler;
  private pointSelClickHandler: Cesium.ScreenSpaceEventHandler;
  private selectedEntityHandlerRemove: Cesium.Event.RemoveCallback;

  get buttonWidget(): TemplateRef<any> {
    return this.buttonTemplate;
  }

  onViewerInit(viewer: Viewer): void {
    this.viewer = viewer;
    this.ds = new Cesium.CustomDataSource();
    this.viewer.dataSources.add(this.ds);
    // Trailing label for point select
    this.pointSelMoveLabel = this.ds.entities.add({
      id: 'psTrailingLabel',
      label: {
        show: false,
        showBackground: true,
        font: '14px monospace',
        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        pixelOffset: new Cesium.Cartesian2(15, 0),
        disableDepthTestDistance: Number.POSITIVE_INFINITY
      },
    });

    // Click Pin for point select
    this.pointSelClickPin = this.ds.entities.add({
      name: 'Selected Coordinates',
      id: 'pointSelPin',
      billboard: {
        show: false,
        image: this.pinBuilder.fromMakiIconId('marker', Cesium.Color.WHITE, 30),
        font: '14px monospace',
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM
      } as any,
    });
    // Click label for point select
    this.pointSelClickLabel = this.ds.entities.add({
      id: 'pointSelLabel',
      label: {
        show: false,
        showBackground: true,
        font: '14px monospace',
        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
        verticalOrigin: Cesium.VerticalOrigin.TOP,
        pixelOffset: new Cesium.Cartesian2(0, 0),
      },
    });


    this.pointSelMoveHandler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
    this.pointSelMoveHandler.setInputAction((event) => {
      if (this.pointSelectActive) {
        const scene = viewer.scene;
        const pickedObject = scene.pick(event.endPosition);
        const cartesian = scene.pickPosition(event.endPosition);
        if (!cartesian) {
          return;
        }
        const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
        const longitudeString = Cesium.Math.toDegrees(cartographic.longitude).toFixed(7);
        const latitudeString = Cesium.Math.toDegrees(cartographic.latitude).toFixed(7);
        const heightString = cartographic.height.toFixed(3);
        this.pointSelMoveLabel.position = cartesian;
        this.pointSelMoveLabel.label.show = true;
        this.pointSelMoveLabel.label.text =
          'Lon: ' +
          ('   ' + longitudeString).slice(-12) +
          '\u00B0' +
          '\nLat: ' +
          ('   ' + latitudeString).slice(-12) +
          '\u00B0' +
          '\nHae: ' +
          ('  ' + heightString.slice(-8)) +
          'm';
        this.pointSelMoveLabel.label.eyeOffset = new Cesium.Cartesian3(
          0.0,
          0.0,
          -cartographic.height *
          (scene.mode === Cesium.SceneMode.SCENE2D ? 1.5 : 1.0)
        );
      } else {
        this.pointSelMoveLabel.label.show = false;
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
// Click event handler for point select

    // Set up esc key event handler
    document.addEventListener(
      'keyup',
      (e): void => {
        if (e.key === 'Escape') {
          this.close();
        }
      },
      false
    );

    this.pointSelClickHandler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
    this.pointSelClickHandler.setInputAction((event) => {
      if (this.pointSelectActive) {
        const scene = viewer.scene;
        const pickedObject = scene.pick(event.position);
        const cartesian = scene.pickPosition(event.position);
        const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
        this.pointSelectLong = Cesium.Math.toDegrees(cartographic.longitude);
        this.pointSelectLat = Cesium.Math.toDegrees(cartographic.latitude);
        this.pointSelectHeight = cartographic.height;
        const geoHeight = this.geoidHeightService.getGeoidHeightLocal(this.pointSelectLong, this.pointSelectLat);
        const mslHeight = (this.pointSelectHeight - geoHeight);
        this.pointSelClickPin.position = cartesian;
        this.pointSelClickPin.billboard.show = true;
        this.pointSelClickPin.billboard.eyeOffset = new Cesium.Cartesian3(
          0.0,
          0.0,
          -cartographic.height *
          (scene.mode === Cesium.SceneMode.SCENE2D ? 1.5 : 1.0)
        );
        this.pointSelClickPin.description = {
          getValue: () => {
            return '<table style="border: 1px solid white; border-collapse: collapse; width: 100%;">' +
              '<tbody>' +
              '<tr>' +
              '<td style="border: 1px solid white; border-collapse: collapse;">Longitude (degrees)</td>' +
              '<td style="border: 1px solid white; border-collapse: collapse;">' +
              this.pointSelectLong.toFixed(7) +
              '</td>' +
              '</tr>' +
              '<tr>' +
              '<td style="border: 1px solid white; border-collapse: collapse;">Latitude (degrees)</td>' +
              '<td style="border: 1px solid white; border-collapse: collapse;">' +
              this.pointSelectLat.toFixed(7) +
              '</td>' +
              '</tr>' +
              '<tr>' +
              '<td style="border: 1px solid white; border-collapse: collapse;">Height Above Ellipsoid (m)</td>' +
              '<td style="border: 1px solid white; border-collapse: collapse;">' +
              this.pointSelectHeight.toFixed(3) +
              '</td>' +
              '</tr>' +
              '<tr>' +
              '<td style="border: 1px solid white; border-collapse: collapse;">Height Above Geoid (m)</td>' +
              '<td style="border: 1px solid white; border-collapse: collapse;">' +
              geoHeight.toFixed(3) +
              '</td>' +
              '</tr>' +
              '<tr>' +
              '<td style="border: 1px solid white; border-collapse: collapse;">Height Above Mean Sea Level (m)</td>' +
              '<td style="border: 1px solid white; border-collapse: collapse;">' +
              mslHeight.toFixed(3) +
              '</td>' +
              '</tr>' +
              '</tbody>' +
              '</table>';
          }
        };
        viewer.infoBox.frame.src = '';
        viewer.selectedEntity = this.pointSelClickPin;
      } else {
        this.pointSelClickPin.billboard.show = false;
        this.pointSelClickLabel.label.show = false;
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

  }

  constructor(cesiumService: CesiumService, private geoidHeightService: GeoidHeightService) {
    super(cesiumService);
    this.id = uuidv4();
    console.debug(`Initing point-selection: id: ${this.id}`);

  }


  ngOnInit(): void {
    /*this.radarGroupServiceSub = this.radarGroupService.listenForOpenNotifications().subscribe((id) => {
      if (id !== this.id) {
        this.close();
      }
    });*/
  }

  togglePointSelect(): void {
    if (this.pointSelectActive) {
      this.close();
    } else {
      this.pointSelectActive = true;
      // this.radarGroupService.notifyOfButtonOpen(this.id);
    }
  }


  private close(): void {
    this.pointSelectActive = false;
    this.pointSelClickLabel.label.show = false;
    this.pointSelClickPin.billboard.show = false;
    if (this.viewer) {
      if (this.viewer.selectedEntity === this.pointSelClickPin) {
        this.viewer.selectedEntity = undefined;
      }
    }
    this.onClose.emit(this.pointSelectActive);
  }


  ngOnDestroy(): void {
    super.ngOnDestroy();
    // this.radarGroupServiceSub?.unsubscribe();
    this.openSub?.unsubscribe();

    if (this.viewer) {
      this.viewer.dataSources.remove(this.ds);
      // this.removeEntity(this.pointSelMoveLabel);
      // this.removeEntity(this.pointSelClickPin);
      // this.removeEntity(this.pointSelClickLabel);
    }
    if (this.selectedEntityHandlerRemove) {
      this.selectedEntityHandlerRemove();
    }

    if (this.pointSelClickHandler && !this.pointSelClickHandler.isDestroyed()) {
      this.pointSelClickHandler.destroy();
    }
    if (this.pointSelMoveHandler && !this.pointSelMoveHandler.isDestroyed()) {
      this.pointSelMoveHandler.destroy();
    }
  }

  private removeEntity(entity: any): void {
    if (this.viewer && entity) {
      this.viewer.entities.remove(entity);
    }
  }
}

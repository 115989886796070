import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {uuidv4} from './uuid';
import {BehaviorSubject} from 'rxjs';

export type LegendIconType =
  'circle'
  | 'marker'
  | 'square'
  | '4star'
  | '6star'
  | 'red-cube'
  | 'blue-cube'
  | 'dart-circle';

export interface LegendItemConfig {
  legendId?: string;
  id: string;
  name: string;
  category: string;
  color: string;
  iconUrl?: string;
  icon: LegendIconType;
}

export interface LegendEvent {
  action: 'add' | 'remove' | 'click';
  id?: string;
  legendConfig?: LegendItemConfig;
}

export interface LegendConfig {
  categoryId: string;
  legendItems: { [legendId: string]: LegendItemConfig };
}

export type GlobalLegendConfig = { [categoryId: string]: LegendConfig };

@Injectable()
export class LegendService {

  private eventSubjects: { [key: string]: ReplaySubject<LegendEvent>; } = {};
  private legendConfigs: { [key: string]: LegendItemConfig } = {};

  private globalLegendConfigSubject: BehaviorSubject<GlobalLegendConfig> = new BehaviorSubject<GlobalLegendConfig>({});
  private globalLegendConfig: GlobalLegendConfig = {};

  constructor() {
  }

  watchForLegendConfigs(): Observable<GlobalLegendConfig> {
    return this.globalLegendConfigSubject;
  }

  watchForLegendEvents(id: string): Observable<LegendEvent> {
    return this.eventSubjects[id];
  }

  addLegend(legendConfig: LegendItemConfig): string {
    const id = uuidv4();

    this.eventSubjects[id] = new ReplaySubject(1);

    if (!(legendConfig.category in this.globalLegendConfig)) {
      this.globalLegendConfig[legendConfig.category] = {
        categoryId: legendConfig.category,
        legendItems: {}
      };
    }
    this.globalLegendConfig[legendConfig.category].legendItems[id] = {
      ...legendConfig,
      legendId: id
    };

    this.globalLegendConfigSubject.next(this.globalLegendConfig);
    return id;
  }

  removeLegend(id: string): void {
    if (this.eventSubjects[id]) {
      this.eventSubjects[id].complete();
      delete this.eventSubjects[id];

      for (const config of Object.values(this.globalLegendConfig)){
        if (config.legendItems[id]){
          delete config.legendItems[id];
        }
      }

      this.globalLegendConfigSubject.next(this.globalLegendConfig);
    }
  }

  notifyOfButtonClick(id: string): void {
    if (this.eventSubjects[id]) {
      this.eventSubjects[id].next({
        action: 'click',
        id,
        legendConfig: this.legendConfigs[id]
      });
    }
  }
}

import {TrackConfigComponent, TrackConfigSingleton} from '../track-config/track-config.component';

export class HeadsTailsService {
  public static allIDs = [];
  public static headsIDs = [];
  public static tailsIDs = [];

  static drawHeadsTails(obj: any): { base: any, head?: any, tail?: any } {
    let addHead = true;
    let addTail = true;
    const returnObj: { base: any, head?: any, tail?: any } = {base: obj};
    this.allIDs.push(obj.id);
    // if (obj.id.includes('gryphon-')) {
    //   // return returnObj;
    // }

    if (obj.id.includes('-affiliation')) {
      return returnObj;
    }
    // Confirm global bool for drawing heads is true, otherwise just return obj
    const uidField = 'safire_unique_id';
    const colorField = 'safire_draw_color';
    const headsCzml = {
      id: String(obj.safire_properties[uidField]) + '_heading',
      name: String(obj.name) + '_heading',
      type: 'track', // This is a non standard extension for identifying objects as tracks (used for cleanup)
      sensor: obj.sensor,  // This is not standard. Used by UI to define timeout
      description: obj.description_text,
      polyline: {
        show: TrackConfigSingleton.drawHeads,
        positions: {
          cartographicDegrees: obj.heading_draw,
        },
        width: 10.0,
        material: {
          polylineArrow: {
            color: {
              rgba: obj.safire_properties[colorField]
            }
          }
        },
        depthFailMaterial: {
          polylineArrow: {
            color: {
              rgba: obj.safire_properties[colorField]
            }
          }
        }
      },
    };
    // Reassign template values to those sent in the track msg
    returnObj.head = headsCzml;
    /*this.allIDs.push(headsCzml.id);
    for (const i in this.headsIDs) {
      if (this.headsIDs[i] === headsCzml.id) {
        addHead = false;
        break;
      }
    }*/
    // Confirm global bool for drawing tails is true, otherwise just return obj
    const tailsCzml = {
      id: String(obj.safire_properties[uidField]) + '_tail',
      name: String(obj.name) + '_tail',
      type: 'track', // This is a non standard extension for identifying objects as tracks (used for cleanup)
      sensor: obj.sensor,  // This is not standard. Used by UI to define timeout
      description: obj.description_text,
      polyline: {
        show: TrackConfigSingleton.drawTails,
        positions: {
          cartographicDegrees: obj.tail_draw,
        },
        width: 3.0,
        material: {
          solidColor: {
            color: {
              rgba: obj.safire_properties[colorField]
            }
          }
        },
        depthFailMaterial: {
          solidColor: {
            color: {
              rgba: obj.safire_properties[colorField]
            }
          }
        }
      },
    };
    returnObj.tail = tailsCzml;
    /*this.allIDs.push(tailsCzml.id);
    for (const i in this.tailsIDs) {
      if (this.tailsIDs[i] === headsCzml.id) {
        addTail = false;
        break;
      }
    }*/
    /*if (addHead) {
      this.headsIDs.push(headsCzml.id);
    }
    if (addTail) {
      this.tailsIDs.push(tailsCzml.id);
    }*/
    return returnObj;
  }

  /*constructor() {
    HeadsTailsServiceSingleton = this;
  }*/
}

// HeadsTailsServiceSingleton = HeadsTailsService;

<ng-template #widgetTemplate>
  <div class="outer-wrapper">

    <ng-container *ngIf="legendCategories">
      <ng-container *ngFor="let id of categoryKeys">
        <lib-base-legend [categoryId]="id" [name]="legendCategories[id]"></lib-base-legend>
      </ng-container>
    </ng-container>


    <ng-container *ngIf="!legendCategories">
      <lib-base-legend [categoryId]="categoryId" [name]="name"></lib-base-legend>
    </ng-container>

  </div>
</ng-template>
<ng-template>


</ng-template>


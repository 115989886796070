import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Optional,
  Output
} from '@angular/core';
import {CesiumComponent} from '../../common/CesiumComponent';
import {Cartesian3, CzmlDataSource, HeadingPitchRoll, Viewer} from 'cesium';
import {CesiumService} from '@ax/ax-angular-map-cesium';
import {StompService} from '../../common/stomp.service';
import {DartService} from '../../common/dart.service';
import {HeadsTailsService} from '../../common/heads-tails.service';
import {interval, Subscription} from 'rxjs';
import {SafireEnvironmentService} from '../../common/safire-environment.service';
import {IMessage, Stomp} from '@stomp/stompjs';
import {DateTime} from 'luxon';
import {uuidv4} from '../../common/uuid';
import {LegendItemConfig, LegendService} from '../../common/legend.service';
import * as Cesium from 'cesium';
import {TrackConfigComponent, TrackConfigSingleton} from '../../track-config/track-config.component';
import {TrackDescriptionsService} from '../../common/track-descriptions.service';
import {AffiliationsService} from '../../common/affiliations.service';
import {TimeSyncService} from '../../common/time-sync.service';

@Component({
  selector: 'lib-stomp-listener',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StompListenerComponent extends CesiumComponent implements OnDestroy {
  static dartActive = 0;
  static posData = {};
  public catchAllDS: CzmlDataSource;
  public dartDS: CzmlDataSource;
  public dartUTMDS: CzmlDataSource;
  public trackDS: CzmlDataSource;
  @Input() exchange: string;
  @Input() legendConfig: LegendItemConfig;
  @Output() terminated: EventEmitter<void> = new EventEmitter();
  headIds: Set<string> = new Set<string>();
  tailIds: Set<string> = new Set<string>();
  private stompSub: Subscription;
  private trackRecord: any;
  private projRecord: any;
  private utmRecord: any;
  private uvrRecord: any;
  private gsRecord: any;
  private ephemeralRecord: { [k: string]: { timestamp: number, stale: number } } = {};
  private intervalSub: Subscription;
  private stompClientSub: Subscription;
  private miscSubs: Subscription[] = [];
  private id: string;
  // private semaphore: semaphore;
  private legendId: string;
  private legendSub: Subscription;
  private tasks: (() => void)[] = [];
  private taskIntervalSub: Subscription;
  private viewer: Viewer;
  private dataID: string;
  private staleCheckInterval = 2000;
  // private lastStaleCheck: DateTime;

  constructor(private safireEnvironmentService: SafireEnvironmentService,
              private dartService: DartService,
              private stompService: StompService,
              private timeSyncService: TimeSyncService,
              @Optional() private legendService: LegendService,
              cesiumService: CesiumService) {
    super(cesiumService);
    this.id = uuidv4();
  }

  createIndex(ident: string, position: any): void {

    if (ident in StompListenerComponent.posData) {
      StompListenerComponent.posData[ident].pos = position;
    } else {
      StompListenerComponent.posData[ident] = {id: ident, pos: position};
    }

  }

  clearStale(): void {
    const now = performance.now(); // DateTime.now();

    /*if(this.lastStaleCheck && now.diff(this.lastStaleCheck).as('milliseconds') < this.staleCheckInterval){
      return;
    }

    this.lastStaleCheck = now;*/
    const packets = [];
    // tslint:disable-next-line:forin
    for (const key in this.ephemeralRecord) {
      const record = this.ephemeralRecord[key];
      // if (now.diff(record.stale).as('milliseconds') > 0) {
      if (now - record.stale > 0) {
        packets.push({
          id: key,
          delete: JSON.parse('true')
        });


        /** This piece of code was added and never tested. I don't believe this has ever worked,
         * and its purpose was never documented. I've opted to comment it out for now
         */
        // for (let i = 0; i < AffiliationsService.affiliationIDs.length; i++) {
        //   if (key + '_affiliation' === AffiliationsService.affiliationIDs[i]) {
        //     AffiliationsService.affiliationIDs.splice(i, 1);
        //   }
        // }
        if ((key + '_heading') in this.headIds) {
          delete this.headIds[key + '_heading'];
        }
        if ((key + '_tail') in this.tailIds) {
          delete this.headIds[key + '_tail'];
        }

        if (key in StompListenerComponent.posData) {
          delete StompListenerComponent.posData[key];
        }

        delete this.ephemeralRecord[key];
      }
      for (const id of AffiliationsService.affiliationIDs) {
        if (this.trackDS.entities.getById(id)) {
          this.trackDS.entities.getById(id).show = !!StompListenerComponent.dartActive;
        }
      }
    }
    // TODO: wrap promise in observable to wait for completion before moving onto next task
    this.catchAllDS.process(packets);
    this.dartDS.process(packets);
    this.trackDS.process(packets);
    this.dartUTMDS.process(packets);
  }

  onViewerInit(viewer: Viewer): void {
    this.viewer = viewer;
    if (!this.exchange) {
      return;
    }
    this.trackRecord = {};
    this.projRecord = {};
    this.utmRecord = {};
    this.uvrRecord = {};
    this.gsRecord = {};
    this.catchAllDS = new CzmlDataSource();
    this.trackDS = new CzmlDataSource();
    this.dartDS = new CzmlDataSource();
    this.dartUTMDS = new CzmlDataSource();
    this.cesiumService.addDatasource(this.catchAllDS);
    this.cesiumService.addDatasource(this.trackDS);
    this.cesiumService.addDatasource(this.dartDS);
    this.cesiumService.addDatasource(this.dartUTMDS);
    if (this.legendConfig?.id && ['ninja-dart', 'nasa_utm-dart', 'src-dart', 'dowding-dart', 'mplan-dart'].includes(this.legendConfig.id)) {
      StompListenerComponent.dartActive += 1;
      console.debug('Affiliations have been activated');
    }
    console.debug('Initing StompListener');
    this.catchAllDS.process([{
      id: 'document',
      name: 'stomp-listener-' + this.id,
      version: '1.0',
    }, {
      clock: {
        currentTime: DateTime.now().toISO()
      }
    }]).then(() => this.trackDS.process([{
      id: 'document',
      name: 'stomp-listener-' + this.id,
      version: '1.0',
    }, {
      clock: {
        currentTime: DateTime.now().toISO()
      }
    }]).catch(error => console.error('Error processing czml: ' + error))
    ).then(() => this.dartDS.process([{
      id: 'document',
      name: 'stomp-listener-' + this.id,
      version: '1.0',
    }, {
      clock: {
        currentTime: DateTime.now().toISO()
      }
    }]).catch(error => console.error('Error processing czml: ' + error)))
      .then(() => this.dartUTMDS.process([{
      id: 'document',
      name: 'stomp-listener-' + this.id,
      version: '1.0',
    }, {
      clock: {
        currentTime: DateTime.now().toISO()
      }
    }]).catch(error => console.error('Error processing czml: ' + error)))
      .then(() => {

      this.completeInit();
    }).catch(error => console.error('Error during init: ' + error));

  }

  ngOnDestroy(): void {
    StompListenerComponent.posData = {};
    if (this.legendConfig?.id && ['ninja-dart', 'nasa_utm-dart', 'src-dart', 'dowding-dart', 'mplan-dart'].includes(this.legendConfig.id)) {
      StompListenerComponent.dartActive -= 1;
      if (!StompListenerComponent.dartActive) {
        document.getElementById('affiliation').style.top = '-350';
        console.debug('Affiliations have been deactivated');
      }
    }
    super.ngOnDestroy();
    this.taskIntervalSub?.unsubscribe();
    try {
      this.cesiumService.removeDatasource(this.catchAllDS);
      this.cesiumService.removeDatasource(this.trackDS);
      this.cesiumService.removeDatasource(this.dartDS);
      this.cesiumService.removeDatasource(this.dartUTMDS);
    } catch (e) {
      console.error('Error removing Cesium datasources: ' + e);
    }

    this.stompClientSub?.unsubscribe();
    this.stompSub?.unsubscribe();
    this.intervalSub?.unsubscribe();

    if (this.legendId) {
      this.legendService.removeLegend(this.legendId);
    }

    this.legendSub?.unsubscribe();

    this.miscSubs.forEach(sub => sub?.unsubscribe());

  }

  private toggleHeadTail(state: boolean, ids: Set<string>): void {
    const idArr = [...ids.keys()];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < idArr.length; i++) {
      const entity = this.trackDS.entities.getById(idArr[i]);
      if (entity) {
        entity.show = state;
      }
    }
  }

  private initHeadTailSubs(): void {
    this.miscSubs.push(TrackConfigSingleton.drawHeadsSubject.subscribe((state) => {
      this.tasks.push(() => this.toggleHeadTail(state, this.headIds));
    }));

    this.miscSubs.push(TrackConfigSingleton.drawTailsSubject.subscribe((state) => {
      this.tasks.push(() => this.toggleHeadTail(state, this.tailIds));
    }));
  }

  private completeInit(): void {
    this.stompClientSub?.unsubscribe();
    this.stompSub?.unsubscribe();
    this.intervalSub?.unsubscribe();
    // this.semaphore = new semaphore(1);
    this.stompClientSub = this.stompService.getStompClient().subscribe((client) => {
      if (client === null) {
        return;
      }
      this.stompSub = client.watch(this.exchange).subscribe((msg: IMessage) => {
        this.tasks.push(() => this.updateMap(JSON.parse(msg.body)));
      });
    });

    this.intervalSub = interval(this.staleCheckInterval).subscribe(() => {
      this.tasks.push(() => this.clearStale());
    });

    this.taskIntervalSub = interval(400).subscribe(() => {
      while (this.tasks.length > 0) {
        try {
          const cur = this.tasks.pop();
          cur();
        } catch (e) {
          console.error(`StompListenerComponent[${this.id}]`, e);
        }

      }

    });

    if (this.legendService && this.legendConfig) {
      this.legendId = this.legendService.addLegend(this.legendConfig);

      this.legendSub = this.legendService.watchForLegendEvents(this.legendId).subscribe((legendEvent) => {
        if (!legendEvent) {
          return;
        }
        switch (legendEvent.action) {
          case 'add':
            break;
          case 'remove':
            break;
          case 'click':
            this.legendService.removeLegend(this.legendId);
            this.terminated.emit();
            break;

        }
      });
    }

    /** Subscribes to the state which dictates if we have recently changed label format - located in
     * track-config.component.ts.
     */
    TrackConfigSingleton.updateLabelFormatSubject.subscribe( dumpLabels => {
      /** Callback checks if the state is true then iterate through all the tracks in the current datasource
       * (that do not have undefined labels) and blank out their labels. The labels will repropagate on the next
       * invocation of mapUpdate where we get new altitude data for the tracks that have had their labels blanked out.
       */
      if (dumpLabels) {
        const blankLabel = new Cesium.ConstantProperty('');
        this.trackDS.entities.values.filter(track => track.label !== undefined).map( val => {
            val.label.text = blankLabel;
        });
        /** after we are done blanking out track labels, set the state back to false to stop the process */
        TrackConfigSingleton.updateLabelFormatSubject.next(false);
      }
    });
    this.initHeadTailSubs();
  }

  private updateMap(messageJson: any): void {
    for (const item of messageJson) {
      const messageTime = DateTime.fromISO(item.timestamp);
      this.dataID = item.id;
      const timestamp = this.timeSyncService.getSyncedNowTimestamp();
      const diff = timestamp.diff(messageTime).as('millisecond');
      if (diff < 10000) {
        const curTime = performance.now();
        if (item.type === 'track') {
          this.createIndex(item.id, item.position);
          TrackDescriptionsService.updateDescriptions(item, this.viewer);
          const finalJsons: { base: any, head?: any, tail?: any, affil?: any } = HeadsTailsService.drawHeadsTails(item);
          finalJsons.affil = AffiliationsService.drawAffiliations(item);
          /** LABEL CONFIG DEFAULT: Checks to see if the current label formatting setting in the
           * Track Config matches the default setting for the deployment. If so, then keep calm and carry on.
           * If not, we piece together the appropriate label elements, units, and methods (which consists of track id,
           * altitude, and speed).
           */
          if (TrackConfigSingleton.labelFormat !== TrackConfigSingleton.labelConfig) {
            let newLabel = '';
            if (finalJsons.base.label !== undefined) {
              TrackConfigSingleton.labelFormat.showId ? newLabel
                  += finalJsons.base.safire_properties.label_stub
                  : newLabel += '';
              TrackConfigSingleton.labelFormat.showAlt ? newLabel
                  += finalJsons.base.safire_properties.altitude_collection[TrackConfigSingleton.labelFormat.altFormat]
                  + '\n'
                  : newLabel += '';
              TrackConfigSingleton.labelFormat.showSpeed ? newLabel
                  += finalJsons.base.safire_properties.speed_collection[TrackConfigSingleton.labelFormat.speedFormat]
                  : newLabel += '';
              finalJsons.base.label.text = newLabel;
          }
          }
          const finalJsonArr = [
            finalJsons.base,
            finalJsons.head,
            finalJsons.tail,
            finalJsons.affil,
          ].filter(e => e);

          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < finalJsonArr.length; i++) {
            let stale = curTime;
            const id = finalJsonArr[i].id;
            if (id.includes('_affiliation')) {
              stale += 2000;
            } else if (item?.properties?.time_valid) {
              stale += item.properties.time_valid;
            } else {
              stale += 10000;
            }
            this.ephemeralRecord[id] = {
              timestamp: curTime,
              stale
            };
          }


          if ('hpr' in finalJsons.base) {
            const position = Cartesian3.fromDegrees(messageJson[0].hpr.lon, messageJson[0].hpr.lat, messageJson[0].hpr.alt);
            const heading = messageJson[0].hpr.heading;
            const pitch = 0;
            const roll = 0;
            const hprObj = new HeadingPitchRoll(heading, pitch, roll);
            const oriQuat = Cesium.Transforms.headingPitchRollQuaternion(position, hprObj);
            finalJsons.base.orientation = {unitQuaternion: [oriQuat.x, oriQuat.y, oriQuat.z, oriQuat.w]};
          }
          this.trackDS.process(finalJsonArr);
        }

        // If obj type is dart_alert send it off to the dart service
        else if (item.type === 'dart_alert') {
          const stale = curTime + 6000; // set stale time by adding ms
          const finalJson = this.dartService.parseSerialize(item);
          // Using existing expiry function with a tie-in to the dartService.allIDs global list
          const ephemeralId: any = this.dartService.allIDs;

          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < ephemeralId.length; i++) {
            this.ephemeralRecord[ephemeralId[i]] = {
              timestamp: curTime,
              stale
            };
            this.dartService.allIDs = [];
          }
          this.dartDS.process(finalJson);
        } else if (item.type === 'dart_utm_operations') {
          const finalJson = [];
          const stale = curTime + 6000;
          timestamp.plus(6000); // set stale time by adding ms
          // Using existing expiry function with a tie-in to the dartService.allIDs global list
          // tslint:disable-next-line:prefer-for-of
          for (let i = 0; i < item.operations.length; i++) {
            const operation = item.operations[i];
            this.ephemeralRecord[operation.id] = {
              timestamp: curTime,
              stale
            };
            finalJson.push(operation);
            const antiFlicker = JSON.parse(JSON.stringify(operation));
            antiFlicker.polygon.material.solidColor.color.rgba = [0, 0, 0, 0];
            antiFlicker.polygon.outlineColor.rgba = [0, 0, 0, 0];
            antiFlicker.id = String(operation.id) + '_anti_flicker';
            this.ephemeralRecord[antiFlicker.id] = {
              timestamp: curTime,
              stale
            };
            finalJson.push(antiFlicker);
          }
          this.dartUTMDS.process(finalJson);
        } else {
          this.catchAllDS.process(messageJson);
        }
      } else {
        console.debug('Old Message', item.id, messageTime, timestamp, diff);
      }
    }
    // TODO: wrap promise in observable to wait for completion before moving onto next task
    //  this.catchAllDS.process(messageJson);

  }
}

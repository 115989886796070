import {
  Component,
  forwardRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {AXCesiumWidget} from '@ax/ax-angular-map-cesium';

export type LegendCategories = { [id: string]: string };


@Component({
  selector: 'lib-live-legend',
  templateUrl: './live-legend.component.html',
  styleUrls: ['./live-legend.component.css'],
  providers: [{provide: AXCesiumWidget, useExisting: forwardRef(() => LiveLegendComponent)}]
})
export class LiveLegendComponent extends AXCesiumWidget implements OnInit {

  @ViewChild('widgetTemplate') widgetTemplate: TemplateRef<any>;

  @Input() categoryId: string;
  @Input() name: string;
  @Input() legendCategories: LegendCategories;
  get categoryKeys(): string[]{
    return Object.keys(this.legendCategories);
  }

  get generalWidget(): TemplateRef<any> {
    return this.widgetTemplate;
  }

  constructor() {
    super();

  }

  ngOnInit(): void {
  }
}

import {ChangeDetectionStrategy, Component} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {DEFAULT_CONFIG} from './app.default_config';
import {Subscription} from 'rxjs/internal/Subscription';
import {LegendItemConfig, LegendService} from '../../../safire-components/src/lib/common/legend.service';
import {
  ControlArrConfig,
  ControlConfig,
  ControlConfigNoArr,
  DashboardConfig,
  UTMTableOverlayConfig
} from './dashboard-config';
import {mapConfigSubject} from './SandboxMapConfigProvider';
import {MapParser} from '@ax/ax-angular-map-common';
import {catchError, debounceTime} from 'rxjs/operators';
import {ButtonState} from '../../../safire-components/src/lib/button/safire-button/safire-button.component';
import {of} from 'rxjs';
import {LegendCategories} from "../../../safire-components/src/lib/legend/live-legend/live-legend.component";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    LegendService
  ]
})
export class AppComponent {
  title = 'safire';

  config: DashboardConfig = null;

  private paramSub: Subscription;
  private configSub: Subscription;
  ON: ButtonState.ON;
  OFF: ButtonState.OFF;
  showIt: boolean;
  DEFAULT_LEGEND_CATEGORIES = {
    dataFeed: 'Data Feeds',
    infrastructure: 'Infrastructure',
  };
  legendCategories: LegendCategories = this.DEFAULT_LEGEND_CATEGORIES;

  private parseControlConfigArray(config: ControlArrConfig): ControlConfigNoArr|null {
    let legendConfig: LegendItemConfig = null;
    switch (config[0]) {
      case 'utm-table-overlay':
        return {
          id: 'utm-table',
          type: 'utm-table-overlay',
          config: {
            title: 'UTM Data Table'
          }
        } as UTMTableOverlayConfig;
      case 'utm-menu-table':
        return {
          type: 'utm-menu-table'
        };
      case 'btn.czml.stomp':
        if (config[5]) {
          legendConfig = {
            id: config[1],
            name: config[2],
            category: config.length > 7 ? config[7] : 'dataFeed',
            color: config[6],
            icon: config[5]
          };
        }
        return {
          id: config[1],
          type: 'btn.czml.stomp',
          config: {
            iconUrl: config[3],
            title: config[2],
            exchange: config[4],
            legendConfig
          }
        };
      case 'btn.czml.rest':
        if (config[6] || config[3]) {
          legendConfig = {
            id: config[1],
            name: config[2],
            category: config.length > 8 ? config[8] : 'dataFeed',
            color: config[7],
            iconUrl: config[3],
            icon: config[6]
          };
        }
        return {
          id: config[1],
          type: 'btn.czml.rest',
          config: {
            iconUrl: config[3],
            title: config[2],
            url: config[4],
            refreshInterval: config[5],
            legendConfig
          }
        };
      case 'btn.measure.distance-finder':
        return {
          id: config[1],
          type: 'btn.measure.distance-finder',
          config: {
            iconUrl: config[3] || '/assets/icons/ruler_button.png',
            title: config[2]
          }
        };
      // case 'btn.measure.range-ring':
      //   return {
      //     id: config[1],
      //     type: 'btn.measure.range-ring',
      //     config: {
      //       iconUrl: config[3] || '/assets/icons/range_rings_button.png',
      //       title: config[2]
      //     }
      //   };
      case 'btn.measure.point-selection':
        return {
          id: config[1],
          type: 'btn.measure.point-selection',
          config: {
            iconUrl: config[3] || '/assets/icons/point_select_button.png',
            title: config[2]
          }
        };

    }
    return null;
  }

  private cleanupControlConfig(config: ControlConfig): ControlConfigNoArr|null {
    if (Array.isArray(config)) {
      return this.parseControlConfigArray(config);
    }
    let ret: ControlConfigNoArr;
    switch (config.type) {
      case 'button-group':
        config.config.showAll = (typeof config.config.showAll) === 'boolean' ? config.config.showAll : true;
        config.config.buttons = (config.config.buttons || []).map(c => this.cleanupControlConfig(c)).filter(c => c);
        ret = config;
        break;
      case 'btn.czml.stomp':
      case 'btn.czml.rest':
      case 'radar-group':
      case 'measure.distance-finder':
      case 'measure.range-ring':
      case 'measure.point-selection':
      // case 'czml.btn-rest-loader':
      case 'attribution':
      default:
        ret = config;
    }
    return ret;
  }

  private prepareConfig(config: DashboardConfig): DashboardConfig {
    const newConfig: DashboardConfig = JSON.parse(JSON.stringify(config));
    newConfig.controls = (newConfig.controls || []).map(controlConfig => this.cleanupControlConfig(controlConfig)).filter(c => c);
    return newConfig;

  }

  constructor(private httpClient: HttpClient, private activatedRoute: ActivatedRoute) {
    this.paramSub = activatedRoute.queryParamMap.pipe(debounceTime(500)).subscribe((params) => {
      const url = params.get('config') || '/config/default_config.json';


      this.configSub?.unsubscribe();
      this.configSub = this.httpClient
        .get(url, {responseType: 'json'})
        .pipe(catchError(e => {
          console.error('Error getting default config: ' + e);
          return of(DEFAULT_CONFIG);
        })).subscribe((res: DashboardConfig) => {
          if (res.mapConfig) {
            mapConfigSubject.next(MapParser.parseMapConfig(res.mapConfig));
          } else {
            console.error('No map config supplied');
          }
          this.config = res;
          this.dumpConfigToConsole();
          this.config = this.prepareConfig(this.config);
          this.legendCategories = this.config.legendCategories || this.DEFAULT_LEGEND_CATEGORIES;
        });
    });
  }

  dumpConfigToConsole(): void {
    // tslint:disable:no-console
    console.debug('Loaded config:');
    console.debug(JSON.stringify(this.config, null, 4));
    // tslint:enable:no-console
  }


  asDashboardConfig(config: DashboardConfig): DashboardConfig {
    return config;
  }
}

import {NgModule} from '@angular/core';
import {SafireComponentsComponent} from './safire-components.component';
import {MeasurementModule} from './measurement/measurement.module';
import {CzmlModule} from './czml/czml.module';
import {RadarGroupComponent} from './radar-group/radar-group.component';
import {CommonModule} from '@angular/common';
import {ButtonToggleComponent} from './button-toggle/button-toggle.component';
import {AttributionContentComponent} from './attribution-content/attribution-content.component';
import {AttributionIconComponent} from './attribution-icon/attribution-icon.component';
import {LiveLegendComponent} from './legend/live-legend/live-legend.component';
import {ResourceVisualizerComponent} from './resource-visualizer/resource-visualizer.component';
import {MovableInfoboxComponent} from './movable-infobox/movable-infobox.component';
import {SafireButtonComponent} from './button/safire-button/safire-button.component';
import {SafireButtonGroupComponent} from './button/safire-button-group/safire-button-group.component';
import {SafireButtonContentDirective} from './button/safire-button-content.directive';
import { BookmarksComponent } from './bookmarks/bookmarks.component';
import {FormsModule} from '@angular/forms';
import { DartConfigComponent } from './dart-config/dart-config.component';
import { TrackConfigComponent } from './track-config/track-config.component';
import {BaseLegendComponent} from './legend/base-legend/base-legend.component';
import { SafireMenuContentDirective } from './button/safire-menu-content.directive';
import {UtmTableOverlayComponent} from "./utm/utm-table-overlay/utm-table-overlay.component";
import {UtmMenuTableComponent} from "./utm/utm-menu-table/utm-menu-table.component";
import { AcknowledgementsComponent } from './acknowledgements/acknowledgements.component';

const exportedComponents = [
  SafireComponentsComponent,
  RadarGroupComponent,
  ButtonToggleComponent,
  AttributionContentComponent,
  AttributionIconComponent,
  LiveLegendComponent,
  ResourceVisualizerComponent,
  MovableInfoboxComponent,
  BookmarksComponent,
  SafireButtonComponent,
  SafireButtonGroupComponent,
  SafireButtonContentDirective,
  DartConfigComponent,
  TrackConfigComponent,
  SafireMenuContentDirective,
  UtmTableOverlayComponent,
  UtmMenuTableComponent,
  AcknowledgementsComponent
];


@NgModule({
  declarations: [
    exportedComponents,
    BaseLegendComponent,
    AcknowledgementsComponent
  ],
  imports: [
    MeasurementModule,
    CzmlModule,
    CommonModule,
    FormsModule
  ],
  exports: [...exportedComponents, MeasurementModule, CzmlModule]
})
export class SafireComponentsModule {
}

import {ChangeDetectionStrategy, Component, forwardRef, Input, TemplateRef, ViewChild} from '@angular/core';
import {ControlConfigNoArr} from '../../dashboard-config';
import {AXCesiumWidget} from '@ax/ax-angular-map-cesium';
import {
  AbstractButtonToggle, ButtonDisplayMode,
  ButtonState,
  SafireButtonComponent
} from '../../../../../safire-components/src/lib/button/safire-button/safire-button.component';

// tslint:disable-next-line:max-line-length
import {SafireButtonGroupComponent} from '../../../../../safire-components/src/lib/button/safire-button-group/safire-button-group.component';
import {Observable} from 'rxjs';
import {MenuContentComponent} from "../../../../../safire-components/src/lib/button/menu-content";
import {ChildContentContainer} from "../../../../../safire-components/src/lib/button/ChildContentContainer";

@Component({
  selector: 'app-control-wrapper',
  templateUrl: './control-wrapper.component.html',
  providers: [
    {provide: AXCesiumWidget, useExisting: forwardRef(() => ControlWrapperComponent)},
    {provide: SafireButtonComponent, useExisting: forwardRef(() => ControlWrapperComponent)},
    {provide: MenuContentComponent, useExisting: forwardRef(() => ControlWrapperComponent)},
    {provide: SafireButtonGroupComponent, useExisting: forwardRef(() => ControlWrapperComponent)}
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ControlWrapperComponent extends AXCesiumWidget implements AbstractButtonToggle, ChildContentContainer {
  @Input() config: ControlConfigNoArr;
  @Input() state: any;
  @ViewChild(AXCesiumWidget) child: AXCesiumWidget;
  @ViewChild(SafireButtonComponent) button: SafireButtonComponent;
  @ViewChild(MenuContentComponent) menuContent: MenuContentComponent;
  @ViewChild(SafireButtonGroupComponent) menu: SafireButtonGroupComponent;
  ON: ButtonState = ButtonState.ON;
  OFF: ButtonState = ButtonState.OFF;

  get buttonWidget(): TemplateRef<any> {
    return this.child?.buttonWidget;
  }

  get attributionWidget(): TemplateRef<any> {
    return this.child?.attributionWidget;
  }

  get generalWidget(): TemplateRef<any> {
    return this.child?.generalWidget;
  }

  get menuTemplate(): TemplateRef<any>{
    return this.menu?.menuTemplate;
  }



  constructor() {
    super();
  }

  childContent: TemplateRef<any>;
  CELL: ButtonDisplayMode = ButtonDisplayMode.CELL;
  CELLTEXT: ButtonDisplayMode = ButtonDisplayMode.CELLTEXT;

  getTitle(): string {
    return this.button.getTitle();
  }

  activate(): void {
    return this.button.activate();
  }

  setState(state: ButtonState, propogate: boolean = true): void {
    return this.button.setState(state, propogate);
  }

  watchState(): Observable<ButtonState> {
    return this.button.watchState();
  }

  watchActivation(): Observable<boolean> {
    return this.button.watchActivation();
  }

  get buttonTemplate(): TemplateRef<any> {
    return this.button?.buttonTemplate;
  }


  handleBtnClose(): void {

    this.state = false;
    if (this.button) {
      this.button.setState(ButtonState.OFF);
    }
  }

  get childContentTemplate(): TemplateRef<any>{
    return this.button?.childContentTemplate;
  }
}

<ng-template #buttonTemplate>
  <lib-safire-button [btnIcon]="btnIcon" [embedded]="true"
                     [notifyIfOpen]="true"
                     [showText]="false"
                     (stateChange)="toggle($event)"></lib-safire-button>
  <div [ngClass]="{'dropdown-menu-hidden': !enabled, 'dropdown-menu-visible': enabled}" id="bookmarks-menu">

    <div class="title">Bookmarks</div>
    <div class="bookmarks-container">
      <ul>
        <li style="margin-left: 10px;" *ngFor="let bookmark of bookmarks">
          <button
            [ngClass]="{'cesium-button': true}"
            style="width: 200px; text-overflow: ellipsis; white-space: nowrap;"
            (click)="goto(bookmark)"
          >{{bookmark.name}}</button>
          <button
            [ngClass]="{'cesium-button': true, 'del-btn': true, 'dart-config-btn': true}"
            style="margin: 0 auto;"
            (click)="updateBookmarks('DELETE', bookmark)">DELETE</button>
        </li>
      </ul>
    </div>
    <div class="title" style="margin-top: 15px;">Add New Bookmark</div>
    <div class="title2">Name</div>
    <input type="text" id="book-name" value="" />
    <button [ngClass]="{'dart-config-btn': true}" (click)="updateBookmarks('POST')">CAPTURE BOOKMARK</button>
    <div id="crud-alert"></div>
  </div>


</ng-template>

<ng-template #widgetTemplate>
  <div class="menu-overlay" (click)="closeWindow()" *ngIf="enabled">

  </div>
</ng-template>

<ng-template #generalWidgetTemplate>
  <!--<ng-container>-->
    <div id="dart-config" style="display: none;">
      <div id="dart-config-top">DART Configuration<btn id="close-btn" (click)="hideConfig()">X</btn></div>
         <div class="dart-config-menu">
           <div class="config-div">
             <div class="dart-config-header" style="margin-bottom:15px;">AUDIO ALERTS</div>
             <span style="color:white;">Enable Alerts</span>
             <br />
             <label class="switch">
               <input type="checkbox" (change)="toneToggle('toggle')" checked>
               <span class="slider round"></span>
             </label>
             <div class="dart-info-text" style="margin-bottom:15px;">Toggle to globally mute/unmute all audible DART alerts</div>
             <span style="color:white;">Unmute All Data Feeds</span>
             <button class="dart-config-btn" style="width:100%;" (click)="toneToggle('clear')">
               UNMUTE
             </button>
             <div class="dart-info-text">Use this button to unmute all individual data feeds</div>
           </div>
      <div class="config-div">
      <div class="dart-config-header">THREAT RADIUS</div>
      <div class="dart-info-text">Use slider to adjust DART intercept times</div>
      <div class="dart-slider">
        <input id="dart-slider" type="range" min="15" max="300" step="15" [value]="currOuterRadius" (input)="sliderUpdate($event.target)">
      </div>
      <div class="radius-readout">
        <table class="threat-diameter">
          <tr>
            <td></td>
            <td>OUTER</td>
            <td>MIDDLE</td>
            <td>INNER</td>
          </tr>
          <tr>
            <td>CURRENT</td>
            <td id="current-outer-ring">{{currOuterRadius}}s</td>
            <td id="current-middle-ring">{{currMiddleRadius}}s</td>
            <td id="current-inner-ring">{{currInnerRadius}}s</td>
          </tr>
          <tr>
            <td>CHANGE</td>
            <td id="dart-outer-ring">{{outerRadius}}s</td>
            <td id="dart-middle-ring">{{middleRadius}}s</td>
            <td id="dart-inner-ring">{{innerRadius}}s</td>
          </tr>
        </table>
      </div>
      <button class="dart-config-btn" style="width:100%;" (click)="submitNewRadii()">SUBMIT</button>
      <div id="dart-confirm-radii"></div>
        <div *ngIf="confMsgRadii === RadiiMsg.success"
                class="dart-confirm-radii"
                style="color:lime">
          New threat radii have been successfully submitted.
        </div>
        <div *ngIf="confMsgRadii === RadiiMsg.failure"
                class="dart-confirm-radii"
                style="color:red">
          There was a problem submitting your radii. Please try again.
        </div>
      </div>
      <div class="config-div">
      <div class="dart-config-header">MINIMUM THREAT RADIUS</div>
      <div class="dart-info-text">Use slider to adjust minimum DART threat radius</div>
      <div class="dart-slider">
        <input id="dart-slider-min" type="range" min="15" max="3000" step="15" [value]="currOuterMinRadius" (input)="sliderMinUpdate($event.target)">
      </div>
      <div class="radius-readout">
        <table class="threat-diameter">
          <tr>
            <td></td>
            <td>OUTER</td>
            <td>MIDDLE</td>
            <td>INNER</td>
          </tr>
          <tr>
            <td>CURRENT</td>
            <td id="current-min-outer-ring">{{currOuterMinRadius}}m</td>
            <td id="current-min-middle-ring">{{currMiddleMinRadius}}m</td>
            <td id="current-min-inner-ring">{{currInnerMinRadius}}m</td>
          </tr>
          <tr>
            <td>CHANGE</td>
            <td id="dart-min-outer-ring">{{outerMinRadius}}m</td>
            <td id="dart-min-middle-ring">{{middleMinRadius}}m</td>
            <td id="dart-min-inner-ring">{{innerMinRadius}}m</td>
          </tr>
        </table>
      </div>
      <button class="dart-config-btn" style="width:100%;" (click)="submitNewMinRadii()">SUBMIT</button>
      <div *ngIf="confMsgMinRadii === RadiiMsg.success"
                class="dart-confirm-radii"
                style="color:lime;font-size:12px;">
          New threat radii have been successfully submitted.
        </div>
        <div *ngIf="confMsgMinRadii === RadiiMsg.failure"
                class="dart-confirm-radii"
                style="color:red;font-size:12px;">
          There was a problem submitting your radii. Please try again.
        </div>
      </div>
      <div class="config-div">
      <div class="dart-config-header">
        SUBMIT PROTECTED ZONE
      </div>
      <button class="dart-config-btn"
              id="dart-draw-btn"
              style="width:100%;"
              (click)="toggleDraw()">{{ drawInd? 'STOP DRAWING' : 'DRAW'}}</button>
      <button class="dart-config-btn" id="dart-clear-btn" (click)="clearDraw()" style="display:none;text-align:center;float:none;margin:0 auto;width:45%;">CLEAR GEOMETRY</button>
      <div id="protected-zone-name"
           style="margin:10px;"
           *ngIf="drawInd">
        <form #submitGeomName="ngForm"
              (ngSubmit)="submitGeom(submitGeomName.value)">
        <label style="display:inline-block;color:white;">
          Name: &nbsp;
        </label>
        <input ngModel
               name="name"
               type="text"
               style="display:inline-block;" />
      <button type="submit"
              class="dart-config-btn dart-submit-geom-btn"
              *ngIf="drawInd">
        SUBMIT GEOMETRY
      </button>
        </form>
      </div>
      <div *ngIf="confGeomMsg"
           id="submit-geom-alert"
           style="color:red;font-size:12px;">
        There was an issue submitting your geometry. <br> Please clear selection and try again.
      </div>
      <div *ngIf="zoneNameAlert"
        id="zone-name-alert"
        style="color:red;font-size:12px;">
        You must enter a unique name for this protected zone.
      </div>
        <div *ngIf="geomSuccess"
           id="success-geom-alert"
           style="color:lime;font-size:12px;">
        Success submitting geometry!
      </div>
      </div>
      <div class="config-div">
      <div class="dart-config-header">ZONES</div>
        <table class="threat-diameter">
          <tr>
            <td>NAME</td>
            <td>ACTIVE</td>
          </tr>
      <tr id="zone-list"
        *ngFor="let zone of protectedZones.zones; let i = index;">
            <td>{{zone.name}}</td>
            <td><input type="checkbox" id="{{zone.name}}" [checked]="zone.active" (change)="zoneActivator($event, zone.name)"></td>
            <td><button class="dart-config-btn" (click)="deleteZone($event, zone.name)">DELETE</button></td>
      </tr>
        </table>
      </div>
      </div>
    </div>

  <!--</ng-container>-->
</ng-template>

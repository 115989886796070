<ng-template #generalWidgetTemplate>
  <div (click)="toggleContracted()" class="ack-container" [ngClass]="{'ack-expanded' : !this.contractBool}">
    <div class="ack-btn" [ngClass]="{'ack-btn-expanded' : !this.contractBool}"></div>
    <div class="ack-list">
      <ul>
        <li>SAFIRE-X | {{sysDate}} AX Enterprize</li>
        <li style="color: rgb(255,255,255,.5);">CLUE v1.0.0 ({{gitCommitHash}})</li>
      </ul>
      </div>
  </div>
</ng-template>



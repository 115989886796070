<ng-template #widgetTemplate>
  <ng-container *ngIf="active">
    <div id="utm-window" class="utm-window" [ngStyle]="windowStyle" #utmWindow>
      <div id="utm-winbar" class="utm-winbar">
        <span>UTM Data</span>
        <span id="close-btn" style="float:right;cursor:pointer;padding-right:6px;"
              (click)="close()">X</span>
      </div>
      <div class="utm-tabs">
        <div class="tabs-wrapper">
          <button type="button" (click)="switchTab('EVENTS')" [ngClass]="utmEventTabClasses" id="utm-tab-events">UTM
            EVENTS
          </button>
          <button type="button" (click)="switchTab('OPERATIONS')" [ngClass]="operationTabClasses" id="utm-tab-ops">
            OPERATIONS
          </button>
          <button type="button" (click)="switchTab('CONSTRAINTS')" [ngClass]="constraintTabClasses" id="utm-tab-cons">
            CONSTRAINTS
          </button>
        </div>
      </div>
      <div id="utm-table" class="tabcontent">
        <div class="divTableRow">
          <div class="divTableCell">Start Time</div>
          <div class="divTableCell">End Time</div>
          <div class="divTableCell">Class</div>
          <div class="divTableCell">State</div>
          <div class="divTableCell">Priority/Type</div>
          <div class="divTableCell">ID</div>
          <div class="divTableCell">USS</div>
<!--          <div class="divTableCell">Submit Time</div>-->
          <div class="divTableCell">Update Time</div>
        </div>
        <ng-container [ngSwitch]="currentTab">
          <ng-container *ngSwitchCase="'EVENTS'">
            <ng-container *ngFor="let event of events">
              <div class="divTableRow" [ngStyle]="event.style">
                <div class="divTableCell">{{ event.start_time}}</div>
                <div class="divTableCell">{{ event.end_time}}</div>
                <div class="divTableCell">{{ event.class}}</div>
                <div class="divTableCell">{{ event.state}}</div>
                <div class="divTableCell">{{ event.class === 'Operation'? event.prio_type: event.prio_type}}</div>
                <div class="divTableCell">{{ event.id}}</div>
                <div class="divTableCell">{{ event.uss}}</div>
<!--                <div class="divTableCell">{{ event.submit_time}}</div>-->
                <div class="divTableCell">{{ event.update_time}}</div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="'OPERATIONS'">
            <ng-container *ngFor="let operation of operations">
              <div class="divTableRow" [ngStyle]="operation.style">
                <div class="divTableCell">{{ operation.start_time}}</div>
                <div class="divTableCell">{{ operation.end_time}}</div>
                <div class="divTableCell">{{ operation.class}}</div>
                <div class="divTableCell">{{ operation.state}}</div>
                <div class="divTableCell">{{ operation.prio_type}}</div>
                <div class="divTableCell">{{ operation.id}}</div>
                <div class="divTableCell">{{ operation.uss}}</div>
<!--                <div class="divTableCell">{{ operation.submit_time}}</div>-->
                <div class="divTableCell">{{ operation.update_time}}</div>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'CONSTRAINTS'">
            <ng-container *ngFor="let constraint of constraints">
              <div class="divTableRow" [ngStyle]="constraint.style">
                <div class="divTableCell">{{ constraint.start_time}}</div>
                <div class="divTableCell">{{ constraint.end_time}}</div>
                <div class="divTableCell">{{ constraint.class}}</div>
                <div class="divTableCell">{{ constraint.state}}</div>
                <div class="divTableCell">{{ constraint.prio_type}}</div>
                <div class="divTableCell">{{ constraint.id}}</div>
                <div class="divTableCell">{{ constraint.uss}}</div>
<!--                <div class="divTableCell">{{ constraint.submit_time}}</div>-->
                <div class="divTableCell">{{ constraint.update_time}}</div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <!-- This shouldn't happen -->
          </ng-container>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #buttonTemplate>
  <lib-safire-button [btnIcon]="'/assets/icons/utm_data_button.png'" [embedded]="true"
                     [notifyIfOpen]="true"
                     [showText]="false"
                     (stateChange)="toggle($event)" [state]="state"></lib-safire-button>
  <!--<div class="cell">
    <button type="button" title="{{btnAlt}}"
            [ngClass]="{'button-active': active, 'cesium-button': true, 'menu-btn': true}"
            (click)="toggleOverlay()"><img alt="{{btnAlt}}" class="button" src="/assets/icons/utm_data_button.png"/>
    </button>
  </div>-->

</ng-template>
<ng-container [ngTemplateOutlet]="widgetTemplate"></ng-container>

import {Injectable, OnDestroy, Optional} from '@angular/core';
import {StompService} from './stomp.service';
import {DateTime} from 'luxon';
import {Subscription} from 'rxjs';

/**
 * This service attempts to create a synced timestamp to prevent incorrect client time configurations from stopping tracks from displaying.
 */
@Injectable({
  providedIn: 'root'
})
export class TimeSyncService implements OnDestroy {

  private baseDateTime: DateTime;
  private offsetBase: number;
  private stompSub: Subscription;
  private eventSub: Subscription;

  /**
   * Initializes a TimeSyncService, optional uses a stomp service to receive time sync events.
   * @param stompService Stomp Service used to watch for time sync events.
   */
  constructor(@Optional() private stompService: StompService) {
    this.setBaseDateTime(DateTime.now());

    if (this.stompService) {
      this.stompSub = this.stompService.getStompClient().subscribe((client) => {
        this.eventSub?.unsubscribe();
        if (client) {
          this.eventSub = client.watch('/exchange/czml_test_topic/time_sync').subscribe((msg) => {
            const rawBody = JSON.parse(msg.body);
            if (!rawBody.length) {
              return;
            }
            const syncEvent = rawBody[0];
            this.setBaseDateTime(DateTime.fromISO(syncEvent.backend_current_time));
          });
        }
      });
    }
  }


  /**
   * Retrieves a synced timestamp
   * @returns Returns a timestamp synced from a base timestamp or clock sync event
   */
  getSyncedNowTimestamp(): DateTime {
    return this.baseDateTime.plus(performance.now() - this.offsetBase);
  }

  ngOnDestroy(): void {
    this.eventSub?.unsubscribe();
    this.stompSub?.unsubscribe();
  }

  private setBaseDateTime(dateTime: DateTime): void {
    console.debug(`Setting base time: ${dateTime.toISO()}`);
    this.baseDateTime = dateTime;
    this.offsetBase = performance.now();
  }
}

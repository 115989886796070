<ng-template #widgetTemplate>
  <div id="affiliation" class="affiliation" [style.top]="showAfil ? '0' : '-350px'">
    <button id="hostile-but" alt="Affiliate Hostile" class="afil-but" style="display:
    inline-block; border: 1px solid red;"
    (click)="affiliate('MARK_HOSTILE')">
      HOSTILE</button>
    <button id="neutral-but" alt="Affiliate Neutral" class="afil-but" style="border: 1px solid green;"
            (click)="affiliate('MARK_NEUTRAL')">
      NEUTRAL</button>
    <button id="unknown-but" alt="Affiliate Unknown" class="afil-but" style="border: 1px solid yellow;"
            (click)="affiliate('MARK_UNKNOWN')">
      UNKNOWN</button>
    <button id="friendly-but" alt="Affiliate Friendly" class="afil-but" style="border: 1px solid blue;"
            (click)="affiliate('MARK_FRIENDLY')">
      FRIENDLY</button>
    <button id="clear-but" alt="Clear Affiliation"class="afil-but"
            (click)="affiliate('CLEAR')">
      CLEAR</button>
    <img id="mute-but"
         alt="MUTE/UNMUTE"
         [src]="localMute ? '../assets/icons/saf_mute.png' : '../assets/icons/saf_unmute.png'"
         (click)="muteIndividual()" />
<!--      <div>-->
<!--        <button id="kill-but" style="display:-->
<!--        inline-block; margin: 5px; color: black; border-radius: 5px; background-color: red;"-->
<!--                (click)="affiliate('KILL')">-->
<!--          COUNTERMEASURE</button>-->
<!--        <button id="focus-but" class="afil-but"-->
<!--                (click)="affiliate('FOCUS')">-->
<!--          TOGGLE FOCUS</button>-->
<!--    </div>-->
  </div>
  <div id="zoom-alert" style="display:none;">Infrastructure has been hidden at the current zoom level to improve performance. Zoom in to continue to view infrastructure.</div>
  <div id="utm-alert"
       *ngIf="getUTMConn()">UTM Connectivity Warning: Attempting to reconnect while enabled.</div>
</ng-template>

<!--<button title="Upload" type="button"-->
<!--        [ngClass]="{'cesium-button': true, 'menu-btn': true}"-->
<!--        (click)="fireUpload()">-->
<!--  UPLOAD-->
<!--</button>-->

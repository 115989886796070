import {Injectable, TemplateRef} from "@angular/core";


export interface MenuContent {
  menuContent: TemplateRef<any>;

}
@Injectable() export class MenuContentComponent implements MenuContent{
  menuContent: TemplateRef<any>;
}

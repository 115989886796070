import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, ReplaySubject} from "rxjs";
import {map, switchMap} from "rxjs/operators";

export interface UTMEvent {
  update_time: string;
  start_time: string;
  end_time: string;
  class: string;
  state: string;
  id: string;
  uss: string;
  submit_time: string;
  latitude?: number;
  longitude?: number;
  prio_type: string;
}

export class UTMEventStore {
  private operationsSubject = new BehaviorSubject<UTMEvent[]>([]);
  private constraintSubject = new BehaviorSubject<UTMEvent[]>([]);

  setOperations(operations: UTMEvent[]): void {
    this.operationsSubject.next(operations);
  }

  setConstraints(constraints: UTMEvent[]): void {
    this.constraintSubject.next(constraints);
  }

  watchOperations(): Observable<UTMEvent[]> {
    return this.operationsSubject;
  }

  watchConstraints(): Observable<UTMEvent[]> {
    return this.constraintSubject;
  }

  watchEvents(): Observable<UTMEvent[]> {
    return combineLatest([this.watchOperations(), this.watchConstraints()]).pipe(map(([operations, constraints]) => {
      return [...operations, ...constraints];
    }));
  }

  setFromCZML(czml: any): void {
    if (czml instanceof Array) {
      const distinct = (value: UTMEvent, index:number, self:UTMEvent[]) =>{
        return self.findIndex(value1 => value1.id === value.id) === index;
      };
      const events = czml.map(e => UTMEventStore.parseEvent(e)).filter(op => op).filter(distinct);
      //const operations: UTMEvent[] = czml.map(c => this.parseOperation(c)).filter(op => op);
      //const constraints: UTMEvent[] = czml.map(c => this.parseConstraint(c)).filter(con => con);

      this.setOperations(events.filter(e => e.class === 'Operation'));
      this.setConstraints(events.filter(e => e.class === 'Constraint'));
    }
  }

  private parseOperation(czml: any): UTMEvent {
    const extendedData = czml.extended_data;
    if (czml.type === 'utm' && extendedData) {
      if (extendedData.gufi) {
        return {
          update_time: extendedData.update,
          start_time: extendedData.start,
          end_time: extendedData.end,
          class: 'Operation',
          state: extendedData.state,
          id: extendedData.gufi,
          uss: extendedData.uss,
          submit_time: extendedData.submission,
          prio_type: extendedData.prio_type
        };
      }
    }
    return null;
  }

  private parseConstraint(czml: any): UTMEvent {
    const extendedData = czml.extended_data;
    if (extendedData && extendedData.id) {
      return {
        update_time: 'N/A',
        start_time: extendedData.start,
        end_time: extendedData.end,
        class: 'Constraint',
        state: 'N/A',
        id: extendedData.id,
        uss: extendedData.uss,
        submit_time: 'N/A',
        prio_type: extendedData.prio_type
      };
    }
    return null;
  }

  private static parseEvent(e: any): UTMEvent {
    if(!e?.events){
      return null;
    }
    return {
      class: e.events.class,
      id: e.events.identifier,
      state: e.events.state,
      submit_time: e.events.submit_time,
      update_time: e.events.update_time,
      start_time: e.events.start_time,
      end_time: e.events.end_time,
      uss: e.events.uss_name,
      latitude: e.events.lat,
      longitude: e.events.lon,
      prio_type: e.events.prio_type
    };
  }
}


@Injectable({
  providedIn: 'root'
})
export class UtmService {
  eventStores: UTMEventStore[] = [];
  eventStoreSubject = new BehaviorSubject<UTMEventStore[]>([]);

  constructor() {

  }


  registerEventStore(eventStore: UTMEventStore): void {
    this.eventStores.push(eventStore);
    this.eventStoreSubject.next(this.eventStores);
  }

  unregisterEventStore(eventStore: UTMEventStore): void {
    this.eventStores = this.eventStores.filter(test => test !== eventStore);
    this.eventStoreSubject.next(this.eventStores);
  }

  watchOperations(): Observable<UTMEvent[]> {
    return this.eventStoreSubject.pipe(switchMap(stores => {
      return combineLatest(stores.map((store) => store.watchOperations()))
        .pipe(map((allOperations) => {
          return [].concat(...allOperations);
        }));
    }));
  }

  watchConstraints(): Observable<UTMEvent[]> {
    return this.eventStoreSubject.pipe(switchMap(stores => {
      return combineLatest(stores.map((store) => store.watchConstraints()))
        .pipe(map((allConstraints) => {
          return [].concat(...allConstraints);
        }));
    }));
  }

  watchEvents(): Observable<UTMEvent[]> {
    return combineLatest([this.watchOperations(), this.watchConstraints()]).pipe(map(([operations, constraints]) => {
      return [...operations, ...constraints];
    }));
  }

}

import {Component, forwardRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DartConfigComponent} from '../dart-config/dart-config.component';
import {Viewer} from 'cesium';
import {AXCesiumWidget, CesiumService} from '@ax/ax-angular-map-cesium';
export let TrackConfigSingleton: TrackConfigComponent = null;
import {CesiumComponent} from '../common/CesiumComponent';
import {BehaviorSubject, ReplaySubject} from 'rxjs';
import {LegendCategories} from '../legend/live-legend/live-legend.component';


@Component({
  selector: 'lib-track-config',
  templateUrl: './track-config.component.html',
  styleUrls: ['./track-config.component.css'],
  providers: [{provide: AXCesiumWidget, useExisting: forwardRef(() => TrackConfigComponent)}]
})
export class TrackConfigComponent extends CesiumComponent implements OnDestroy, OnInit {
  @ViewChild('generalWidgetTemplate') generalWidgetTemplate: TemplateRef<any>;
  /** This is the label configuration passed as a prop from app.component.ts/html.
   * This is what we use to propagate the local labelFormat which interacts with the checkboxes
   * that handle changing the formatting of hte labels and how they are displayed in stomp-listener.component.ts
   */
  @Input() labelConfig: any;
  public drawHeads = true;
  public drawTails = true;
  public labelFormat: any = {
    showAlt: true,
    showId: true,
    showSpeed: true,
    altUnits: '',
    altMethod: '',
    altFormat: '',
    speedUnits: '',
    speedTime: '',
    speedFormat: ''
  };
  public drawHeadsSubject = new BehaviorSubject<boolean>(true);
  public drawTailsSubject = new BehaviorSubject<boolean>(true);
  /** Create a state to indicate that we should blank out track labels with incorrect label format when the
   * user toggles a change in the label config. This logic is located in stomp-listener.component.ts
   */
  public updateLabelFormatSubject = new BehaviorSubject<boolean>(false);
  private viewer: Viewer;

  constructor(cesiumService: CesiumService) {
    super(cesiumService);
  }

  get generalWidget(): TemplateRef<any> {
    return this.generalWidgetTemplate;
  }

  hideConfig(event): void {
    document.getElementById('track-config').style.display = 'none';
  }

  /** Rewrites the label format based on whether one of the config toggles for labels has been changed.
   * @param e: input $event from checkboxes which changes altitude units/method.
   */
  changeLabels(e): void {
    if (['showId', 'showAlt', 'showSpeed'].includes(e.target.name)) {
      this.labelFormat[e.target.name] = !this.labelFormat[e.target.name];
    } else {
      this.labelFormat[e.target.name] = e.target.value;
    }
    /** Rewrite the label format that matches the current selection. This directly matches a corresponding
     * label format that comes down with the track object for easy correlation. stomp-listener.component.ts reads the
     * current value during new track updates and makes sure the display altitude matches what is listed here.
     */
    this.labelFormat.altFormat = 'alt' + this.labelFormat.altMethod + '_' + this.labelFormat.altUnits;
    if (this.labelFormat.speedUnits === 'knots') {
      this.labelFormat.speedFormat = 'speed_knots';
    } else {
      this.labelFormat.speedFormat = 'speed_' + this.labelFormat.speedUnits + '_' + this.labelFormat.speedTime;
    }
    /** Update the state that indicates we should blank out the track labels that show the old track
     * labels with incorrect label format. This logic is located in stomp-listener.component.ts
     */
    this.updateLabelFormatSubject.next(true);
  }

  toggleHeadsTails(event, type): void {
    const headsBtn = document.getElementById('heads-btn');
    const tailsBtn = document.getElementById('tails-btn');
    if (type === 'heads') {
      if (this.drawHeads === true) {
        headsBtn.classList.remove('track-config-btn-selected');
        headsBtn.classList.add('track-config-btn');
        this.drawHeads = false;
      } else {
        headsBtn.classList.remove('track-config-btn');
        headsBtn.classList.add('track-config-btn-selected');
        this.drawHeads = true;
      }

      this.drawHeadsSubject.next(this.drawHeads);
    }
    if (type === 'tails') {
      if (this.drawTails === true) {
        tailsBtn.classList.remove('track-config-btn-selected');
        tailsBtn.classList.add('track-config-btn');
        this.drawTails = false;
      }
      else {
        tailsBtn.classList.remove('track-config-btn');
        tailsBtn.classList.add('track-config-btn-selected');
        this.drawTails = true;
      }
      this.drawTailsSubject.next(this.drawTails);
    }
  }

  onViewerInit(viewer: Viewer): void {
    this.viewer = viewer;
    /** The following code looks at the default config provided by app.default_config.ts / default_config.json
     * and assigns a local variable to what is configured ahead of time either in lens or via app.default_config.ts.
     * It creates a singular format based on the config, checks to make sure everything is kosher, and then
     * creates the singleton which gets passed to stomp-listener.component.ts to make sure it's doing the appropriate
     * label format checks when it needs to.
     */
    this.labelConfig.altUnits = this.labelConfig.altUnits.toLowerCase();  // Make sure the units are lowercase
    this.labelConfig.altMethod = this.labelConfig.altMethod[0].toUpperCase() +
      this.labelConfig.altMethod.substring(1).toLowerCase();  // Make sure the method is in title  case
    if (['ft', 'm', 'km', 'mi'].includes(this.labelConfig.altUnits)) {
      this.labelFormat.altUnits = this.labelConfig.altUnits;
    } else {
      this.labelFormat.altUnits = 'm';
      console.warn('Configuration Error: Your default config for altitude units is "' +
        this.labelConfig.altUnits +
        '" which is not a viable option (Viable Options: "m", "km", "mi", or "ft"). ' +
        'Your default altitude units has been reset to "m".');
    }
    if (['Hae', 'Msl'].includes(this.labelConfig.altMethod)) {
      this.labelFormat.altMethod = this.labelConfig.altMethod;
    } else {
      this.labelFormat.altMethod = 'Hae';
      console.warn('Configuration Error: Your default config for altitude method is "' +
        this.labelConfig.altMethod +
        '" which is not a viable option (Viable Options: "Hae" or "Msl"). ' +
        'The default altitude method has been reset to "Hae".');
    }
    this.labelConfig.speedUnits = this.labelConfig.speedUnits.toLowerCase();  // Make sure the units are lowercase
    this.labelConfig.speedTime = this.labelConfig.speedTime.toLowerCase();
    if (['ft', 'm', 'km', 'mi', 'knots'].includes(this.labelConfig.speedUnits)) {
      this.labelFormat.speedUnits = this.labelConfig.speedUnits;
    } else {
      this.labelFormat.speedUnits = 'm';
      console.warn('Configuration Error: Your default config for speed units is "' +
        this.labelConfig.speedUnits +
        '" which is not a viable option (Viable Options: "m", "km", "mi", "ft", or optionally "knots"). ' +
        'Your default speed units have been reset to "m".');
    }
    if (['s', 'min', 'hr'].includes(this.labelConfig.speedTime)) {
      this.labelFormat.speedTime = this.labelConfig.speedTime;
    } else {
      this.labelFormat.speedTime = 's';
      console.warn('Configuration Error: Your default config for speed time delta is "' +
        this.labelConfig.speedTime +
        '" which is not a viable option (Viable Options: "s", "m", or "hr"). The default altitude method has been reset to "Hae".');
    }
    this.labelFormat.showAlt = this.labelConfig.showAlt;
    this.labelFormat.showSpeed = this.labelConfig.showSpeed;
    this.labelFormat.showId = this.labelConfig.showId;
    this.labelFormat.altFormat = 'alt' + this.labelFormat.altMethod + '_' + this.labelFormat.altUnits;
    if (this.labelConfig.altFormat !== this.labelFormat.altFormat) {
      this.labelConfig.altFormat = 'alt' + this.labelFormat.altMethod + '_' + this.labelFormat.altUnits;
    }
    if (this.labelFormat.speedUnits !== 'knots') {
      this.labelFormat.speedFormat = 'speed_' + this.labelFormat.speedUnits + '_' + this.labelFormat.speedTime;
    } else {
      this.labelFormat.speedFormat = 'speed_knots';
    }
    if (this.labelConfig.speedFormat !== this.labelFormat.speedFormat) {
      if (this.labelFormat.speedUnits !== 'knots') {
        this.labelConfig.speedFormat = 'speed' + '_' + this.labelFormat.speedUnits + '_' + this.labelFormat.speedTime;
      } else {
        this.labelConfig.speedFormat = 'knots';
      }
    }
    TrackConfigSingleton = this;
  }

  ngOnInit(): void {
  }

}

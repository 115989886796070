<ng-template #buttonTemplate>
  <lib-safire-button [btnIcon]="btnIcon" [embedded]="true"
                     [notifyIfOpen]="true"
                     [showText]="false"
                     (stateChange)="toggle($event)"></lib-safire-button>

  <div [ngClass]="{'dropdown-menu-hidden': !enabled, 'dropdown-menu-visible': enabled}" id="upload-menu">
    <div class="title">Upload Geo Data</div>
    <div style="padding-bottom: 10px;padding-top:20px;">
      *.czml, *.kml, *.geojson, *.waypoints, *.mission, *.fp, *.json
    </div>
    <button title="Upload" type="button"
            [ngClass]="{'cesium-button': true, 'menu-btn': true}"
            (click)="fireUpload()">
      UPLOAD
    </button>
    <div style="padding-top: 10px;">
      Uploaded:
    </div>
    <div class="info-text"
         *ngFor="let fileName of sourceData.sources; let i = index;">
      <button class="close-buttons" type="button" title="Close {{fileName.name}}"
              *ngIf="fileName"
              [ngClass]="{'cesium-button': true}"
              (click)="destroyDataSource(fileName.name)">
        X&nbsp;&nbsp;&nbsp;{{fileName.name}}
      </button>
      </div>
      <div class="kmlTreeContainer">
    <ul id="kmlTreeUl">
      <li *ngFor="let Node of tree" style="color:white; list-style-type: none; ">
        <ng-template *ngTemplateOutlet="treeNodeImpl; context: { $implicit: Node }"></ng-template>
      </li>
    </ul>
  </div>
  </div>
  <input accept=".kml,.czml,.geojson,.waypoints,.fp,.mission,.json" multiple
         (input)="handleInput($event)"
         [type]="'file'" style="display: none;" #inputElement>
</ng-template>

<ng-template #treeNodeImpl let-Node>
  <span *ngIf="Node.type == 'P'">&nbsp; &nbsp;</span>
  <button [ngClass]="{'toggleButtons': true}"
    *ngIf="Node.type == 'F'"
    (click)="Node['remove']()">Close</button>
  <button [ngClass]="{'toggleButtons':true }"
          *ngIf="Node.type == 'D'"
          (click)="Node['toggleCollapse']($event)">v</button>
  <button [ngClass]="{'toggleButtons':true }"
          *ngIf="Node.type == 'f'"
          (click)="Node['toggleCollapse']($event)">v</button>
  <span style="display:none;">{{Node.type}} :: </span>{{Node.name}}
  <input type="checkbox" [(ngModel)]="Node.checked"
    (click)="Node['toggleHide']($event)"/>
  <ul *ngIf="Node.childrenLen > 0" [ngClass]="{'border':true}">
    <li style="color:white; list-style-type: none; text-indent: -.9em; padding-left: .9em;"
        *ngFor="let subNode of Node.documents"><ng-container *ngTemplateOutlet="treeNodeImpl; context:{ $implicit: subNode }"></ng-container>
    <li style="color:white; list-style-type: none; text-indent: -.9em; padding-left: .9em;"
        *ngFor="let subNode2 of Node.folders"><ng-container *ngTemplateOutlet="treeNodeImpl; context:{ $implicit: subNode2 }"></ng-container>
    <li style="color:white; list-style-type: none; text-indent: -.9em; padding-left: .9em;"
        *ngFor="let subNode3 of Node.placemarks"><ng-container *ngTemplateOutlet="treeNodeImpl; context:{ $implicit: subNode3 }"></ng-container>
  </ul>
</ng-template>
<ng-template #widgetTemplate>
<div class="menu-overlay" (click)="closeWindow()" *ngIf="enabled">

</div>
</ng-template>


<ax-cesium-container *ngIf="config"
                     [navigationInstructionsInitiallyVisible]="false"
                     [timeline]="config.general.timeline"
                     [projectionPicker]="true"
                     [disableColumbusScene]="true"
                     [disable2DScene]="true"
                     [targetFrameRate]="60">
  <app-cesium-extra-init></app-cesium-extra-init>
  <app-control-wrapper *ngFor="let _config of asDashboardConfig(config).controls" [config]="_config" [state]="config.states[_config.id]"></app-control-wrapper>

  <lib-acknowledgements></lib-acknowledgements>
  <lib-movable-infobox [dartUrl]="config?.dart?.url || ''"></lib-movable-infobox>
  <lib-dart-config></lib-dart-config>
  <lib-track-config [labelConfig]="config?.general?.labelConfig"></lib-track-config>
  <lib-resource-visualizer></lib-resource-visualizer>
  <lib-live-legend [legendCategories]="legendCategories"></lib-live-legend>
  <!--<lib-live-legend [categoryId]="'dataFeed'" [name]="'Data Feeds'"></lib-live-legend>
  <lib-live-legend [categoryId]="'infrastructure'" [name]="'Infrastructure'"></lib-live-legend>-->

  <!--<lib-utm-table-overlay></lib-utm-table-overlay>-->

</ax-cesium-container>

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MuteBoolService {

  private isMuted = new BehaviorSubject<boolean>(false);
  isMuted$: Observable<boolean>;

  constructor() {
    this.isMuted$ = this.isMuted.asObservable();
  }

  toggleisMuted(type): void {
    if (type === false) {
      this.isMuted.next(false);
    }
    else if (type === true) {
      this.isMuted.next(true);
    }
    else
    {
      this.isMuted.next(!this.isMuted.getValue());
    }
  }
}

import {Component, forwardRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MenuContent, MenuContentComponent} from '../../button/menu-content';

@Component({
  selector: 'lib-utm-menu-table',
  templateUrl: './utm-menu-table.component.html',
  styleUrls: ['./utm-menu-table.component.css'],
  providers: [{
    provide: MenuContentComponent,
    useExisting: forwardRef(() => UtmMenuTableComponent)
  }]
})
export class UtmMenuTableComponent implements OnInit, MenuContent {
  @ViewChild('menuContent') menuContent: TemplateRef<any>;

  constructor() {

  }

  ngOnInit(): void {
  }

}

import {Component, EventEmitter, Input, OnDestroy, Optional, Output} from '@angular/core';
import {EMPTY, ReplaySubject, Subscription, throwError, timer} from 'rxjs';
import * as Cesium from 'cesium';
import {CzmlDataSource, Event, Viewer} from 'cesium';
import {CesiumComponent} from '../../common/CesiumComponent';
import {HttpClient} from '@angular/common/http';
import {CesiumService} from '@ax/ax-angular-map-cesium';
import {uuidv4} from '../../common/uuid';
import {catchError, exhaustMap, switchMap, tap} from 'rxjs/operators';
import {LegendItemConfig, LegendService} from '../../common/legend.service';
import {UTMEventStore, UtmService} from '../../utm/utm.service';
import {MovableInfoboxComponent} from '../../movable-infobox/movable-infobox.component';

function deepEqual(object1, object2): any {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !deepEqual(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false;
    }
  }

  return true;
}

function isObject(object): any {
  return object != null && typeof object === 'object';
}

interface EnvelopeBounds {
  north: number;
  south: number;
  east: number;
  west: number;
}

@Component({
  selector: 'lib-czml-rest-loader',
  templateUrl: './rest-loader.component.html',
  styleUrls: ['./rest-loader.component.css']
})
export class RestLoaderComponent extends CesiumComponent implements OnDestroy {
  private ds: CzmlDataSource;

  @Input() url = '';
  @Input() refreshInterval = -1; // 10*60*1000;
  private id: string;
  private legendId: string;
  private legendSub: Subscription;
  @Input() legendConfig: LegendItemConfig;

  @Output() terminated: EventEmitter<void> = new EventEmitter();
  private urlSubject = new ReplaySubject(1);

  // private refreshIntervalSubject = new ReplaySubject(1);
  private httpSubscription: Subscription;
  private previousCZML: any = {};
  private utmEventStore: UTMEventStore;
  private viewer: Viewer;
  private alreadyAlerted = false;
  private viewWidth;
  private qBool = false;
  private instanceActive = false;
  private zoomAlert = document.getElementById('zoom-alert');
  private removeEntityChangeCallback: Event.RemoveCallback;

  ngOnDestroy(): void {
    MovableInfoboxComponent.utmConnectivityIssues = false;
    if (!this.excludedInf()) {
      MovableInfoboxComponent.restAlertInd = MovableInfoboxComponent.restAlertInd - 1;
    }
    this.instanceActive = false;
    if (MovableInfoboxComponent.restAlertInd === 0 && this.zoomAlert) {
      document.getElementById('zoom-alert').style.display = 'none';
    }
    super.ngOnDestroy();
    this.cesiumService.removeDatasource(this.ds);
    this.httpSubscription?.unsubscribe();

    if (this.legendId) {
      this.legendService.removeLegend(this.legendId);
    }

    this.legendSub?.unsubscribe();
    this.utmService?.unregisterEventStore(this.utmEventStore);
  }

  constructor(private http: HttpClient, @Optional() private legendService: LegendService, cesiumService: CesiumService, @Optional() private utmService: UtmService) {
    super(cesiumService);
    this.id = uuidv4();

    this.utmEventStore = new UTMEventStore();
    this.utmService?.registerEventStore(this.utmEventStore);
  }

  onViewerInit(viewer: Viewer): void {
    this.viewer = viewer;
    if (!this.url) {
      return;
    }
    const moveEndRemoveCallback = viewer.scene.camera.moveEnd.addEventListener(() => {
      if (!this.instanceActive) {
        return;
      }
      if (this.excludedInf()) {
        return;
      }
      const newUrl = this.computeUrl(this.url);
      if (this.viewWidth > .1) {
        this.checkZoom(true);
      } else {
        this.checkZoom(false);
      }
      if (!this.qBool) {
        this.qBool = true;
        fetch(newUrl)
          .then(response => response.json())
          .then(data => {
            this.updateCZML(data);
          })
          .finally(() => {
            this.qBool = false;
          });
      } else {
        console.warn('Currently Processing Another REST Request');
      }
      // Old method of parallel processing that was bogging down the backend
      // this.http.get(newUrl).subscribe((czml) => {
      //   if (this.ds) {
      //     this.ds.process(czml);
      //   }
      // });


    });

    console.debug('Initing RestLoader');
    this.completeInit();
  }
  private excludedInf(): boolean {
    if (this.legendConfig.id.includes('sua') || this.legendConfig.id === 'brdrxng' || this.legendConfig.id === 'usbordr') {
      return true;
    } else {
      return false;
    }
  }

  private checkZoom(val): void {
    if (MovableInfoboxComponent.restAlertInd > 0) {
      if (val) {
        console.warn('Infrastructure has been hidden at the current zoom level to improve performance. Zoom in to continue to view infrastructure.');
        if (this.ds) {
          this.ds.show = false;
        }
        if (this.zoomAlert) {
          document.getElementById('zoom-alert').style.display = 'block';
        }
      } else {
        if (this.ds) {
          this.ds.show = true;
        }
        if (this.zoomAlert) {
          document.getElementById('zoom-alert').style.display = 'none';
        }
      }
    }
  }

  private computeUrl(url: string): string {
    let ret: string;
    if (url.includes('${')) {
      const envelope = this.getViewEnvelope();
      ret = url
        .replace('${envelope_center_lat}', ((envelope.north + envelope.south) / 2).toString())
        .replace('${envelope_center_lon}', ((envelope.east + envelope.west) / 2).toString())
        .replace('${envelope_north}', envelope.north.toString())
        .replace('${envelope_south}', envelope.south.toString())
        .replace('${envelope_east}', envelope.east.toString())
        .replace('${envelope_west}', envelope.west.toString());
    } else {
      ret = url;
    }

    if (ret.includes('${')) {
      console.error(`Potentially missing parameters in url:'${ret}'`);
    }

    return ret;

  }

  private completeInit(): void {
    if (!this.excludedInf()) {
      MovableInfoboxComponent.restAlertInd = MovableInfoboxComponent.restAlertInd + 1;
      if (this.viewWidth > .1 && this.zoomAlert) {
        document.getElementById('zoom-alert').style.display = 'block';
      }
    }
    this.instanceActive = true;
    if (this.refreshInterval > 0) {
      this.httpSubscription = timer(0, this.refreshInterval).pipe(exhaustMap(() => {
        return this.http.get(this.computeUrl(this.url)).pipe(tap({
              next: (czml) => {
                /** response was 200, make sure you switch connectivity boolean
                 * We need a more elegant solution to this in the form of a rewrite.
                 */
                if (MovableInfoboxComponent.utmConnectivityIssues) {
                  MovableInfoboxComponent.utmConnectivityIssues = false;
                }
                this.updateCZML(czml);
                return czml;
              },
              error: () => {
                if (!MovableInfoboxComponent.utmConnectivityIssues) {
                  MovableInfoboxComponent.utmConnectivityIssues = true;
                }
                console.error('Error connecting to UTM Service. Attempting to reconnect while UTM is active');
                return EMPTY;
              }
            }),
          /** Added in case we get a non-200 response from the http request. Returns an empty observable to ensure that
           * it completes and the polling operation set by the timer continues to run instead of closing out. -IP
           */
          catchError(err => {
            if (!MovableInfoboxComponent.utmConnectivityIssues) {
              MovableInfoboxComponent.utmConnectivityIssues = true;
            }
            console.error('Error connecting to UTM Service. Attempting to reconnect while UTM is active');
            /** Complete observable */
            return EMPTY;
          })
        );
      })).subscribe();
    } else {
      this.httpSubscription = this.http.get(this.computeUrl(this.url)).pipe(
        tap({
        next: (czml) => {
          /** response was 200, make sure you switch connectivity boolean */
          if (MovableInfoboxComponent) {
            MovableInfoboxComponent.utmConnectivityIssues = false;
          }
          this.updateCZML(czml);
          return czml;
        },
        error: () => {
          if (!MovableInfoboxComponent.utmConnectivityIssues) {
            MovableInfoboxComponent.utmConnectivityIssues = true;
          }
          console.error('Error connecting to UTM Service. Attempting to reconnect while UTM is active');
          return EMPTY;
        }
        })).subscribe();
    }
    if (this.legendService && this.legendConfig) {
      this.legendId = this.legendService.addLegend(this.legendConfig);

      this.legendSub = this.legendService.watchForLegendEvents(this.legendId).subscribe((legendEvent) => {
        if (!legendEvent) {
          return;
        }
        switch (legendEvent.action) {
          case 'add':
            break;
          case 'remove':
            break;
          case 'click':
            this.terminated.emit();
            break;

        }
      });
    }
  }

  private updateCZML(czml: any): void {
    /** removed because of a bug where infrastructure wouldn't load if zoom
     * level was too close on first instantiation. This component needs an entire
     * rewrite to recitify differences between init methods and screen move
     * event handlers.
     * Re-added as the removal was causing UTM infoboxes to reload (RQ)
     */
    /**
     * Cesium appears to have an issue when there is only a single entity in the CZML array. This if statement should
     * become unnecessary once the issue is resolved. (JS)
     */
    if (deepEqual(this.previousCZML, czml)) {
      return;
    }
    this.previousCZML = JSON.parse(JSON.stringify(czml));

    if (!this.ds) {
      this.ds = new CzmlDataSource();
      this.ds.process({
        id: 'document',
        name: 'rest-loader-' + this.id,
        version: '1.0',
      });
      this.cesiumService.addDatasource(this.ds);
    }

    if (this.viewWidth > .1) {
      this.checkZoom(true);
    } else {
      this.checkZoom(false);
    }

    const toKeep = new Set<string>();
    if (this.removeEntityChangeCallback !== undefined) {
      this.removeEntityChangeCallback();
    }
    this.removeEntityChangeCallback = this.ds.entities.collectionChanged.addEventListener((collection, added, removed, changed) => {
      added.forEach((entity) => {
        toKeep.add(entity.id);
      });
      changed.forEach((entity) => {
        toKeep.add(entity.id);
      });
    });

    this.utmEventStore.setFromCZML(czml);
    this.ds.process(czml).then(() => {
      if (this.removeEntityChangeCallback !== undefined) {
        this.removeEntityChangeCallback();
      }
      /**
       * Create a list of entities based on the changes passed by the event listener which we then loop through
       * and remove from the datasource individually.
       */
      const deadEntities: any[] = [];
      this.ds.entities.values.forEach((entity) => {
        if (!toKeep.has(entity.id)) {
          deadEntities.push(entity);
        }
      });
      deadEntities.forEach((dead) => {
        this.ds.entities.remove(dead);
      });
    }).catch(error => console.error('Error clearing processing and clearing czml entities: ' + error));


  }

  private getViewEnvelope(): EnvelopeBounds {
    let rect = this.viewer.camera.computeViewRectangle(this.viewer.scene.globe.ellipsoid);
    if (rect === undefined) {
      const cl2 = new Cesium.Cartesian2(0, 0);
      const leftTop = this.viewer.scene.camera.pickEllipsoid(cl2, this.viewer.scene.globe.ellipsoid);

      const cr2 = new Cesium.Cartesian2(this.viewer.scene.canvas.width, this.viewer.scene.canvas.height);
      const rightDown = this.viewer.scene.camera.pickEllipsoid(cr2, this.viewer.scene.globe.ellipsoid);

      const leftTopCart = this.viewer.scene.globe.ellipsoid.cartesianToCartographic(leftTop);
      const rightDownCart = this.viewer.scene.globe.ellipsoid.cartesianToCartographic(rightDown);
      rect = new Cesium.Rectangle(leftTopCart.longitude, rightDownCart.latitude, rightDownCart.longitude, leftTopCart.latitude);
    }

    this.viewWidth = rect.width;

    if (rect.width > .1) {
      const centerLat = (rect.north + rect.south) / 2;
      const centerLon = (rect.east + rect.west) / 2;
      const scale = 0.15 / rect.width;
      return {
        north: Cesium.Math.toDegrees(centerLat + (rect.height / 2) * scale),
        south: Cesium.Math.toDegrees(centerLat - (rect.height / 2) * scale),
        east: Cesium.Math.toDegrees(centerLon + (rect.width / 2) * scale),
        west: Cesium.Math.toDegrees(centerLon - (rect.width / 2) * scale)
      };
    } else {
      return {
        north: Cesium.Math.toDegrees(rect.north),
        south: Cesium.Math.toDegrees(rect.south),
        east: Cesium.Math.toDegrees(rect.east),
        west: Cesium.Math.toDegrees(rect.west)
      };
    }
  }
}



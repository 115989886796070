import { Injectable } from '@angular/core';
import {AXCesiumWidget, CesiumService} from '@ax/ax-angular-map-cesium';
import {CesiumComponent} from '../common/CesiumComponent';
// import {Entity, Viewer} from 'cesium';
import * as Cesium from 'cesium';

@Injectable({
  providedIn: 'root'
})
export class TrackDescriptionsService {
  static updateDescriptions(obj: any, viewer): any {
    if (viewer.selectedEntity === undefined) {
      obj.description = obj.description_text;
    }
    else {
      if (viewer.selectedEntity.id === obj.id) {
        return obj;
      }
      else {
        obj.description = obj.description_text;
      }
    }
    return obj;
  }

  constructor() {
  }
}

import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {AXCesiumWidget, CesiumService} from '@ax/ax-angular-map-cesium';
import {CesiumComponent} from '../../common/CesiumComponent';
import {uuidv4} from '../../common/uuid';
import {Viewer} from 'cesium';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {SafireButtonContentDirective} from '../safire-button-content.directive';
import {SafireButtonService} from '../safire-button.service';
import {MenuContent} from '../menu-content';
import {ChildContentContainer} from '../ChildContentContainer';


export enum ButtonState {
  OFF = 'off',
  ON = 'on',
  MIXED = 'mixed'
}

export enum ButtonDisplayMode {
  CELL = 'cell',
  CELLTEXT = 'celltext'
}

export interface AbstractButtonToggle {
  // childContent: TemplateRef<any>;

  getTitle(): string;

  activate(): void;

  setState(state: ButtonState, propogate: boolean): void;

  watchState(): Observable<ButtonState>;

  watchActivation(): Observable<boolean>;
}


@Component({
  selector: 'lib-safire-button',
  templateUrl: './safire-button.component.html',
  styleUrls: ['./safire-button.component.css'],
  providers: [
    {provide: AXCesiumWidget, useExisting: forwardRef(() => SafireButtonComponent)}/*,
    {
    provide: MenuContentComponent,
    useExisting: forwardRef(() => SafireButtonComponent)
  }*/
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SafireButtonComponent extends CesiumComponent implements AbstractButtonToggle,
                                                                      OnInit,
                                                                      OnChanges,
                                                                      OnDestroy,
                                                                      MenuContent,
                                                                      ChildContentContainer {
  @Input() embedded = false;
  @Input() title = '';
  @Input() btnIcon = '';
  @Input() state: ButtonState = ButtonState.OFF;
  @Input() baseState: ButtonState = ButtonState.OFF;
  @Input() notifyIfOpen = false;
  @Input() showText = true;
  @Output() stateChange: EventEmitter<ButtonState> = new EventEmitter<ButtonState>();
  @ContentChild(SafireButtonContentDirective, {read: TemplateRef, static: true}) rawChildContentTemplate: TemplateRef<any>;
  @ViewChild('buttonTemplate') buttonTemplate: TemplateRef<any>;

  @ViewChild('childOutputTemplate') childContentTemplate: TemplateRef<any>;

  @Input() btnAlt = '';
  ON: ButtonState = ButtonState.ON;
  MIXED: ButtonState = ButtonState.MIXED;
  OFF: ButtonState = ButtonState.OFF;
  id: string;
  allBtn: string;

  @Input() displayMode: ButtonDisplayMode;
  CELL: ButtonDisplayMode = ButtonDisplayMode.CELL;
  CELLTEXT: ButtonDisplayMode = ButtonDisplayMode.CELLTEXT;
  private stateSubject: BehaviorSubject<ButtonState>;
  private stateSub: Subscription;

  constructor(cesiumService: CesiumService, @Optional() private safireButtonService: SafireButtonService, private cdrf: ChangeDetectorRef) {
    super(cesiumService);
    this.id = uuidv4();
    this.stateSubject = new BehaviorSubject<ButtonState>(this.state);

  }

  get menuContent(): TemplateRef<any> {
    return this.buttonTemplate;
  }

  get generalWidget(): TemplateRef<any> {
    return this.childContentTemplate;
  }

  get buttonWidget(): TemplateRef<any> {
    return this.buttonTemplate;
  }

  ngOnInit(): void {
    this.isAll();
    if (this.safireButtonService && this.notifyIfOpen) {
      this.safireButtonService.listenForOpenNotifications().subscribe((id) => {
        if (this.id !== id && this.state !== this.baseState) {
          this.setState(this.baseState);
        }
      });
    }
    this.stateSub = this.stateSubject.subscribe((state) => {
      this.stateChange.emit(state);
    });
  }

  onViewerInit(viewer: Viewer): void {
  }

  setState(state: ButtonState, propogate: boolean = true): void {
    console.debug('Setting Button State');
    this.state = state;
    if (propogate) {
      this.stateSubject.next(state);
    }
    this.cdrf.markForCheck();
  }

  isAll(): void {
    if (this.title === 'All') {
      this.allBtn = 'is-all-btn';
    } else {
      this.allBtn = '';
    }
  }

  toggleState(): void {


      if (this.state === ButtonState.OFF ||
          this.state === ButtonState.MIXED)
      {
        this.state = ButtonState.ON;
        if (this.safireButtonService && this.notifyIfOpen) {
          this.safireButtonService.notifyOfButtonOpen(this.id);
        }
      }
      else {
        this.state = ButtonState.OFF; // this.baseState;
    }
      this.stateSubject.next(this.state);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.state) {
      if (changes.state) {
        this.setState(this.state);
      }
      // this.toggle.emit(this.state);
    }
    if (changes.baseState) {
      if (this.state === changes.baseState.previousValue) {
        this.setState(this.baseState);
      }
    }
  }

  activate(): void {
  }

  getTitle(): string {
    return this.title;
  }

  watchActivation(): Observable<boolean> {
    return null;
  }

  watchState(): Observable<ButtonState> {
    return this.stateSubject.asObservable();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.stateSub?.unsubscribe();
  }
}

import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AxAngularMapCesiumModule} from '@ax/ax-angular-map-cesium';
import {AbstractMapConfigProvider} from '@ax/ax-angular-map-common';
import {SafireComponentsModule} from '../../../safire-components/src/lib/safire-components.module';
import {SandboxMapConfigProvider} from './SandboxMapConfigProvider';
import {ClarityModule} from '@clr/angular';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AttributionIconComponent } from './lib/attribution-icon/attribution-icon.component';
import { ControlWrapperComponent } from './lib/control-wrapper/control-wrapper.component';
import { CesiumExtraInitComponent } from './lib/cesium-extra-init/cesium-extra-init.component';

@NgModule({
  declarations: [
    AppComponent,
    AttributionIconComponent,
    ControlWrapperComponent,
    CesiumExtraInitComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AxAngularMapCesiumModule,
    SafireComponentsModule,
    ClarityModule
  ],
  providers: [
    {provide: AbstractMapConfigProvider, useClass: SandboxMapConfigProvider},

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

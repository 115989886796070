import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {uuidv4} from '../../common/uuid';
import {AXCesiumWidget, CesiumService} from '@ax/ax-angular-map-cesium';
import {CesiumComponent} from '../../common/CesiumComponent';
import * as Cesium from 'cesium';
import {Color, HeightReference, PinBuilder, Viewer} from 'cesium';
import {Subscription} from 'rxjs/internal/Subscription';

@Component({
  selector: 'lib-distance-finder',
  templateUrl: './distance-finder.component.html',
  styleUrls: ['./distance-finder.component.css', '../../common/css/button.css'],
  providers: [{provide: AXCesiumWidget, useExisting: forwardRef(() => DistanceFinderComponent)}]
})
export class DistanceFinderComponent extends CesiumComponent implements OnInit, OnDestroy {

  private id: string;
  private radarGroupServiceSub: Subscription;
  private pinBuilder = new PinBuilder();
  @Input() distanceFinderActive = false;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  private clickCount = false;
  private viewer: Viewer;
  private posStatic: any;
  private posFollowPost: any;
  private measureLine: any;
  private staticLine: any;
  private pinOne: any;
  private pinTwo: any;
  private pinFollow: any;
  private postOne: any;
  private postTwo: any;
  private earthsRadius = 6371e3;
  private measureLon: number;
  private measureLat: number;
  private openSub: Subscription;
  @ViewChild('buttonTemplate') buttonTemplate: TemplateRef<any>;

  // Get the button template for addition to the menu
  private ds: Cesium.CustomDataSource;

  get buttonWidget(): TemplateRef<any> {
    return this.buttonTemplate;
  }


  // Do this on component init
  onViewerInit(viewer: Viewer): void {

    // Initialize the viewer
    this.viewer = viewer;
    this.ds = new Cesium.CustomDataSource();
    viewer.dataSources.add(this.ds);
    // Initialize starting positions of polylines because cesium requires the variables to be initialized as cartesians
    let pos1: any = Cesium.Cartesian3.fromDegrees(-75.4093483, 43.2291085, 0.0);
    let pos2: any = Cesium.Cartesian3.fromDegrees(-75.0, -43.0, 0.0);

    // Setting up all of the entities
    // Dynamic polyline which follows mouse
    this.measureLine = this.ds.entities.add({
      id: 'measureLine',
      polyline: {
        show: false,
        positions: new Cesium.CallbackProperty(() => [pos1, pos2], false),
        width: 3,
        material: Cesium.Color.WHITE,
        clampToGround: true
      }
    });

    // Line measuring distance that appears after second click
    this.staticLine = this.ds.entities.add({
      id: 'staticLine',
      name: 'Distance Measured',
      polyline: {
        show: false,
        positions: this.measureLine.polyline.positions,
        width: 3,
        material: Cesium.Color.WHITE,
        clampToGround: true
      }
    });

    // Flag icon that follows mouse
    this.pinFollow = this.ds.entities.add({
      id: 'pinFollow',
      show: false,
      position: this.posStatic,
      point: {
        color: Color.RED,
        pixelSize: 8,
        heightReference: HeightReference.CLAMP_TO_GROUND,
      }
    });

    // Flag for first-clicked coordinates
    this.pinOne = this.ds.entities.add({
      id: 'pinOne',
      show: false,
      position: this.posStatic,
      billboard: {
        image: '/assets/map_icons/flag_1_icon.png',
        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        pixelOffset: new Cesium.Cartesian2(-2, 0),
      }
    });

    // Flag for second-click coordinates
    this.pinTwo = this.ds.entities.add({
      id: 'pinTwo',
      show: false,
      position: this.posStatic,
      billboard: {
        image: '/assets/map_icons/flag_2_icon.png',
        horizontalOrigin: Cesium.HorizontalOrigin.LEFT,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        pixelOffset: new Cesium.Cartesian2(-2, 0),
      }
    });

    // Ground stick for first-clicked coordinates
    this.postOne = this.ds.entities.add({
      id: 'postOne',
      polyline: {
        show: false,
        material: Cesium.Color.WHITE,
        width: 2
      }
    });

    // Ground stick for Second-clicked coordinates
    this.postTwo = this.ds.entities.add({
      id: 'postTwo',
      polyline: {
        show: false,
        material: Cesium.Color.WHITE,
        width: 2
      }
    });

    // Setting up the mouse move event handler
    const moveCursor = new Cesium.ScreenSpaceEventHandler(viewer.canvas); // requires canvas as a parameter
    moveCursor.setInputAction((event) => {
        if (this.distanceFinderActive) {
          const scene = this.viewer.scene;
          // Get mouse position using cesium's pickposition
          const pickedObject = scene.pickPosition(event.endPosition);
          // Get it as a cartesian it to a cartesian
          const cartesian = scene.pickPosition(event.endPosition);
          // Convert it to a cartographic so we can get degrees lat/lon
          if (!cartesian) {
            return;
          }
          const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
          const disLon = Cesium.Math.toDegrees(cartographic.longitude);
          const disLat = Cesium.Math.toDegrees(cartographic.latitude);
          this.posStatic = Cesium.Cartesian3.fromDegrees(disLon, disLat, 0);
          this.pinFollow.position = this.posStatic;
          // Display appropriate entities
          this.measureLine.polyline.show = true;
          this.pinFollow.show = true;

          if (!this.clickCount) {
            // Don't show the measureline if the screen hasn't been clicked yet
            this.measureLine.polyline.show = false;
          } else {
            // Otherwise display the measureline and dynamically update pos2 so it folows the mouse
            pos2 = cartesian;
            this.measureLine.polyline.show = true;
          }
        }
      },
      Cesium.ScreenSpaceEventType.MOUSE_MOVE);

    // Set up esc key event handler
    document.addEventListener(
      'keyup',
      (e): void => {
        if (e.key === 'Escape') {
          this.toggleDistanceFinder();
          this.ngOnDestroy();
        }
      },
      false
    );

    // Set up the mouse click event handler
    const clickCursor = new Cesium.ScreenSpaceEventHandler(viewer.canvas); // requires canvas as a parameter
    clickCursor.setInputAction((event) => {
        if (this.distanceFinderActive) {
          const scene = this.viewer.scene;
          // Get mouse position using cesium's pickposition
          const pickedObject = scene.pick(event.position);
          // Get it as a cartesian it to a cartesian
          const cartesian = scene.pickPosition(event.position);
          // Convert it to a cartographic so we can get degrees lat/lon
          const cartographic = Cesium.Cartographic.fromCartesian(cartesian);
          const disLon = Cesium.Math.toDegrees(cartographic.longitude);
          const disLat = Cesium.Math.toDegrees(cartographic.latitude);
          const disHeight = Cesium.Math.toDegrees(cartographic.height);
          // Create a new height for the ground sticks based on the coordinates' HAE
          const newHeight = disHeight * .05;
          // Set the cartesian coordinates for the first-clicked ground stick
          const postCoordsOne = Cesium.Cartesian3.fromDegrees(disLon, disLat, 0.0);
          const postCoordsTwo = Cesium.Cartesian3.fromDegrees(disLon, disLat, newHeight);

          if (!this.clickCount) {
            // Make click count true/1
            this.clickCount = !this.clickCount;
            // If the screen hasn't been clicked yet then set up some variables for measurements/staticLine
            this.measureLon = disLon;
            this.measureLat = disLat;
            // Set position 1 of the measured distance
            pos1 = cartesian;
            // Set a flag in the location of the first click and show it
            this.pinOne.position = Cesium.Cartesian3.fromDegrees(disLon, disLat, newHeight);
            this.postOne.polyline.show = true;
            // Set a ground stick in the location of the first click and show it
            this.postOne.polyline.positions = new Cesium.ConstantProperty([postCoordsOne, postCoordsTwo]);
            // Appropriately hide/show entities based on number of clicks
            this.postTwo.polyline.show = false;
            this.pinOne.show = true;
            this.pinTwo.show = false;
            this.staticLine.polyline.show = false;
          } else {
            this.clickCount = !this.clickCount;
            // Set position 2 of the measured distance
            pos2 = cartesian;
            // Set a flag in the location of the second click and show it
            this.pinTwo.position = Cesium.Cartesian3.fromDegrees(disLon, disLat, newHeight);
            this.postTwo.polyline.show = true;
            // Set a flag in the location of the second click and show it
            this.postTwo.polyline.positions = new Cesium.ConstantProperty([postCoordsOne, postCoordsTwo]);
            this.pinTwo.show = true;
            // Display a static measurement line in place of the trailing polyline
            this.staticLine.polyline.show = true;
            this.staticLine.polyline.positions = new Cesium.ConstantProperty([pos1, pos2]);

            // Meat and potatoes of the distance measurement
            const calc1 = this.measureLat * Math.PI / 180;
            const calc2 = disLat * Math.PI / 180;
            const calc3 = (disLat - this.measureLat) * Math.PI / 180;
            const calc4 = (disLon - this.measureLon) * Math.PI / 180;
            const calc5 = Math.sin(calc3 / 2) * Math.sin(calc3 / 2) +
              Math.cos(calc1) * Math.cos(calc2) *
              Math.sin(calc4 / 2) * Math.sin(calc4 / 2);
            const calc6 = 2 * Math.atan2(Math.sqrt(calc5), Math.sqrt(1 - calc5));
            const finalCalc = (this.earthsRadius * calc6) / 1000;

            // Give staticLine a description formatted with distance measured in various units and start/end coordinates
            this.staticLine.description =
              '<table style="border: 1px solid white; border-collapse: collapse; width: 100%;">' +
              '<tbody>' +
              '<tr>' +
              '<td style="border: 1px solid white; border-collapse: collapse;">' +
              'Starting Coordinates:' +
              '</td>' +
              '<td style="border: 1px solid white; border-collapse: collapse;">' +
              String(this.measureLon.toFixed(6)) +
              ', ' +
              String(this.measureLat.toFixed(6)) +
              '</td>' +
              '</tr>' +
              '<tr>' +
              '<td style="border: 1px solid white; border-collapse: collapse;">' +
              'Ending Coordinates:' +
              '</td>' +
              '<td style="border: 1px solid white; border-collapse: collapse;">' +
              String(disLon.toFixed(6)) +
              ', ' +
              String(disLat.toFixed(6)) +
              '</td>' +
              '</tr>' +
              '<tr>' +
              '<td style="border: 1px solid white; border-collapse: collapse;">' +
              'Distance km:' +
              '</td>' +
              '<td style="border: 1px solid white; border-collapse: collapse;">' +
              String(finalCalc.toFixed(6)) +
              '</td>' +
              '</tr>' +
              '<tr>' +
              '<td style="border: 1px solid white; border-collapse: collapse;">' +
              'Distance m:' +
              '</td>' +
              '<td style="border: 1px solid white; border-collapse: collapse;">' +
              String((finalCalc * 1000).toFixed(6)) +
              '</td>' +
              '</tr>' +
              '<tr>' +
              '<td style="border: 1px solid white; border-collapse: collapse;">' +
              'Distance mi:' +
              '</td>' +
              '<td style="border: 1px solid white; border-collapse: collapse;">' +
              String((finalCalc * 0.621371).toFixed(6)) +
              '</td>' +
              '</tr>' +
              '<tr>' +
              '<td style="border: 1px solid white; border-collapse: collapse;">' +
              'Distance ft:' +
              '</td>' +
              '<td style="border: 1px solid white; border-collapse: collapse;">' +
              String((finalCalc * 3280.84).toFixed(6)) +
              '</td>' +
              '</tr>' +
              '</tbody>' +
              '</table>';

            // Make staticLine the selected entity so it displays the infobox
            viewer.selectedEntity = this.staticLine;


          }
        }


      },
      Cesium.ScreenSpaceEventType.LEFT_CLICK);

    // If the selected entity changes, use this list of undesirables to determine if we should show an infobox
    // viewer.selectedEntityChanged.addEventListener((entity) => {
    //   if (!entity) {
    //     return;
    //   }

      /** This was having trouble working so we moved it to movable-infobox.component.ts
       */
      //   if (entity.id === 'measureLine' ||
    //     entity.id === 'pinFollow' ||
    //     entity.id === 'pinOne' ||
    //     entity.id === 'pinTwo' ||
    //     entity.id === 'postOne' ||
    //     entity.id === 'postTwo') {
    //     viewer.selectedEntity = undefined;
    //   }
    // });


    // Set up esc key event handler
    document.addEventListener(
      'keyup',
      (e): void => {
        if (e.key === 'Escape') {
          this.close();
        }
      },
      false
    );

  }

  constructor(cesiumService: CesiumService) {// , private radarGroupService: ButtonGroupService) {
    // Give a heads-up that the component has been initialized
    super(cesiumService);
    this.id = uuidv4();
    console.debug(`Initing distance-finder: id: ${this.id}`);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.close();
    this.viewer.dataSources.remove(this.ds);
    this.openSub?.unsubscribe();
  }

  private close(): void {
    // When the button is closed make sure distance finder is false, no entities are displayed on the map, and nuke the currently selected entity
    this.distanceFinderActive = false;
    this.measureLine.polyline.show = false;
    this.staticLine.polyline.show = false;
    this.postOne.polyline.show = false;
    this.postTwo.polyline.show = false;
    this.pinFollow.show = false;
    this.pinOne.show = false;
    this.pinTwo.show = false;
    this.viewer.selectedEntity = undefined;
    this.onClose.emit(this.distanceFinderActive);
  }

  toggleDistanceFinder(): void {
    // This just toggles the distance finder from true/false and activates the close function
    if (this.distanceFinderActive) {
      this.close();
    } else {
      this.distanceFinderActive = true;
    }
  }


}

<ng-template #generalWidgetTemplate>
  <!--<ng-container>-->
  <div id="track-config" style="display: none;">
    <div id="track-config-header">Track & Label Config<btn (click)="hideConfig($event)" id="close-btn">X</btn></div>
    <div class="track-config-menu">
      <div class="cfg-subheader" style="font-weight: normal;">
        Toggle Headings/Tails
      </div>
      <div class="config-div">
        <div style="display:inline-block;width:50%">
          <button (click)="toggleHeadsTails($event,'heads')" class="track-config-btn-selected" id="heads-btn" style="width: 90%;">HEADINGS</button>
        </div>
        <div style="display:inline-block;width:50%">
          <button (click)="toggleHeadsTails($event,'tails')" class="track-config-btn-selected" id="tails-btn" style="width: 90%;">TAILS</button>
        </div>
      </div>
      <!--  This is the beginning of the altitude units/method section of the track config  -->
      <!--    Change altitude label-->
      <div class="cfg-header">LABELS</div>
      <!--    Checkbox intended to change the altitude method (hae/msl).-->


      <div style="border: 1px solid grey; border-radius: 5px; margin-bottom: 10px;">
        <div class="cfg-subheader" style="padding: 10px 0 5px 0;">
          IDENTIFIERS
        </div>
        <div class="cfg-subheader" style="margin-bottom:0; font-size: 16px;">
          Show ID:
        </div>
        <form (change)="changeLabels($event)">
          <label class="radio-inline">
            <input [checked]="labelFormat['showId']" name="showId" type="radio">ON
          </label>
          <label class="radio-inline">
            <input [checked]="!labelFormat['showId']" name="showId" type="radio">OFF
          </label>
        </form>
      </div>
      <div style="border: 1px solid grey; border-radius: 5px; margin-bottom: 10px;">
        <div class="cfg-subheader" style="padding: 10px 0 5px 0;">
          ALTITUDE
        </div>
        <div class="cfg-subheader" style="margin-bottom:0; font-size: 16px;">
          Show Altitude:
        </div>
        <form (change)="changeLabels($event)">
          <label class="radio-inline">
            <input [checked]="labelFormat['showAlt']" name="showAlt" type="radio">ON
          </label>
          <label class="radio-inline">
            <input [checked]="!labelFormat['showAlt']" name="showAlt" type="radio">OFF
          </label>
        </form>
        <div class="cfg-subheader" style="margin-bottom:0; font-size: 16px;">
          Altitude Units:
        </div>
        <form (change)="changeLabels($event)">
          <label class="radio-inline">
            <input [checked]="labelFormat['altUnits'] === 'm'" name="altUnits" type="radio" value="m">m
          </label>
          <label class="radio-inline">
            <input [checked]="labelFormat['altUnits'] === 'km'" name="altUnits" type="radio" value="km">km
          </label>
          <label class="radio-inline">
            <input [checked]="labelFormat['altUnits'] === 'ft'" name="altUnits" type="radio" value="ft">ft
          </label>
          <label class="radio-inline">
            <input [checked]="labelFormat['altUnits'] === 'mi'" name="altUnits" type="radio" value="mi">mi
          </label>
        </form>
        <div class="cfg-subheader" style="margin-bottom:0; font-size: 16px;">
          Altitude Method:
        </div>
        <form (change)="changeLabels($event)">
          <label class="radio-inline">
            <input [checked]="labelFormat['altMethod'] === 'Hae'" name="altMethod" type="radio" value="Hae">HAE
          </label>
          <label class="radio-inline">
            <input [checked]="labelFormat['altMethod'] === 'Msl'" name="altMethod" type="radio" value="Msl">MSL
          </label>
        </form>
      </div>
      <div style="border: 1px solid grey; border-radius: 5px; margin-bottom: 10px;">
        <div class="cfg-subheader" style="padding: 10px 0 5px 0;">
          SPEED
        </div>
        <div class="cfg-subheader" style="margin-bottom:0; font-size: 16px;">
          Show Speed:
        </div>
        <form (change)="changeLabels($event)">
          <label class="radio-inline">
            <input [checked]="labelFormat['showSpeed']" name="showSpeed" type="radio">ON
          </label>
          <label class="radio-inline">
            <input [checked]="!labelFormat['showSpeed']" name="showSpeed" type="radio">OFF
          </label>
        </form>
        <div class="cfg-subheader" style="margin-bottom:0; font-size: 16px;">
          Speed Units:
        </div>
        <form (change)="changeLabels($event)">
          <label class="radio-inline">
            <input [checked]="labelFormat['speedUnits'] === 'm'" name="speedUnits" type="radio" value="m">m
          </label>
          <label class="radio-inline">
            <input [checked]="labelFormat['speedUnits'] === 'km'" name="speedUnits" type="radio" value="km">km
          </label>
          <label class="radio-inline">
            <input [checked]="labelFormat['speedUnits'] === 'ft'" name="speedUnits" type="radio" value="ft">ft
          </label>
          <label class="radio-inline">
            <input [checked]="labelFormat['speedUnits'] === 'mi'" name="speedUnits" type="radio" value="mi">mi
          </label>
          <label class="radio-inline">
            <input [checked]="labelFormat['speedUnits'] === 'knots'" name="speedUnits" type="radio" value="knots">Knots
          </label>
        </form>
        <div class="cfg-subheader" style="margin-bottom:0; font-size: 16px;">
          Speed Time Method:
        </div>
        <fieldset [disabled]="labelFormat['speedUnits'] === 'knots'">
          <form (change)="changeLabels($event)">
            <label class="radio-inline">
              <input [checked]="labelFormat['speedTime'] === 's'" name="speedTime" type="radio" value="s">/s
            </label>
            <label class="radio-inline">
              <input [checked]="labelFormat['speedTime'] === 'min'" name="speedTime" type="radio" value="min">/min
            </label>
            <label class="radio-inline">
              <input [checked]="labelFormat['speedTime'] === 'hr'" name="speedTime" type="radio" value="hr">/hr
            </label>
          </form>
        </fieldset>
      </div>
    </div>
  </div>
</ng-template>

// In order to update acknowledgements simply change the entries in the ackConfig variable below. The component will loop
// through the "Test" keys and print the values as list items within the Acknowledgements panel.

// Important to note: in order for smooth css transitions, strict height/width values were set in px
// When updating the config, you must also update the height value of the ack-expanded class to match new content

import {Component, forwardRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { formatDate } from '@angular/common';
import { isDevMode } from '@angular/core';
import {CesiumComponent} from '../common/CesiumComponent';
import {AXCesiumWidget, CesiumService} from '@ax/ax-angular-map-cesium';
import {Viewer} from 'cesium';
import { environment } from '../../../../safire-sandbox/src/environments/environment.prod';

@Component({
  selector: 'lib-acknowledgements',
  templateUrl: './acknowledgements.component.html',
  styleUrls: ['./acknowledgements.component.css'],
  providers: [{provide: AXCesiumWidget, useExisting: forwardRef(() => AcknowledgementsComponent )}]
})
export class AcknowledgementsComponent extends CesiumComponent implements OnInit, OnDestroy {
  sysDate = formatDate(new Date(), 'yyyy', 'en');
  contractBool = true;
  gitCommitHash: string = 'devMode';

  @ViewChild('generalWidgetTemplate') generalWidgetTemplate: TemplateRef<any>;

  constructor(protected cesiumService: CesiumService) {
    super(cesiumService);
  }

  toggleContracted(): void {
    this.contractBool = !this.contractBool;
  }

  get generalWidget(): TemplateRef<any> {
    return this.generalWidgetTemplate;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onViewerInit(viewer: Viewer): void {
    /** Checks to see if we are currently in development mode. If so, we just default the displayed Git commit
     * hash to "devMode." If we are in production mode it pulls the actual Git commit hash from the environment variable
     * that has been set in build.gradle, the Dockerfile, and safire-sandbox/src/environments/environment.prod.
     */
  !isDevMode() ? this.gitCommitHash = environment.gitCommitHash : this.gitCommitHash = 'devMode';
  }

}


import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LegendCategories} from "../live-legend/live-legend.component";
import {delay} from "rxjs/operators";
import {LegendItemConfig, LegendService} from "../../common/legend.service";
import {Subscription} from "rxjs/internal/Subscription";

@Component({
  selector: 'lib-base-legend',
  templateUrl: './base-legend.component.html',
  styleUrls: ['./base-legend.component.css']
})
export class BaseLegendComponent implements OnInit, OnDestroy {
  private minimized1 = false;
  @Input() legendCategories: LegendCategories;
  @Input() categoryId: string;
  @Input() name: string;
  legendItemConfigs: LegendItemConfig[] = [];

  private legendSub: Subscription;
  legendDisplay: 'block' | 'none' = 'block';
  hasItems = false;


  constructor(private legendService: LegendService, private cdRef: ChangeDetectorRef) {
    this.legendSub = this.legendService.watchForLegendConfigs().pipe(delay(0)).subscribe((globalLegendConfig) => {
      const legendConfig = globalLegendConfig[this.categoryId];
      if (!legendConfig) {
        this.legendItemConfigs = [];
        return;
      }
      this.legendItemConfigs = Object.values(legendConfig.legendItems);
      this.legendItemConfigs.sort((a, b) => {
          /**
           * Snyk says that this should return 0 instead of -1, but returning 0 breaks legend sorting.
           * calling this a fals positive.
           */
            return (a.name > b.name) ? 1 : -1;
      });
      this.hasItems = this.legendItemConfigs?.length > 0;
      this.cdRef.detectChanges();
    });

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.legendSub?.unsubscribe();
  }

  handleClick(id: string): void {
    this.legendService.notifyOfButtonClick(id);
  }

  minimizeLegend(): void {
    if (this.minimized1 === false) {
      this.legendDisplay = 'none';
    } else {
      this.legendDisplay = 'block';
    }
    this.minimized1 = !this.minimized1;
  }
}

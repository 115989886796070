<ng-template #buttonTemplate>

  <div class="cell">
    <lib-safire-button [title]="title" [btnIcon]="btnIcon" [embedded]="true"
                       [state]="menuState"
                       [showText]="hasParent"
                       [baseState]="menuBaseState"
                       [notifyIfOpen]="!hasParent"
                       (stateChange)="toggleMenu($event)"></lib-safire-button>

  </div>
</ng-template>

<ng-template #childContentTemplate>
  <ng-container *ngFor="let btn of buttons">
    <ng-container [ngTemplateOutlet]="btn.childContentTemplate"></ng-container>
  </ng-container>
</ng-template>

<ng-template #childMenuTemplate>
  <ng-container *ngFor="let menu of menus">
    <ng-container *ngIf="menu.menuTemplate">
      <ng-container [ngTemplateOutlet]="menu.menuTemplate"></ng-container>
    </ng-container>
  </ng-container>


  <div [ngClass]="{'dropdown-menu-visible': true, 'hidden': !menuVisible}" *ngIf=" hasParent">

    <div class="title">{{title}}<img *ngIf="settingsId" src="assets/icons/settings_button.png"
                                     (click)="notifySettings()" alt="settings"/></div>
    <lib-safire-button *ngIf="showAll" [title]="'All'" [state]="allState" [embedded]="true"
                       (stateChange)="handleAllToggle($event)"></lib-safire-button>
    <ng-container *ngFor="let button of buttons">
      <ng-container [ngTemplateOutlet]="button.buttonTemplate"></ng-container>
    </ng-container>

  </div>

</ng-template>


<ng-template #widgetTemplate>
  <div [ngClass]="{'dropdown-menu-root-container':true, 'hidden': !menuVisible}" *ngIf="!hasParent">

    <ng-container *ngFor="let menu of menus">
      <ng-container *ngIf="menu.menuTemplate">
        <ng-container [ngTemplateOutlet]="menu.menuTemplate"></ng-container>
      </ng-container>
    </ng-container>

    <div [ngClass]="{'dropdown-menu-root': true, 'hidden': !menuVisible}">
      <div class="title">{{title}}<img *ngIf="settingsId" src="assets/icons/settings_button.png"
                                       (click)="notifySettings()" alt="settings"/></div>
      <lib-safire-button *ngIf="showAll" [title]="'All'" [state]="allState" [embedded]="true"
                         (stateChange)="handleAllToggle($event)"></lib-safire-button>
      <ng-container *ngFor="let button of buttons">
        <ng-container [ngTemplateOutlet]="button.buttonTemplate"></ng-container>
      </ng-container>
      <!--<ng-container *ngFor="let contents of menuContents">
        <ng-container [ngTemplateOutlet]="contents.menuContent"></ng-container>
      </ng-container>-->

    </div>
    <!--    <ng-container *ngFor="let button of buttons">
            <ng-container [ngTemplateOutlet]="button.buttonTemplate"></ng-container>
          </ng-container>-->

  </div>
  <div class="menu-overlay" (click)="toggleMenu(OFF)" *ngIf="!hasParent && menuVisible">

  </div>
  <div *ngIf="!hasParent">

    <ng-container [ngTemplateOutlet]="childContentTemplate"></ng-container>

  </div>


</ng-template>

import {Injectable} from '@angular/core';
import {Observable, of, ReplaySubject} from 'rxjs';
import {
  AbstractMapConfigProvider,
  CesiumIonLayerConfig,
  CesiumIonTileSubLayerConfig,
  LayerConfig,
  MapConfig,
  PointImpl,
  XYZLayerConfig
} from '@ax/ax-angular-map-common';


const defaultMapConfig = (() => {
  const ret: LayerConfig[] = [
    new XYZLayerConfig(
      'USGSImageryTopo',
      'https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryTopo/MapServer/tile/{z}/{y}/{x}',
      ''
    ),
    new CesiumIonLayerConfig(
      'Cesium Ion',
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2NGFhNDE2Ny1lOGVlLTQxYjQtOGFmNy0wYjI4MDU1YmNiYTciLCJpZCI6NDM4MDksImlhdCI6MTYzOTQxMjYxNn0.GOO7uA3aevel4Ir3__DAdPxW-xb5zCdawX6kqtOsQuc',
      [
        new CesiumIonTileSubLayerConfig('1', 'Cesium World Terrain', 'terrain'),
        new CesiumIonTileSubLayerConfig('2', 'Bing Maps Aerial', 'imagery'),
        new CesiumIonTileSubLayerConfig('3', 'Bing Maps Aerial with Labels', 'imagery'),
        new CesiumIonTileSubLayerConfig('4', 'Bing Maps Road', 'imagery'),
        new CesiumIonTileSubLayerConfig('3845', 'Blue Marble Next Generation July, 2004', 'imagery')
      ]
    )
  ];

  const defaultBounds = {
    nw: new PointImpl(43.26508131632468, -75.50405502319336),
    ne: new PointImpl(43.26508131632468, -75.32793045043944),
    se: new PointImpl(43.17989530238948, -75.32793045043944),
    sw: new PointImpl(43.17989530238948, -75.50405502319336)
  };
  return new MapConfig(ret,
    'Cesium Ion - 2',
    'Cesium Ion - 3845',
    new PointImpl(43.2219462, -75.4200271),
    {
      type: 'Polygon', coordinates: [[
        defaultBounds.nw.coordinates,
        defaultBounds.ne.coordinates,
        defaultBounds.se.coordinates,
        defaultBounds.sw.coordinates
      ]
      ]
    });
})();

export const mapConfigSubject = new ReplaySubject<MapConfig>(1);
// mapConfigSubject.next(defaultMapConfig);

@Injectable({
  providedIn: 'root'
})
export class SandboxMapConfigProvider extends AbstractMapConfigProvider {

  getTileLayerConfigurations(): Observable<MapConfig> {
    return mapConfigSubject;
  }

}

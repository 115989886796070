import {Injectable} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NoticeService {
  private notificationServiceSubject: ReplaySubject<string>;

  constructor() {
    this.notificationServiceSubject = new ReplaySubject<string>(1);
  }

  notify(id: string): void {
    this.notificationServiceSubject.next(id);
  }

  listenForEvent(id: string): Observable<string> {
    return this.notificationServiceSubject.pipe(filter((tmp) => id === tmp));
  }


}

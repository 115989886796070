import {Component, forwardRef, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AXCesiumWidget} from '@ax/ax-angular-map-cesium';

@Component({
  selector: 'lib-attribution-icon',
  templateUrl: './attribution-icon.component.html',
  styleUrls: ['./attribution-icon.component.css'],
  providers: [{provide: AXCesiumWidget, useExisting: forwardRef(() => AttributionIconComponent)}]
})
export class AttributionIconComponent extends AXCesiumWidget implements OnInit {
  @Input() linkUrl: string;
  @Input() iconAlt = '';
  @Input() iconUrl = '';
  @Input() title = '';
  @ViewChild('content') content: TemplateRef<any>;

  get attributionWidget(): TemplateRef<any> {
    return this.content;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}

import {ControlConfig, DashboardConfig, getExchangeButtonConfig, getRestButtonConfig} from './dashboard-config';

const controls: ControlConfig[] = [
    {
      id: 'live_tracks',
      type: 'button-group',
      config: {
        title: 'Data Feeds',
        settingsId: 'ht-config',
        iconUrl: '/assets/icons/radar_button.png',
        buttons: [
          [
            'btn.czml.stomp',
            'src',
            'LSTAR',
            null,
            '/exchange/czml_test_topic/track_src',
            'circle',
            '#46a0fa',
            'dataFeed'
          ],
          [
            'btn.czml.stomp',
            'saab',
            'Saab MLAT',
            null,
            '/exchange/czml_test_topic/track_saab',
            '4star',
            '#ffff00',
            'dataFeed'
          ],
          [
            'btn.czml.stomp',
            'saab_smr',
            'Saab SMR',
            null,
            '/exchange/czml_test_topic/track_saab_smr',
            '4star',
            '#ff8000',
            'dataFeed'
          ],
          [
            'btn.czml.stomp',
            'pingstation',
            'PingStation',
            null,
            '/exchange/czml_test_topic/track_pingstation',
            '4star',
            '#ccff33',
            'dataFeed'
          ],
          [
            'btn.czml.stomp',
            'mplan',
            'GCS - Mission Planner',
            null,
            '/exchange/czml_test_topic/track_mplan',
            '6star',
            '#ffffff',
            'dataFeed'
          ],
          [
            'btn.czml.stomp',
            'piccolo',
            'GCS - Piccolo',
            null,
            '/exchange/czml_test_topic/track_piccolo',
            '6star',
            '#47a0fa',
            'dataFeed'
          ],
          [
            'btn.czml.stomp',
            'ugcs',
            'GCS - UgCS',
            null,
            '/exchange/czml_test_topic/track_ugcs',
            '6star',
            '#b300ff',
            'dataFeed'
          ],
          [
            'btn.czml.stomp',
            'tak',
            'TAK',
            null,
            '/exchange/czml_test_topic/track_tak',
            '6star',
            '#66ffff',
            'dataFeed'
          ],
          [
            'btn.czml.stomp',
            'nasa_utm',
            'USS Telemetry',
            null,
            '/exchange/czml_test_topic/track_nasa_utm',
            'square',
            '#dfb500',
            'dataFeed'
          ],
          [
            'btn.czml.stomp',
            'bcastrid',
            'Broadcast RID',
            null,
            '/exchange/czml_test_topic/track_bcastrid',
            'square',
            '#d9d9d9',
            'dataFeed'
          ],
          [
            'btn.czml.stomp',
            'pierce_rid',
            'Pierce RID',
            null,
            '/exchange/czml_test_topic/track_pierce_rid',
            'square',
            '#5b8bb1',
            'dataFeed'
          ],
          [
            'btn.czml.stomp',
            'ninja',
            'NINJA',
            null,
            '/exchange/czml_test_topic/track_ninja',
            'circle',
            '#ff2222',
            'dataFeed'
          ],
          [
            'btn.czml.stomp',
            'dowding',
            'Dowding',
            null,
            '/exchange/czml_test_topic/track_dowding',
            'circle',
            '#0000ff',
            'dataFeed'
          ],
          [
            'btn.czml.stomp',
            'sagetech_mxs',
            'Sagetech MXS',
            null,
            '/exchange/czml_test_topic/track_sagetech_mxs',
            'circle',
            '#00ff92',
            'dataFeed'
          ],
          [
            'btn.czml.stomp',
            'fortem_trueview_r20',
            'Fortem',
            null,
            '/exchange/czml_test_topic/track_fortem_trueview_r20',
            'circle',
            '#b200ff',
            'dataFeed'
          ],
          [
            'btn.czml.stomp',
            'matrixspace',
            'MatrixSpace',
            null,
            '/exchange/czml_test_topic/track_matrixspace',
            'circle',
            '#ffffff',
            'dataFeed'
          ],
          [
            'btn.czml.stomp',
            'rigitech',
            'RigiTech',
            null,
            '/exchange/czml_test_topic/track_rigitech',
            '6star',
            '#ff00f2',
            'dataFeed'
          ],
          [
            'btn.czml.stomp',
            'fuse_cuas',
            'FUSE C-UAS',
            null,
            '/exchange/czml_test_topic/track_fuse_cuas',
            'circle',
            '#f205ea',
            'dataFeed'
          ],
          [
            'btn.czml.stomp',
            'fuse_surv',
            'FUSE Surveillance',
            null,
            '/exchange/czml_test_topic/track_fuse_surv',
            'circle',
            '#05f2da',
            'dataFeed'
          ],
          {
            id: 'r1400s',
            type: 'button-group',
            config: {
              title: 'R1400s',
              iconUrl: '/assets/icons/hamburger_icon.png',
              buttons: [
                [
                  'btn.czml.stomp',
                  '18c3',
                  'R1400 - Griffiss',
                  null,
                  '/exchange/czml_test_topic/track_gryphon_18c3',
                  'circle',
                  '#df5fdd',
                  'dataFeed'
                ],
                [
                  'btn.czml.stomp',
                  '18c4',
                  'R1400 - Oriskany',
                  null,
                  '/exchange/czml_test_topic/track_gryphon_18c4',
                  'circle',
                  '#ff8000',
                  'dataFeed'
                ],
                [
                  'btn.czml.stomp',
                  '18c5',
                  'R1400 - Verona',
                  null,
                  '/exchange/czml_test_topic/track_gryphon_18c5',
                  'circle',
                  '#ff33cc',
                  'dataFeed'
                ],
                [
                  'btn.czml.stomp',
                  '18c6',
                  'R1400 - Cicero',
                  null,
                  '/exchange/czml_test_topic/track_gryphon_18c6',
                  'circle',
                  '#00ffff',
                  'dataFeed'
                ],
                [
                  'btn.czml.stomp',
                  '18c7',
                  'R1400 - Lakeport',
                  null,
                  '/exchange/czml_test_topic/track_gryphon_18c7',
                  'circle',
                  '#ffff00',
                  'dataFeed'
                ],
                [
                  'btn.czml.stomp',
                  '18c8',
                  'R1400 - Vienna',
                  null,
                  '/exchange/czml_test_topic/track_gryphon_18c8',
                  'circle',
                  '#00ff92',
                  'dataFeed'
                ],
                [
                  'btn.czml.stomp',
                  '18c9',
                  'R1400 - Lee',
                  null,
                  '/exchange/czml_test_topic/track_gryphon_18c9',
                  'circle',
                  '#ffc812',
                  'dataFeed'
                ],
                [
                  'btn.czml.stomp',
                  '18ca',
                  'R1400 - Mobile',
                  null,
                  '/exchange/czml_test_topic/track_gryphon_18ca',
                  'circle',
                  '#288486',
                  'dataFeed'
                ],
                [
                  'btn.czml.stomp',
                  '18cb',
                  'R1400 - Wampsville',
                  null,
                  '/exchange/czml_test_topic/track_gryphon_18cb',
                  'circle',
                  '#51f933',
                  'dataFeed'
                ]
              ]
            }
          }
        ]
      }
    },
    {
      id: 'utm',
      type: 'button-group',
      config: {
        title: 'UTM',
        iconUrl: '/assets/icons/utm_button.png',
        buttons: [
          [
            'btn.czml.rest',
            'operations',
            'Operations',
            null,
            '/api/utm-relay/utm/operations?lat=${envelope_center_lat}&lon=${envelope_center_lon}&radius=6076200',
            2000,
            'blue-cube',
            '#0000ff',
            'utm'
          ],
          [
            'btn.czml.rest',
            'constraints',
            'Constraints',
            null,
            '/api/utm-relay/utm/constraints?lat=${envelope_center_lat}&lon=${envelope_center_lon}&radius=6076200',
            2000,
            'red-cube',
            '#ff0000',
            'utm'
          ],
          [
            'utm-table-overlay'
          ]
        ]
      }
    },
    {
      id: 'dart',
      type: 'button-group',
      config: {
        title: 'DART',
        iconUrl: '/assets/icons/dart.png',
        settingsId: 'dart-config',
        buttons: [
          [
            'btn.czml.stomp',
            'ninja-dart',
            'DART: NINJA',
            null,
            '/exchange/dart_alerts/ninja-dart',
            'dart-circle',
            '#262626'
          ],
          [
            'btn.czml.stomp',
            'nasa_utm-dart',
            'DART: USS Telemetry',
            null,
            '/exchange/dart_alerts/nasa_utm-dart',
            'dart-circle',
            '#262626'
          ],
          [
            'btn.czml.stomp',
            'src-dart',
            'DART: LSTAR',
            null,
            '/exchange/dart_alerts/src-dart',
            'dart-circle',
            '#262626'
          ],
          [
            'btn.czml.stomp',
            'dowding-dart',
            'DART: Dowding',
            null,
            '/exchange/dart_alerts/dowding-dart',
            'dart-circle',
            '#262626'
          ],
          [
            'btn.czml.stomp',
            'mplan-dart',
            'DART: GCS - Mission Planner',
            null,
            '/exchange/dart_alerts/mplan-dart',
            'dart-circle',
            '#262626'
          ]
        ]
      }
    },
    {
      id: 'infrastructure',
      type: 'button-group',
      config: {
        title: 'Infrastructure',
        iconUrl: '/assets/icons/infrastructure_button.png',
        showAll: false,
        buttons: [
          {
            id: 'federal',
            type: 'button-group',
            config: {
              title: 'Federal Assets',
              iconUrl: '/assets/icons/hamburger_icon.png',
              buttons: [
                [
                  'btn.czml.rest',
                  'ddpoint',
                  'DoD Facilities',
                  '/assets/map_icons/dod_icon.png',
                  '/api/asset-server/infrastructure/dod_points?return_type=czml&query_type=envelope&north=${envelope_north}&south=${envelope_south}&east=${envelope_east}&west=${envelope_west}',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ],
                [
                  'btn.czml.rest',
                  'usbordr',
                  'US Borders',
                  '/assets/map_icons/national_border.png',
                  '/api/asset-server/borders/lines?return_type=czml',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ]
              ]
            }
          },
          {
            id: 'firstresp',
            type: 'button-group',
            config: {
              title: 'First Responders',
              iconUrl: '/assets/icons/hamburger_icon.png',
              buttons: [
                [
                  'btn.czml.rest',
                  'lawenfc',
                  'Law Enforcement',
                  '/assets/map_icons/law_enforcement_icon.png',
                  '/api/asset-server/infrastructure/law_enforcement?return_type=czml&query_type=envelope&north=${envelope_north}&south=${envelope_south}&east=${envelope_east}&west=${envelope_west}',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ],
                [
                  'btn.czml.rest',
                  'firedpt',
                  'Fire Stations',
                  '/assets/map_icons/fire_station_icon.png',
                  '/api/asset-server/infrastructure/fire_stations?return_type=czml&query_type=envelope&north=${envelope_north}&south=${envelope_south}&east=${envelope_east}&west=${envelope_west}',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ],
                [
                  'btn.czml.rest',
                  'emergms',
                  'EMS',
                  '/assets/map_icons/ems_icon.png',
                  '/api/asset-server/infrastructure/ems?return_type=czml&query_type=envelope&north=${envelope_north}&south=${envelope_south}&east=${envelope_east}&west=${envelope_west}',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ]
              ]
            }
          },
          {
            id: 'airspace',
            type: 'button-group',
            config: {
              title: 'Special Use Airspace or Restricted Airspace',
              iconUrl: '/assets/icons/hamburger_icon.png',
              buttons: [
                [
                  'btn.czml.rest',
                  'laancfm',
                  'LAANC UAS Facility Maps',
                  '/assets/map_icons/laanc.png',
                  '/api/asset-server/infrastructure/laanc_facmaps?return_type=czml&north=${envelope_north}&south=${envelope_south}&east=${envelope_east}&west=${envelope_west}',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ],
                [
                  'btn.czml.rest',
                  'uasrest',
                  'UAS Restricted Airspace',
                  '/assets/map_icons/uasrest.png',
                  '/api/asset-server/infrastructure/uasrest?return_type=czml&north=${envelope_north}&south=${envelope_south}&east=${envelope_east}&west=${envelope_west}',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ],
                [
                  'btn.czml.rest',
                  'airobst',
                  'Airspace Obstacles',
                  '/assets/map_icons/obstacle_icon.png',
                  '/api/asset-server/infrastructure/airobst?query_type=envelope&return_type=czml&north=${envelope_north}&south=${envelope_south}&east=${envelope_east}&west=${envelope_west}',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ],
                [
                  'btn.czml.rest',
                  'sua_moa',
                  'Special Use Airspace: MOA',
                  '/assets/map_icons/sua_moa.png',
                  '/api/asset-server/infrastructure/sua?return_type=czml&sua_type=MOA',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ],
                [
                  'btn.czml.rest',
                  'sua_restricted',
                  'Special Use Airspace: Restricted',
                  '/assets/map_icons/sua_restricted.png',
                  '/api/asset-server/infrastructure/sua?return_type=czml&sua_type=R',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ],
                [
                  'btn.czml.rest',
                  'sua_warning',
                  'Special Use Airspace: Warning',
                  '/assets/map_icons/sua_warn.png',
                  '/api/asset-server/infrastructure/sua?return_type=czml&sua_type=W',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ],
                [
                  'btn.czml.rest',
                  'sua_alert',
                  'Special Use Airspace: Alert',
                  '/assets/map_icons/sua_alert.png',
                  '/api/asset-server/infrastructure/sua?return_type=czml&sua_type=A',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ],
                [
                  'btn.czml.rest',
                  'sua_prohibited',
                  'Special Use Airspace: Prohibited',
                  '/assets/map_icons/sua_prohib.png',
                  '/api/asset-server/infrastructure/sua?return_type=czml&sua_type=P',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ],
                [
                  'btn.czml.rest',
                  'sua_d',
                  'Special Use Airspace: Danger',
                  '/assets/map_icons/sua_danger.png',
                  '/api/asset-server/infrastructure/sua?return_type=czml&sua_type=D',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ],
                [
                  'btn.czml.rest',
                  'sua_ada',
                  'Special Use Airspace: ADA',
                  '/assets/map_icons/sua_ada.png',
                  '/api/asset-server/infrastructure/sua?return_type=czml&sua_type=ADA',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ]
              ]
            }
          },
          {
            id: 'transportation',
            type: 'button-group',
            config: {
              title: 'Transportation',
              iconUrl: '/assets/icons/hamburger_icon.png',
              buttons: [
                [
                  'btn.czml.rest',
                  'airport',
                  'Airports',
                  '/assets/map_icons/airport_icon.png',
                  '/api/asset-server/infrastructure/airports?return_type=czml&query_type=envelope&north=${envelope_north}&south=${envelope_south}&east=${envelope_east}&west=${envelope_west}',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ],
                [
                  'btn.czml.rest',
                  'railroads',
                  'Railroads',
                  '/assets/map_icons/railroads.png',
                  '/api/asset-server/infrastructure/railroads?query_type=envelope&return_type=czml&north=${envelope_north}&south=${envelope_south}&east=${envelope_east}&west=${envelope_west}',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ],
                [
                  'btn.czml.rest',
                  'wtrport',
                  'Ports',
                  '/assets/map_icons/port_icon.png',
                  '/api/asset-server/infrastructure/ports?return_type=czml&query_type=envelope&north=${envelope_north}&south=${envelope_south}&east=${envelope_east}&west=${envelope_west}',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ],
                [
                  'btn.czml.rest',
                  'bridges',
                  'Bridges',
                  '/assets/map_icons/bridge_icon.png',
                  '/api/asset-server/infrastructure/bridges?query_type=envelope&return_type=czml&north=${envelope_north}&south=${envelope_south}&east=${envelope_east}&west=${envelope_west}',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ]
              ]
            }
          },
          {
            id: 'utils',
            type: 'button-group',
            config: {
              title: 'Utilities',
              iconUrl: '/assets/icons/hamburger_icon.png',
              buttons: [
                [
                  'btn.czml.rest',
                  'power_production',
                  'Power Production',
                  '/assets/map_icons/Power_Plant.png',
                  '/api/asset-server/infrastructure/power_production?query_type=envelope&return_type=czml&north=${envelope_north}&south=${envelope_south}&east=${envelope_east}&west=${envelope_west}',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ],
                [
                  'btn.czml.rest',
                  'power_lines',
                  'Power Lines',
                  '/assets/map_icons/power_lines.png',
                  '/api/asset-server/infrastructure/power_lines?return_type=czml&north=${envelope_north}&south=${envelope_south}&east=${envelope_east}&west=${envelope_west}',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ],
                [
                  'btn.czml.rest',
                  'celltwr',
                  'Cell Towers',
                  '/assets/map_icons/celltwr_icon.png',
                  '/api/asset-server/infrastructure/cell_towers?return_type=czml&query_type=envelope&north=${envelope_north}&south=${envelope_south}&east=${envelope_east}&west=${envelope_west}',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ],
                [
                  'btn.czml.rest',
                  'pipelin',
                  'Pipelines',
                  '/assets/map_icons/pipelines.png',
                  '/api/asset-server/infrastructure/pipelines?return_type=czml&query_type=envelope&north=${envelope_north}&south=${envelope_south}&east=${envelope_east}&west=${envelope_west}',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ]
              ]
            }
          },
          {
            id: 'raw_mats',
            type: 'button-group',
            config: {
              title: 'Raw Materials',
              iconUrl: '/assets/icons/hamburger_icon.png',
              buttons: [
                [
                  'btn.czml.rest',
                  'watertr',
                  'Water Treatment',
                  '/assets/map_icons/wwt_icon.png',
                  '/api/asset-server/infrastructure/waste_water?return_type=czml&query_type=envelope&north=${envelope_north}&south=${envelope_south}&east=${envelope_east}&west=${envelope_west}',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ],
                [
                  'btn.czml.rest',
                  'minesuv',
                  'Mines',
                  '/assets/map_icons/mine_icon.png',
                  '/api/asset-server/infrastructure/mines?return_type=czml&query_type=envelope&north=${envelope_north}&south=${envelope_south}&east=${envelope_east}&west=${envelope_west}',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ]
              ]
            }
          },
          {
            id: 'controlled_fac',
            type: 'button-group',
            config: {
              title: 'Protected or Controlled Facilities',
              iconUrl: '/assets/icons/hamburger_icon.png',
              buttons: [
                [
                  'btn.czml.rest',
                  'schools',
                  'Schools',
                  '/assets/map_icons/school_icon_15.png',
                  '/api/asset-server/infrastructure/schools?return_type=czml&query_type=envelope&north=${envelope_north}&south=${envelope_south}&east=${envelope_east}&west=${envelope_west}',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ],
                [
                  'btn.czml.rest',
                  'hspital',
                  'Hospitals',
                  '/assets/map_icons/hospital_icon.png',
                  '/api/asset-server/infrastructure/hospitals?return_type=czml&query_type=envelope&north=${envelope_north}&south=${envelope_south}&east=${envelope_east}&west=${envelope_west}',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ],
                [
                  'btn.czml.rest',
                  'manufct',
                  'Manufacturing',
                  '/assets/map_icons/factory_icon.png',
                  '/api/asset-server/infrastructure/manufacturing?return_type=czml&query_type=envelope&north=${envelope_north}&south=${envelope_south}&east=${envelope_east}&west=${envelope_west}',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ],
                [
                  'btn.czml.rest',
                  'stadium',
                  'Stadiums',
                  '/assets/map_icons/Stadium.png',
                  '/api/asset-server/infrastructure/stadiums?return_type=czml&query_type=envelope&north=${envelope_north}&south=${envelope_south}&east=${envelope_east}&west=${envelope_west}',
                  -1,
                  null,
                  null,
                  'infrastructure'
                ]
              ]
            }
          }
        ]
      }
    },
    {
      id: 'map_tools',
      type: 'button-group',
      config: {
        title: 'Map Tools',
        iconUrl: '/assets/icons/measure_button.png',
        // showAll: false,
        buttons: [
          [
            'btn.measure.distance-finder',
            'distance-finder',
            'Distance Finder',
            ''
          ],
          [
            'btn.measure.point-selection',
            'point-selection',
            'Point Selection',
            ''
          ]
        ]
      }
    },
    {
      id: 'deployment_files',
      type: 'button-group',
      config: {
        title: 'Deployment Assets',
        iconUrl: '/assets/icons/macdill_button.png',
        showAll: false,
        buttons: [
          {
            id: 'macdill_ninja_net',
            type: 'button-group',
            config: {
              title: 'MacDill Ninja Network',
              iconUrl: '/assets/icons/hamburger_icon.png',
              showAll: true,
              buttons: [
                [
                  'btn.czml.rest',
                  'macdill_ninja',
                  'Ninja Gen2',
                  '/assets/icons/transparent_ph.png',
                  '/api/asset-server/infrastructure/deployment-specific?return_type=czml&query_type=specific&value=macdill_ninja',
                  -1,
                  null,
                  null,
                  null
                ],
                [
                  'btn.czml.rest',
                  'centcom_trx',
                  'Centcom TRx',
                  '/assets/icons/transparent_ph.png',
                  '/api/asset-server/infrastructure/deployment-specific?return_type=czml&query_type=specific&value=centcom_trx',
                  -1,
                  null,
                  null,
                  null
                ],
                [
                  'btn.czml.rest',
                  'atc_trx',
                  'ATC TRx',
                  '/assets/icons/transparent_ph.png',
                  '/api/asset-server/infrastructure/deployment-specific?return_type=czml&query_type=specific&value=atc_trx',
                  -1,
                  null,
                  null,
                  null
                ],
                [
                  'btn.czml.rest',
                  'marina_trx',
                  'Marina TRx',
                  '/assets/icons/transparent_ph.png',
                  '/api/asset-server/infrastructure/deployment-specific?return_type=czml&query_type=specific&value=marina_trx',
                  -1,
                  null,
                  null,
                  null
                ],
                [
                  'btn.czml.rest',
                  'alert',
                  'Alert TRx',
                  '/assets/icons/transparent_ph.png',
                  '/api/asset-server/infrastructure/deployment-specific?return_type=czml&query_type=specific&value=alert',
                  -1,
                  null,
                  null,
                  null
                ],
                [
                  'btn.czml.rest',
                  'fire',
                  'Fire TRx',
                  '/assets/icons/transparent_ph.png',
                  '/api/asset-server/infrastructure/deployment-specific?return_type=czml&query_type=specific&value=fire',
                  -1,
                  null,
                  null,
                  null
                ],
                [
                  'btn.czml.rest',
                  'golf',
                  'Golf TRx',
                  '/assets/icons/transparent_ph.png',
                  '/api/asset-server/infrastructure/deployment-specific?return_type=czml&query_type=specific&value=golf',
                  -1,
                  null,
                  null,
                  null
                ]
              ]
            }
          },
          [
            'btn.czml.rest',
            'boathouse',
            'Boathouse',
            '/assets/icons/transparent_ph.png',
            '/api/asset-server/infrastructure/deployment-specific?return_type=czml&query_type=specific&value=boathouse',
            -1,
            null,
            null,
            null
          ],
          [
            'btn.czml.rest',
            'atc_tower',
            'ATC Tower',
            '/assets/icons/transparent_ph.png',
            '/api/asset-server/infrastructure/deployment-specific?return_type=czml&query_type=specific&value=atc_tower',
            -1,
            null,
            null,
            null
          ],
          [
            'btn.czml.rest',
            'windtalker',
            'Windtalker',
            '/assets/icons/transparent_ph.png',
            '/api/asset-server/infrastructure/deployment-specific?return_type=czml&query_type=specific&value=windtalker',
            -1,
            null,
            null,
            null
          ],
          [
            'btn.czml.rest',
            'perimeter',
            'Base Perimeter',
            '/assets/icons/transparent_ph.png',
            '/api/asset-server/infrastructure/deployment-specific?return_type=czml&query_type=specific&value=macdill_perimeter',
            -1,
            null,
            null,
            null
          ],
          [
            'btn.czml.rest',
            'v2high',
            'V2 High',
            '/assets/icons/transparent_ph.png',
            '/api/asset-server/infrastructure/deployment-specific?return_type=czml&query_type=specific&value=macdill_v2High',
            -1,
            null,
            null,
            null
          ],
          [
            'btn.czml.rest',
            'class_D',
            'Class D Airspace',
            '/assets/icons/transparent_ph.png',
            '/api/asset-server/infrastructure/deployment-specific?return_type=czml&query_type=specific&value=macdill_ClassD',
            -1,
            null,
            null,
            null
          ],
          [
            'btn.czml.rest',
            'hta',
            'Helicopter Training Area (HTA)',
            '/assets/icons/transparent_ph.png',
            '/api/asset-server/infrastructure/deployment-specific?return_type=czml&query_type=specific&value=macdill_hta',
            -1,
            null,
            null,
            null
          ]
        ]
      }
    },
    {
      type: 'attribution',
      config: {
        title: 'AX Enterprize',
        iconAlt: 'AX Enterprize',
        linkUrl: 'https://www.axenterprize.com',
        iconUrl: '/assets/icons/ax_logo.png'
      }
    },
    {
      type: 'bookmarks',
      config: {
        title: 'Bookmarks',
        bookmarks: [
          { name: 'test', lat: 0, lon: 0, alt: 5000}
        ]
      }
    } as any
  ];


export const DEFAULT_CONFIG: DashboardConfig = {
  general: {
    timeline: false,
    labelConfig: {
      showAlt: true,
      showId: true,
      showSpeed: true,
      altUnits: 'm',
      altMethod: 'Hae',
      altFormat: 'altHae_m',
      speedUnits: 'm',
      speedTime: 's',
      speedFormat: 'speed_m_s',
    },
  },
  controls,
  dart: {
    enabled: true,
    url: 'https://safire-sandbox.testing.axenterprize.com/api/dart'
  },
  states: {
    src: false,
    live_tracks: {
      src: false,
      saab: false,
      saab_smr: false,
      pingstation: true,
      mplan: false,
      piccolo: false,
      ugcs: false,
      tak: false,
      nasa_utm: false,
      bcastrid: false,
      pierce_rid: false,
      ninja: false,
      dowding: false,
      sagetech_mxs: false,
      fortem_trueview_r20: false,
      matrixspace: false,
      rigitech: false,
      fuse_cuas: false,
      fuse_surv: false,
      r1400s: {
        '18c3': false,
        '18c4': false,
        '18c5': false,
        '18c6': false,
        '18c7': false,
        '18c8': false,
        '18c9': false,
        '18ca': false,
        '18cb': false
      }
    },
    utm: {
      operations: false,
      constraints: false
    },
    dart: {
      'ninja-dart': false,
      'nasa_utm-dart': false,
      'src-dart': false,
      'dowding-dart': false,
      'mplan-dart': false
    },
    infrastructure: {
      federal: {
        ddpoint: false,
        brdrxng: false,
        usbordr: false
      },
      firstresp: {
        lawenfc: false,
        firedpt: false,
        emergms: false
      },
      airspace: {
        laancfm: false,
        uasrest: false,
        airobst: false,
        sua_moa: false,
        sua_restricted: false,
        sua_warning: false,
        sua_alert: false,
        sua_prohibited: false,
        sua_d: false,
        sua_ada: false
      },
      transportation: {
        airport: false,
        railroads: false,
        wtrport: false,
        bridges: false
      },
      utils: {
        power_production: false,
        power_lines: false,
        celltwr: false,
        pipelin: false
      },
      raw_mats: {
        watertr: false,
        minesuv: false
      },
      controlled_fac: {
        prisons: false,
        schools: false,
        hspital: false,
        manufct: false,
        stadium: false
      }
    },
    map_tools: {
      'distance-finder': false,
      'point-selection': false
    },
    deployment_files: {
      macdill_ninja_net: {
        macdill_ninja: false,
        centcom_trx: false,
        atc_trx: false,
        marina_trx: false,
        alert: false,
        fire: false,
        golf: false
      },
      boathouse: false,
      atc_tower: false,
      windtalker: false,
      perimeter: false,
      v2high: false,
      class_D: false,
      hta: false
    }
  },
  legendCategories: {
    dataFeed: 'Data Feeds',
    infrastructure: 'Infrastructure',
    utm: 'UTM'
  },
  mapConfig: {
    layerConfigs: [
      {
        type: 'xyz',
        id: 'USGSImageryTopo',
        name: 'USGSImageryTopo',
        layerType: 'imagery',
        url: 'https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryTopo/MapServer/tile/{z}/{y}/{x}',
        attribution: ''
      },
      {
        type: 'xyz',
        id: 'OpenStreetMap',
        name: 'OpenStreetMap',
        layerType: 'imagery',
        url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: ''
      },
      {
        type: 'xyz',
        id: 'USGSImageryOnly',
        name: 'USGSImageryOnly',
        layerType: 'imagery',
        url: 'https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}',
        attribution: ''
      },
      {
        type: 'ellipsoid-terrain',
        id: 'ellipsoid',
        name: 'Ellipsoid',
        layerType: 'terrain',
        model: 'WGS84'
      },
      {
        type: 'cesium-ion',
        id: 'Cesium',
        name: 'Cesium',
        layerType: 'all',
        layers: [
          {
            id: '1',
            name: 'Cesium World Terrain',
            type: 'terrain'
          } as any,
          {
            id: '2',
            name: 'Bing Maps Aerial',
            type: 'imagery'
          },
          {
            id: '3',
            name: 'Bing Maps Aerial with Labels',
            type: 'imagery'
          },
          {
            id: '4',
            name: 'Bing Maps Road',
            type: 'imagery'
          }
        ],
        access_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI2NGFhNDE2Ny1lOGVlLTQxYjQtOGFmNy0wYjI4MDU1YmNiYTciLCJpZCI6NDM4MDksImlhdCI6MTYzOTQxMjYxNn0.GOO7uA3aevel4Ir3__DAdPxW-xb5zCdawX6kqtOsQuc'
      }
    ],
    defaultImageryLayerId: 'Cesium - 2',
    defaultTerrainLayerId: 'Cesium - 1',
    centerPoint: {
      type: 'Point',
      coordinates: [
        -75.40738821029663,
        43.22716259727749
      ]
    } as any,
    defaultBounds: {
      type: 'Polygon',
      coordinates: [
        [
          [
            -75.50405502319336,
            43.26508131632468
          ],
          [
            -75.32793045043944,
            43.26508131632468
          ],
          [
            -75.32793045043944,
            43.17989530238948
          ],
          [
            -75.50405502319336,
            43.17989530238948
          ]
        ]
      ]
    }
  }
};

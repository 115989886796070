import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

export interface StompConfig {
  url: string;
  exchange: string;
  authHeaders: { [k: string]: string };
  reconnectDelay?: number;
  heartbeatInterval: {
    incoming?: number;
    outgoing?: number;
  };
}

@Injectable({
  providedIn: 'root'
})
export class SafireEnvironmentService {

  constructor(private http: HttpClient) {
  }

  getHost(): string {
    console.debug('Using Default SafireEnvironmentService');

    return 'localhost:4200';
  }

  getStompConfig(): Observable<StompConfig> {
    return this.http.get('/config/client_config').pipe(map((response: any) => {
      const url = new URL(response.url);
      if(location.protocol === 'https:'){
        url.protocol = 'wss:';
      }
      return {
        url: url.toString(),
        exchange: response.exchange,
        authHeaders: response.authHeaders,
        reconnectDelay: response.reconnectDelay || 2000,
        heartbeatInterval: {
          incoming: (response.heartbeatInterval?.heartbeatIncoming) || 4000,
          outgoing: (response.heartbeatInterval?.heartbeatOutgoing) || 4000
        }
      };
    }));
  }

}

import {Injectable} from '@angular/core';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {RxStomp, RxStompConfig} from '@stomp/rx-stomp';
import {SafireEnvironmentService} from './safire-environment.service';

@Injectable({
  providedIn: 'root'
})
export class StompService {
  private stompObservable: ReplaySubject<RxStomp | null>;

  private lastStomp: RxStomp;
  private subscriptions: Subscription[];

  constructor(private safireEnvironmentService: SafireEnvironmentService) {
    this.stompObservable = new ReplaySubject<RxStomp | null>(1);
    this.refreshStompClient();
  }

  private refreshStompClient(): void {
    this.safireEnvironmentService.getStompConfig().subscribe((safireStompConfig) => {
      console.debug('Have stomp config');
      if (this.lastStomp) {
        (this.subscriptions || []).forEach(s => {
          s.unsubscribe();
        });
        this.lastStomp.deactivate();
      }


      const stompConfig: RxStompConfig = {
        // Typically login, passcode and vhost
        // Adjust these for your broker
        connectHeaders: safireStompConfig.authHeaders,

        // Broker URL, should start with ws:// or wss:// - adjust for your broker setup
        brokerURL: safireStompConfig.url,

        // Keep it off for production, it can be quite verbose
        // Skip this key to disable
        /*debug(str) {
          // tslint:disable-next-line:no-console
          console.log('STOMP: ' + str);
        },*/

        // If disconnected, it will retry after 200ms
        reconnectDelay: safireStompConfig.reconnectDelay,
        heartbeatIncoming: safireStompConfig.heartbeatInterval.incoming,
        heartbeatOutgoing: safireStompConfig.heartbeatInterval.outgoing
      };
      // Create an instance. The first RxStomp is the UMD module name and other is the class name
      const rxStomp = new RxStomp();

      // You can set additional configuration here
      rxStomp.configure(stompConfig);

      // Attempt to connect
      rxStomp.activate();
      this.subscriptions = [];
      this.subscriptions.push(rxStomp.stompErrors$.subscribe((e) => {
        console.error('Stomp error: ' + e);
      }));
      this.subscriptions.push(rxStomp.webSocketErrors$.subscribe((e) => {
        console.error('Websocket error: ' + e);
      }));
      this.lastStomp = rxStomp;
      this.stompObservable.next(this.lastStomp);
    });
  }

  getStompClient(): Observable<RxStomp | null> {
    return this.stompObservable;
  }

}

import {StompListenerComponent} from '../czml/stomp-listener/stomp-listener.component';
import {HeadsTailsService} from './heads-tails.service';
import {HeadingPitchRoll} from 'cesium';

export class AffiliationsService {
  public static affiliationIDs = [];
  static drawAffiliations(obj): any {
    let listAdd = true;
    const affiliationState: string = obj.affiliation;
    let returnObj;
    if (affiliationState === 'NONE') {
      // StompListenerComponent.trackDS.entities.getById(obj.id);
      return returnObj;
    }
    else {
      const affiliationCzml = {
        show: !!StompListenerComponent.dartActive,
        id: String(obj.id)
          .replace('_heading', '')
          .replace('_tail', '')
          .replace('-track', '') + '_affiliation',
        name: String(obj.name)
          .replace('_heading', '')
          .replace('_tail', '')
          .replace('-track', '') + '_affiliation',
        type: 'track', // This is a non standard extension for identifying objects as tracks (used for cleanup)
        position: obj.position,
        billboard: {
          image: '/assets/affiliations/Affiliation_' + obj.affiliation + '.png',
          horizontalOrigin: 'CENTER',
          verticalOrigin: 'CENTER',
          pixelOffset: {
            cartesian2: [15.0, -10.0]
          },
          disableDepthTestDistance: 999999,
          heightReference: 'NONE',
          scale: 1
        }
      };
      HeadsTailsService.allIDs.push(affiliationCzml.id);
      // Reassign template values to those sent in the track msg
      returnObj = affiliationCzml;
      for (let i = 0; i < this.affiliationIDs.length; i++) {
        if (this.affiliationIDs[i] === affiliationCzml.id) {
          listAdd = false;
          break;
        }
      }
      if (listAdd) {
        this.affiliationIDs.push(affiliationCzml.id);
      }
      return returnObj;
    }
  }
}


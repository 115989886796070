import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

export enum SafireGroupEventType {
  CLOSED,
  CHILD_OPEN
}

export interface SafireGroupEvent {
  type: SafireGroupEventType;
  sourceId: string;
}


@Injectable()
export class SafireButtonGroupService {

  eventSubject = new Subject<SafireGroupEvent>();

  constructor() {

  }

  listenForEvents(): Observable<SafireGroupEvent> {
    return this.eventSubject;
  }

  notifyOfClose(id: string): void {
    this.eventSubject.next({
      type: SafireGroupEventType.CLOSED,
      sourceId: id
    });
  }
  notifyOfChildOpen(id: string): void {
    this.eventSubject.next({
      type: SafireGroupEventType.CHILD_OPEN,
      sourceId: id
    });
  }

}

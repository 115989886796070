import {Component, EventEmitter, forwardRef, Input, OnDestroy, Output, TemplateRef, ViewChild} from '@angular/core';
import {CesiumComponent} from '../common/CesiumComponent';
import {AXCesiumWidget, CesiumService} from '@ax/ax-angular-map-cesium';
import * as Cesium from 'cesium';
import {Viewer} from 'cesium';
import {HttpClient} from '@angular/common/http';
import {Subscription} from 'rxjs/internal/Subscription';
import {ButtonState, SafireButtonComponent} from '../button/safire-button/safire-button.component';
import {getElementLanguageDirection} from '@cds/core/internal';

export type Bookmark = {
  name: string;
  lon: number;
  lat: number;
  alt: number;
};

@Component({
  selector: 'lib-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.css', '../common/css/button.css', '../common/css/menu-overlay.css', '../radar-group/radar-group.component.css', '../dart-config/dart-config.component.css'],
  providers: [{provide: AXCesiumWidget, useExisting: forwardRef(() => BookmarksComponent)}]
})

export class BookmarksComponent extends CesiumComponent implements OnDestroy {

  @ViewChild('buttonTemplate') buttonTemplate: TemplateRef<any>;
  @ViewChild('widgetTemplate') widgetTemplate: TemplateRef<any>;
  @ViewChild(SafireButtonComponent) button: SafireButtonComponent;
  @Input() btnAlt = 'Bookmarks';
  @Input() btnIcon = '/assets/icons/bookmarks_button.png';
  @Input() loadFromAssetServer = true;
  @Input() bookmarks: Bookmark[] = [
    {name: 'Washington, D.C.', lon: -77.0090630, lat: 38.8898351, alt: 1500},
    {name: 'Los Angeles, California', lon: -118.2520913, lat: 34.0416540, alt: 1500},
    {name: 'New York, New York', lon: -73.9921559, lat: 40.7498592, alt: 1500}

  ];
  private viewer: Viewer;
  private ellipsoid;
  enabled = false;
  private loadSub: Subscription;
  @Output() closed: EventEmitter<ButtonState> = new EventEmitter<ButtonState>();


  get buttonWidget(): TemplateRef<any> {
    return this.buttonTemplate;
  }

  get generalWidget(): TemplateRef<any> {
    return this.widgetTemplate;
  }

  // For adding and deleting bookmarks from the database
  updateBookmarks(action: string, bookmark?: Bookmark): void {
    const crudAlert = document.getElementById('crud-alert');
    if (action === 'POST') {
      const bName = (document.getElementById('book-name') as HTMLInputElement).value;
      if (!bName.length) {
        crudAlert.style.color = 'orange';
        crudAlert.innerHTML = 'Invalid name of new Bookmark. Please try again.';
        this.alertTime();
        return;
      }
      const windowPosition = new Cesium.Cartesian3(this.viewer.container.clientWidth / 2, this.viewer.container.clientHeight / 2);
      const pickRay = this.viewer.scene.camera.getPickRay(windowPosition);
      const pickPosition = this.viewer.scene.globe.pick(pickRay, this.viewer.scene);
      const pickPositionCartographic = this.viewer.scene.globe.ellipsoid.cartesianToCartographic(pickPosition);
      const newLon = pickPositionCartographic.longitude * (180 / Math.PI);
      const newLat = pickPositionCartographic.latitude * (180 / Math.PI);
      const newAlt = this.viewer.scene.globe.ellipsoid.cartesianToCartographic(this.viewer.scene.camera.position).height;
      bookmark = {'name': bName, 'lon': newLon, 'lat': newLat, 'alt': newAlt};
    }
    fetch('/api/asset-server/fly_to_config', {
      method: action,
      body: JSON.stringify(bookmark)
    }).then (response => response.json())
      .then (confirmation => {
        if (confirmation.Status === 'ok') {
          crudAlert.style.color = 'lime';
          crudAlert.innerHTML = 'Bookmarks updated.';
          this.alertTime();
        }
        else {
          crudAlert.style.color = 'orange';
          crudAlert.innerHTML = 'Something has gone wrong. Please try again.';
          this.alertTime();
        }
      }).then (() => { this.load(); })
      .catch(error => console.error('Error loading bookmarks: ' + error));
  }

  alertTime() {
    setTimeout(() => {document.getElementById('crud-alert').innerHTML = '';}, 10000)
  }


  constructor(cesiumService: CesiumService, private http: HttpClient) {
    super(cesiumService);

  }

  onViewerInit(viewer: Viewer): void {
    this.viewer = viewer;


  }

  load(): void {
    this.loadSub?.unsubscribe();
    this.loadSub = this.http.get('/api/asset-server/fly_to_config').subscribe((json) => {
      const tmp = JSON.parse(json['Data'].toString());
      const newBookmarks: Bookmark[] = [];
      for (const bookmark of tmp) {
        newBookmarks.push({
          name: bookmark.name,
          lat: bookmark.lat,
          lon: 'lon' in bookmark ? bookmark.lon : bookmark.long,
          alt: bookmark.alt,
        });
      }
      this.bookmarks = newBookmarks;
    });
  }

  toggle($event: ButtonState): void {
    this.enabled = $event === ButtonState.ON;
    if (this.loadFromAssetServer) {
      this.load();
    }
  }

  goto(bookmark: Bookmark): void {
    this.viewer.camera.flyTo({
      destination: Cesium.Cartesian3.fromDegrees(bookmark.lon, bookmark.lat, bookmark.alt)
    });
  }

  closeWindow(): void {
    this.button?.setState(this.button?.baseState ?? ButtonState.OFF);
    this.closed.emit(ButtonState.OFF);
  }
}

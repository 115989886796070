import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RangeRingComponent} from './range-ring/range-ring.component';
import { PointSelectionComponent } from './point-selection/point-selection.component';
import { GeoidHeightService } from '../common/geoid-height.service';
import { DistanceFinderComponent } from './distance-finder/distance-finder.component';



@NgModule({
  declarations: [RangeRingComponent, PointSelectionComponent, DistanceFinderComponent],
  exports: [RangeRingComponent, PointSelectionComponent, DistanceFinderComponent],
  imports: [
    CommonModule
  ]
})
export class MeasurementModule { }

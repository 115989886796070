import {Component, OnInit} from '@angular/core';
import {CesiumComponent} from '../../../../../safire-components/src/lib/common/CesiumComponent';
import {CesiumService} from '@ax/ax-angular-map-cesium';
import {Viewer} from 'cesium';

@Component({
  selector: 'app-cesium-extra-init',
  templateUrl: './cesium-extra-init.component.html',
  styleUrls: ['./cesium-extra-init.component.css']
})
export class CesiumExtraInitComponent extends CesiumComponent implements OnInit {

  constructor(cesiumService: CesiumService) {
    super(cesiumService);
  }

  ngOnInit(): void {
  }

   onViewerInit(viewer: Viewer): void {
    // Enable FPS display (comment out to disable)
    // viewer.scene.debugShowFramesPerSecond = true;
   }

}

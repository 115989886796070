import {Component, forwardRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {CesiumComponent} from '../common/CesiumComponent';
import {AXCesiumWidget, CesiumService} from '@ax/ax-angular-map-cesium';
import {
  CallbackProperty,
  Cartographic,
  Color,
  ColorMaterialProperty,
  CustomDataSource,
  defined,
  HeightReference,
  Math as cMath,
  Viewer,
  PolygonHierarchy,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType
} from 'cesium';
import {LegendService} from '../common/legend.service';
import {debounceTime, map} from 'rxjs/operators';
import {Subscription, timer} from 'rxjs';
import {DartService} from '../common/dart.service';
import {MuteBoolService} from '../common/mute-bool.service';

export let DartConfigSingleton: DartConfigComponent = null;
enum RadiiMsg {
  success,
  failure
}

/** This will maintain encapsulation and allow for better unit testing and will allow
 * multiple dart-config components to be used on a single page.
 */
@Component({
  selector: 'lib-dart-config',
  templateUrl: './dart-config.component.html',
  styleUrls: ['./dart-config.component.css'],
  providers: [{provide: AXCesiumWidget, useExisting: forwardRef(() => DartConfigComponent)}]
})
export class DartConfigComponent extends CesiumComponent implements OnInit, OnDestroy {
  @ViewChild('generalWidgetTemplate') generalWidgetTemplate: TemplateRef<any>;
  public protectedZones = {zones: []} as { zones: any[] };
  public valuesUpdater = false;
  dartEnabled = false;
  private viewer: Viewer;
  private outerRadius = 15;
  private middleRadius = 15;
  private innerRadius = 15;
  private outerMinRadius = 15;
  private middleMinRadius = 15;
  private innerMinRadius = 15;
  private currOuterRadius = 0;
  private currMiddleRadius = 0;
  private currInnerRadius  = 0;
  private currOuterMinRadius = 0;
  private currMiddleMinRadius = 0;
  private currInnerMinRadius = 0;
  private dartDraw = false;
  private dartGeomClickHandler: ScreenSpaceEventHandler;
  private trailingPoint;
  private ds: CustomDataSource;
  private activeShapePoints = [];
  private floatingPoint;
  private activeShape;
  private handler;
  private pointInd = 0;
  private selectedEntityHandlerRemove;
  private drawInd = false;
  private coordArr = [];
  private zoneNameAlert = false;
  private confGeomMsg = false;
  private geomSuccess = false;
  private legendSub: Subscription;
  private confMsgTimerSub: Subscription;
  private confMsgMinTimerSub: Subscription;
  private confGeomSub: Subscription;
  private muteSub: Subscription;
  private localMute = false;
  public get RadiiMsg(): typeof RadiiMsg{ return RadiiMsg; }
  confMsgRadii: any = '';
  confMsgMinRadii: any = '';

  constructor(protected cesiumService: CesiumService,
              private legendService: LegendService,
              private muteService: MuteBoolService) {
    super(cesiumService);
    DartConfigSingleton = this;
  }

  get generalWidget(): TemplateRef<any> {
    return this.generalWidgetTemplate;
  }

  ngOnInit(): void {
    DartConfigSingleton = this;
  }

  initDartListener(): void {
    /** Intended to acknowledge when DART is enabled to propagate a list of states and to toggle dartEnabled boolean
     */
    this.legendSub = this.legendService.watchForLegendConfigs()
      .pipe(map(config => Object.values(config || {})
          .map(c => Object.values(c.legendItems))
          .reduceRight((acc, cur) => {
            acc.push(...cur);
            return acc;
          }, [])
        )
      )
      .pipe(map(configs => configs.filter(config => config.id.endsWith('-dart'))))
      .pipe(map(configs => {
          return (configs || []).length > 0;
        }),
        /** Delay notification in the event that multiple services are enabled at the same time
         */
        debounceTime(50)
      ).subscribe(e => {
        this.handleDartState(e);
      });


  }

  onViewerInit(viewer: Viewer): void {
    this.initDartListener();
    this.getCurrentRings();
    this.getZones();

    this.muteSub =
      this.muteService.isMuted$.subscribe(isMuted => {
      this.localMute = isMuted;
    });

    /** Set up event handlers
     */
    this.handler = new ScreenSpaceEventHandler(viewer.canvas);
    /** Set up new datasource with which we'll load entities
     */
    this.ds = new CustomDataSource();
    viewer.dataSources.add(this.ds);

    /** Add the trailing red point to while drawing
     */
    this.trailingPoint = this.ds.entities.add({
      id: 'dartTrailingPoint',
      point: {
        show: true,
      },
    });

    /** Left click event handler for draw tool.
     */
    this.handler.setInputAction(event => {
      /** We use `viewer.scene.pickPosition` here instead of `viewer.camera.pickEllipsoid` so that
       * we get the correct point when mousing over terrain.
       */
      if (this.drawInd) {
        const earthPosition = viewer.scene.pickPosition(event.position);
        /** `earthPosition` will be undefined if our mouse is not over the globe.
         */
        if (defined(earthPosition)) {
          const cartographic = Cartographic.fromCartesian(earthPosition);
          /** Grab the long/lat in degrees to later send to the DART new geometry endpoint
           */
          const longitude = parseFloat(cMath.toDegrees(cartographic.longitude).toFixed(7));
          const latitude = parseFloat(cMath.toDegrees(cartographic.latitude).toFixed(7));
          /** Create an arraw of long/lat and push it to the full coordinate array which we will convert to
           * geojson and send upstream
           */
          const tmpCoordArr = [longitude, latitude];
          this.coordArr.push(tmpCoordArr);
          if (this.activeShapePoints.length === 0) {
            this.floatingPoint = this.createPoint(viewer, earthPosition);
            this.activeShapePoints.push(earthPosition);
            /** Create a new polygon hierarchy from clicked positions and send it to draw-shape for live polygon drawing
             */
            const dynamicPositions = new CallbackProperty(() => {
              return new PolygonHierarchy(this.activeShapePoints);
              /** return this.activeShapePoints;
               */
            }, false);

            this.activeShape = this.drawShape(viewer, dynamicPositions);
          }
          /** Push the last clicked point to the polygon coordinates array
           */
          this.activeShapePoints.push(earthPosition);
          /** create a new visible point per polygon vertex
           */
          this.createPoint(viewer, earthPosition);
        }
      }
    }, ScreenSpaceEventType.LEFT_CLICK);

    /** Event listener to disallow selecting various entities in order to disable infobox
     */
    this.selectedEntityHandlerRemove = viewer.selectedEntityChanged.addEventListener((entity) => {
      if (!entity) {
        return;
      }
      if (entity.id === 'dartPolygon' ||
        entity.id.includes('dartPoint')) {
        viewer.selectedEntity = undefined;
      }
    });

    /** Mouse move event handler to generate trailing point
     */
    this.handler.setInputAction(event => {
      if (defined(this.floatingPoint)) {
        const newPosition = viewer.scene.pickPosition(event.endPosition);
        if (defined(newPosition)) {
          this.floatingPoint.position.setValue(newPosition);
          /**Commented out but can be enabled for trailing draw function of polygon
           * this.activeShapePoints.pop();
           * this.activeShapePoints.push(newPosition);
           */
        }
      }
    }, ScreenSpaceEventType.MOUSE_MOVE);

  }

  /** Creates new points based on clicked map positions
   * @param viewer: Cesium viewer
   * @param worldPosition: tracking the mouse position on the map to enter as the newly made point's position
   */
  createPoint(viewer: Viewer, worldPosition): any {
    /** A counter to incrase ID values by 1 in case we ever want to individually reference them
     */
    this.pointInd = this.pointInd + 1;
    return this.ds.entities.add({
      id: 'dartPoint' + String(this.pointInd),
      position: worldPosition,
      point: {
        color: Color.RED,
        pixelSize: 8,
        heightReference: HeightReference.CLAMP_TO_GROUND,
        /** Below comment will resolve points clipping into terrain but create click accuracy issues
         * disableDepthTestDistance: Number.POSITIVE_INFINITY,
         */
      },
    });
  }

  /** Draws new polygon based on passed geographic coordinates
   * @param viewer: Cesium viewer
   * @param positionData: geospatial position data for the newly drawn geometry.
   */
  drawShape(viewer: Viewer, positionData): any {
    let shape;
    shape = this.ds.entities.add({
      id: 'dartPolygon',
      polygon: {
        hierarchy: positionData,
        material: new ColorMaterialProperty(
          Color.BLUE.withAlpha(0.7)
        ),
      },
    });
    return shape;
  }

  /** Toggles draw functionality when DRAW button is clicked
   */
  toggleDraw(): void {
    this.drawInd = !this.drawInd;
    if (!this.drawInd) {
      this.clearDraw();
    }
  }

  /** Function to submit new geometry via API endpoint
   */
  submitGeom(data): void {
    const zoneName = data.name;
    /** Ensure that a name was entered for the new zone
     */
    if (zoneName !== '') {
      this.zoneNameAlert = false;
      /** If the zone name is propagated and there are more than 2 points, then push the first coordinate pair to
       * the end of the coordinatearray to make it compatible with geojson
       */
      this.coordArr.push(this.coordArr[0]);
      /** Template for geojson to be sent to epi endpoint
       */
      const geoBody = {
        name: zoneName,
        active: true,
        geojson: {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: {
                type: 'Polygon',
                coordinates: [
                  this.coordArr
                ]
              }
            }
          ]
        }
      };

      fetch('api/dart/geometry?type=new_zone', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(geoBody)
      })
        .then(response => response.json())
        .then( resp => {
          /** Ensure that success response from API endpoint is true before continuing
           */
          if (resp.Success) {
            /** If successful then toggle draw function to off and add update zone list
             */
            this.toggleDraw();
            this.getZones();
            this.geomSuccess = true;
            this.confGeomSub = timer(10000).subscribe(() => {
              this.geomSuccess = false;
            });
          } else {
            /** Success response was not true then remove the newly added final coordinate pair and alert
             */
            if (resp.Error === 'Duplicate key violation') {
              this.zoneNameAlert = true;
            }
            else {
              this.confGeomMsg = true;
            }
            this.coordArr.pop();
            this.confGeomSub = timer(10000).subscribe(() => {
              this.confGeomMsg = false;
              this.zoneNameAlert = false;
            });
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          this.confGeomMsg = true;
          this.confGeomSub = timer(10000).subscribe(() => {
              this.confGeomMsg = false;
            });

        });
    } else {
      /** If no name was entered then alert the user
       */
        this.zoneNameAlert = true;
    }
  }

  /** Function to clear all polygon drawing currently on screen
   */
  clearDraw(): void {
    /** Remove all entities in the current data source
     */
    this.ds.entities.removeAll();
    /** Reset the point indicator used for entity IDs
     */
    this.pointInd = 0;
    /** Reset all arrays used for polygon creation
     */
    this.activeShapePoints = [];
    this.coordArr = [];
  }

  /** Update the information table when the Radii slider is used
   * @param e: slider data
   */
  sliderUpdate(e): void {
    const outerRing = e.value;
    const outerRingNum = parseInt(outerRing, 10);
    this.outerRadius = Math.round(outerRingNum);
    this.middleRadius = Math.round((outerRingNum / 3) * 2);
    this.innerRadius = Math.round(outerRingNum / 3);
  }

  /** Update the information table when the Minimum Radiii slider is used
   * @param e: form value for geometry name
   */
  sliderMinUpdate(e): void {
    const outerRing = e.value;
    const outerRingNum = parseInt(outerRing, 10);
    this.outerMinRadius = Math.round(outerRingNum);
    this.middleMinRadius = Math.round((outerRingNum / 3) * 2);
    this.innerMinRadius = Math.round(outerRingNum / 3);
  }

  /** Updates current radii portion of slider tables as stored in the db from an API endpoint
   */
  getCurrentRings(): void {
    fetch('/api/dart/settings', {method: 'GET'})
      .then(response => response.json())
      .catch(error => {
        console.error(`[DartConfig] Error fetching Dart settings: ${error}`);
      })
      .then(data => {

        this.currMiddleRadius = parseInt(data.middle_ring, 10);
        this.currInnerRadius = parseInt(data.inner_ring, 10);
        this.currMiddleMinRadius = parseInt(data.middle_min_radius, 10);
        this.currInnerMinRadius = parseInt(data.inner_min_radius, 10);
        /** Update the slider values
         */
        this.currOuterRadius = data.outer_ring;
        this.currOuterMinRadius = data.outer_min_radius;
      }).catch((error) => {
      console.error(`[DartConfig] Error loading Dart settings: ${error}`);
    });
  }

  /** Function to retrieve list of protected zones from db via API endpoint
   */
  getZones(): void {

    fetch('/api/dart/geometry', {method: 'GET'})
      .then(response => response.json())
      .catch((error) => {
        console.error(`[DartConfig] Error fetching Dart Geometry: ${error}`);
      })
      .then(data => {
        /** Reinitialize protected zones to clear it out
         */
        this.protectedZones = {zones: []} as { zones: any[] };
        /** Loop through the received protected zones and re-add them to the cached list
         */
        for (const zone of data.Zones) {
          this.protectedZones.zones.push({name: zone.name, active: zone.active});
        }
      }).catch((error) => {
      console.error(`Error loading Dart Geometry: ${error}`);
    });
  }

  /** Function for updating the listed zones, their active state, and sending new active states to api endpoint
   * @param event: Click event for activating or deactivating zones
   * @param zoneName: Name of the zone to activate or deactivate
   */
  zoneActivator(event, zoneName): void {
    for (const zone of this.protectedZones.zones) {

      if (zone.name === zoneName) {
        fetch('/api/dart/geometry?type=update_zone&name=' +
          zoneName +
          '&active=' +
          !zone.active,
          {
            method: 'POST'
          })
          .then(response => response.json())
          .then(data => {
            /** Ensure that success response from API endpoint is true before continuing
             */
            if (data.Success === true) {
              /** If delete request was successful then update our cached list of zones
               */
              zone.active = !zone.active;
            }
            /** Otherwise reset the checkbox in the UI to its initial state to stay consistent with it's recorded state
             */
            else {
              zone.active = !zone.active;
            }
          })
          .catch((error) => {
            console.error('Error submitting protected zone:', error);
          });
      }
    }
  }

  /** Function to delete zones from the db using an api endpoint
   * @param event: The event to delete a zone
   * @param zoneName: The name of the zone to delete
   */
  deleteZone(event, zoneName): void {
    fetch('/api/dart/geometry?name=' + zoneName, {method: 'DELETE'})
      .then(response => response.json())
      .then(data => {
        /** Ensure that success response from API endpoint is true before continuing
         */
        if (data.Success === true) {
          /** Iterate through the cached list of zones and pop out the deleted zone on success.
           */
          for (let i = 0; i < this.protectedZones.zones.length; i++) {
            if (this.protectedZones.zones[i].name === zoneName) {
              this.protectedZones.zones.splice(i, 1);
            }
          }
        }
      })
      .catch((error) => {
        console.error('Error deleting pretected zone:', error);
      });
  }

  /** Function to submit new alert radii
   */
  submitNewRadii(): void {
    fetch('/api/dart/settings?outer_ring=' +
      this.outerRadius +
      '&middle_ring=' +
      this.middleRadius +
      '&inner_ring=' +
      this.innerRadius,
      {method: 'POST'})
      .then(response => response.json())
      .then(data => {
        /** Ensure that success response from backend is true
         */
        if (data.Success === true) {
          /** Inform of successful submission and update current section of table
           */
          this.confMsgRadii = this.RadiiMsg.success;
          this.getCurrentRings();
        } else {
          /** If success response is not true then alert
           */
          this.confMsgRadii = this.RadiiMsg.failure;
        }
      }).catch(error => {
      this.confMsgRadii = this.RadiiMsg.failure;
    });
    this.confMsgTimerSub = timer(10000).subscribe(() => {
          this.confMsgRadii = '';
        });
  }

  /** Function to submit new minimum alert radii
   */
  submitNewMinRadii(): void {
    fetch('/api/dart/settings?outer_min_radius=' +
      this.outerMinRadius +
      '&middle_min_radius=' +
      this.middleMinRadius +
      '&inner_min_radius=' +
      this.innerMinRadius,
      {method: 'POST'})
      .then(response => response.json())
      .then(data => {
        /** Ensure that success response from backend is true
         */
        if (data.Success === true) {
          /** Inform of successful submission and update current section of table
           */
          this.confMsgMinRadii = this.RadiiMsg.success;
          this.getCurrentRings();
        } else {
          /** If success response is not true then alert
           */
          this.confMsgMinRadii = this.RadiiMsg.failure;
        }
      }).catch(error => {
      this.confMsgMinRadii = this.RadiiMsg.failure;
    });
    this.confMsgMinTimerSub = timer(10000).subscribe(() => {
          this.confMsgMinRadii = '';
        });
  }


  /** Function to close the DART config menu
   */
  hideConfig(): void {
    document.getElementById('dart-config').style.display = 'none';
    /** Just in case they left the draw tool active before closing config menu
     */
    this.drawInd = true;
    this.toggleDraw();
  }

  /** Function which toggles audible DART alerts globally and clears the array of track IDs to ignore audible alerts
   * @param action : accepts toggle and clear
   */
  toneToggle(action): void {
    if (action === 'toggle') {
      DartService.playTone = !DartService.playTone;
    } else if (action === 'clear') {
      DartService.mutedAlerts = [];
      this.muteService.toggleisMuted(false);
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.legendSub?.unsubscribe();
    this.confMsgMinTimerSub?.unsubscribe();
    this.confMsgTimerSub?.unsubscribe();
    this.confGeomSub?.unsubscribe();
    this.muteSub?.unsubscribe();
  }

  /**
   * If this method is called with a true, there is at least 1 dart listener enabled.
   * If this method is called with a false, there are dart listener enabled.
   */
  private handleDartState(newState: boolean): void {
    this.dartEnabled = newState;

  }
}





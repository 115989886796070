import {Component, forwardRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AXCesiumWidget, CesiumService} from '@ax/ax-angular-map-cesium';
import {CesiumComponent} from '../common/CesiumComponent';
import {defined, Entity, Viewer} from 'cesium';
import * as Cesium from 'cesium';
import {uuidv4} from '../common/uuid';
import {HttpClient} from '@angular/common/http';
import { Subscription } from 'rxjs';
import {NoticeService} from '../common/notice.service';
import {DartConfigSingleton} from '../dart-config/dart-config.component';
import {StompListenerComponent} from '../czml/stomp-listener/stomp-listener.component';
import {DartService} from '../common/dart.service';
import {MuteBoolService} from '../common/mute-bool.service';


@Component({
  selector: 'lib-movable-infobox',
  templateUrl: './movable-infobox.component.html',
  styleUrls: ['./movable-infobox.component.css'],
  providers: [{provide: AXCesiumWidget, useExisting: forwardRef(() => MovableInfoboxComponent)}]
})
export class MovableInfoboxComponent extends CesiumComponent implements OnInit, OnDestroy {
  static restAlertInd = 0;
  static utmConnectivityIssues = false;
  @Input() dartUrl = '';
  private id: string;
  private viewer: Viewer;
  private trackID = '';
  private trackEntity: any;
  private muteIndex;
  // public dartCfgRefreshInd: boolean = false; this is for polling not implemented yet


  @ViewChild('widgetTemplate') widgetTemplate: TemplateRef<any>;
  private selectedEntityIgnoreList = [
    'measureLine',
    'pinFollow',
    'pinOne',
    'pinTwo',
    'postOne',
    'postTwo',
    'psTrailingLabel',
    'pointSelLabel',
    'dartPoint',
    'dartPolygon',
    'dartTrailingPoint',
    'radius_1',
    'radius_2',
    'radius_3',
    '_affiliation'
  ];
  private dartNoticeSub: Subscription;
  private htNoticeSub: Subscription;
  private muteSub: Subscription;
  private showAfil = false;
  private localMute = false;
  get generalWidget(): TemplateRef<any> {
    return this.widgetTemplate;
  }
  affiliationSub: Subscription;
  affiliate(action): any {
    this.affiliationSub?.unsubscribe();
    if (action === 'KILL') {
      const confirmation = confirm('Are you sure you want to use countermeasures on ' + String(this.trackID) + '?');
      if (confirmation) {
        this.affiliationSub = this.http.get(`/api/affiliation-service/counter?ident=`
          + this.trackID
          + '&action='
          + action).subscribe((res) => {
          }, (err) => {
          }
        );
      } else {
        alert('No countermeasures taken for ' + this.trackID + '.');
      }
    } else if (action === 'FOCUS') {
      const trackIdent = ['ninja', 'src', 'nasa_utm', 'harris', 'saab', 'tak', 'mplan', 'pingstation', 'publicsafety', 'opendroneid', 'v2v', 'dowding', 'gryphon', 'bcastrid'];
      for (let i = 0; i < trackIdent.length; i++) {
        if (this.trackEntity.id.includes(trackIdent[i])) {
          this.affiliationSub = this.http.get(`${this.dartUrl}/focus?ac_id=${this.trackID}`).subscribe(() => {
            console.debug('DART notified', this.trackID);
          }, (err) => {
            console.error('DART notification error', err);
          });
          console.debug('Reaching out to DART with TrackID', this.trackID);
          break;
        }
      }

    } else {
      const idSet = this.trackEntity.id
        .replace('_heading', '')
        .replace('_tail', '')
        .replace('-track', '')
        .split('-');
      const idSensor = idSet[0];

      idSet.shift(); // Remove the 1st element (sensor) from the list

      const idName = idSet.join('-');
      const affilBody = {
        sensor: idSensor,
        track: idName,
        affiliation: action.replace('MARK_', '')
      };

      this.affiliationSub = this.http.post(`/api/affiliation-service/affiliation`, affilBody).subscribe((res) => {
          console.debug(`${action} complete`, this.trackID);
        }, (err) => {
          console.error(`Error setting affiliation: ${action} error - `, err);
        }
      );
      // this.affiliationSub = this.http.get(`/api/affiliation-service/counter?ident=` + this.trackID + '&action=' + action).subscribe((res) => {
      //     console.log(`${action} complete`, this.trackID);
      //   }, (err) => {
      //     console.error(`${action} error`, err);
      //   }
      // );

    }
  }
  // Takes the track ID of the currently selected entity, propagates the mute/unmute iconography based on ignore status, and adds/removes
  // the id to/from the DART service's audible alerts ignore list depending on action
  muteIndividual(): any{
    this.muteIndex = DartService.mutedAlerts.findIndex(id => id === this.trackID
      .replace('-track', '')
      .replace('_heading', '')
      .replace('_tail', ''));

    if (this.muteIndex > -1) {
      DartService.mutedAlerts.splice(this.muteIndex, 1);
      this.muteService.toggleisMuted(false);

    }
    else {
      DartService.mutedAlerts.push(this.trackID.replace('_heading', '')
        .replace('_tail', '')
        .replace('-track', ''));
      this.muteService.toggleisMuted(true);
    }
  }
  getUTMConn(): boolean {
    return MovableInfoboxComponent.utmConnectivityIssues;
  }

  onViewerInit(viewer: Viewer): void {

    // Set depth test against terrain to false to prevent clipping of entities through 3d terrain
    // viewer.scene.globe.depthTestAgainstTerrain = false;

    this.muteSub =
    this.muteService.isMuted$.subscribe(isMuted => {
    this.localMute = isMuted;
    });

    this.dartNoticeSub = this.noticeService.listenForEvent('dart-config').subscribe(() => {
      console.debug('Opening DART config');
      document.getElementById('dart-config').style.display = 'block';
      document.getElementById('track-config').style.display = 'none';
      // this.dartCfgRefreshInd = true; this is for polling not implemented yet
    });

    this.htNoticeSub = this.noticeService.listenForEvent('ht-config').subscribe(() => {
      console.debug('Opening headings/tails track config');
      document.getElementById('track-config').style.display = 'block';
      DartConfigSingleton.hideConfig();
    });

    const tmpEntity = viewer.entities.add({
      id: 'placeholder',
      point: {
        show: true
      },
    });
    viewer.selectedEntity = tmpEntity;
    // You can use this to change iframe content but functionality is limited
    const changeIframe = document.getElementsByClassName('cesium-infoBox-iframe');
    for (let item = 0; item < changeIframe.length; item++) {
      changeIframe[item].id = 'alterable-iframe';
      const doc = document.getElementById('alterable-iframe');
      // doc.style.minHeight = '-webkit-fill-available';
      doc.setAttribute('sandbox', 'allow-same-origin allow-scripts');
      doc.setAttribute('scrolling', 'no');
    }
    const altered = document.getElementById('alterable-iframe');
    const makeDraggable = '<div class="wrapped-iframe">' + altered.innerHTML + '</div>';
    altered.innerHTML = makeDraggable;
        // element that will be wrapped
    // create wrapper container
    const loader = document.createElement('div');
    loader.setAttribute('style', 'display: flex;');
    // insert wrapper before el in the DOM tree
    altered.parentNode.insertBefore(loader, altered);
    // move el into wrapper
    // loader.appendChild(altered);
    // wrapper.classList.add('loader-container');
    loader.innerHTML = '<div class="loader-container"><div class="spinsies"></div></div>';
    const boxMove = document.getElementsByClassName('cesium-viewer-infoBoxContainer');
    for (let item = 0; item < boxMove.length; item++) {
      boxMove[item].id = 'info-drag';
    }
    const boxHead = document.getElementsByClassName('cesium-infoBox-title');
    for (let item = 0; item < boxHead.length; item++) {
      boxHead[item].id = 'info-dragheader';
    }
    viewer.selectedEntity = undefined;
    const element = document.getElementById('info-drag');
    const theHeader = document.getElementById('info-dragheader');
    element.style.position = 'absolute';
    element.style.top = '0';
    element.style.left = '0';
    element.style.zIndex = '999';
    const dragState = {
      isDragging: false,
      xDiff: 0,
      yDiff: 0,
      x: 0,
      y: 55
    };
    element.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
    function renderWindow(elmnt, myState): void {
      elmnt.style.transform = 'translate(' + myState.x + 'px, ' + myState.y + 'px)';
    }
    function clampX(n): any {
        return Math.min(Math.max(n, 0),
                        // container width - window width
                        window.innerWidth - element.offsetWidth);
    }

    function clampY(n): any {
        return Math.min(Math.max(n, 0), window.innerHeight);
    }

    function onMouseMove(event): void {
        if (dragState.isDragging) {
            dragState.x = clampX(event.pageX - dragState.xDiff);
            /** sets a lower boundary so you can't drag the infobox out-of-frame from the bottom */
            if (clampY(event.pageY - dragState.yDiff) > (window.innerHeight - 60)) {
              dragState.y = clampY(event.pageY - dragState.yDiff) - 60;
            }
            else {
              dragState.y = clampY(event.pageY - dragState.yDiff);
            }
        }
        renderWindow(element, dragState);
    }

    function onMouseDown(event): void {
        dragState.isDragging = true;
        dragState.xDiff = event.pageX - dragState.x;
        dragState.yDiff = event.pageY - dragState.y;
        /** this is to prevent dragging being broken when mousing over an iframe */
        altered.style.pointerEvents = 'none';
    }

    function onMouseUp(): void {
        dragState.isDragging = false;
        /** reinstate appropraite pointer events for the iframe once dragging is complete */
        altered.style.pointerEvents = 'auto';
    }



    const trackList = ['saab', 'saab_smr', 'ninja', 'src', 'tak', 'v2v', 'harris', 'mplan', 'pingstation', 'publicsafety', 'nasa_utm', 'opendroneid', 'gryphon', 'bcastrid', 'pierce_rid', 'dowding', 'sagetech_mxs', 'rigitech', 'piccolo'];

    viewer.selectedEntityChanged.addEventListener(selectedEntity => {
      /**
       * Initiate the loading graphic until the infobox data loads
       */
      loader.style.display = 'flex';
      /**
       * Make sure that the infobox never spawns less than 55 pixels down from top. This protects other screen
       * components such as the UTM table overlay from getting stuck behind the infobox and vice versa.
       */
      if (dragState.y < 55) {
        dragState.y = 55;
      }
      if (Cesium.defined(selectedEntity)) {
        const changedData = viewer.infoBox.frame;
        /**
         * Check for uploaded KML or GEOJSON files and add the appropriate styles to combat Cesium's
         * obnoxious default styles overwriting our previous styling.
         */
        if (selectedEntity.name?.includes('(KML)') || selectedEntity.name?.includes('(CZML)')) {
          /**
           * create an xml dom parser
           */
          const xmlParser = new DOMParser();
          const doc = xmlParser.parseFromString(selectedEntity.description, 'text/html');
          /**
           * loop through all tables, trs, and tds and add the styles necessary to make the rendered tables look
           * standardized compared to track infoboxes
           */
          const theTables = doc.getElementsByTagName('table');
           /**
           * create a new link attribute in the new description div and attach a stylesheet to it
           */
          const theCSS = document.createElement('link');
          /**
           * This stylesheet does most of the heavy lifting for KML and GEOJSON infoboxes
           */
          theCSS.setAttribute('href', '/api/infobox-service/static/styles.css');
          theCSS.setAttribute('rel', 'stylesheet');
          theCSS.setAttribute('type', 'text/css');
          /**
           * this simply pops the new link attribute with the CSS right before any tables in the new description
           */
          if (theTables.length > 0) {
            theTables[0]?.insertBefore(theCSS, null);
          }
          else {
            const divs = doc.getElementsByTagName('div');
            for (let i = 0; i < divs.length; i++) {
              divs[i].removeAttribute('class');
              divs[i].removeAttribute('style');
            }
            if (divs.length > 0) {
              divs[0].insertBefore(theCSS, null);
            }
            else {
              doc.getElementsByTagName('head')[0].insertBefore(theCSS, null);
            }
            selectedEntity.description = new XMLSerializer().serializeToString(doc);
          }
          for (let i = 0; i < theTables.length; i++) {
            theTables[i].removeAttribute('style');
            theTables[i].setAttribute('style', ' width: 100%; font-size: 12px;' +
              'border: 1px solid white; border-collapse: collapse; word-break: break-word;');
          }
          const theTRs = doc.getElementsByTagName('tr');
          const theTDs = doc.getElementsByTagName('td');
          for (let i = 0; i < theTRs.length; i++) {
            theTRs[i].removeAttribute('bgcolor');
            theTRs[i].setAttribute('style', 'width:100%;border-collapse: collapse;');
          }
          for (let i = 0; i < theTDs.length; i++) {
            theTDs[i].removeAttribute('style');
            theTDs[i].setAttribute('style', 'border: 1px solid white; border-collapse: collapse;');
          }
          /**
           * Make sure the new table is serialized to a string so we don't get weird xml errors in the infobox
           */
          let tableCore = selectedEntity.description;
          if (theTables.length > 0) {
            tableCore = new XMLSerializer().serializeToString(theTables[0]);
            /**
             * this is where we actually set the new description
             */
            const newDescription = '<div id="new-desc-wrapper">' +
              tableCore +
              '</div>';
            selectedEntity.description = newDescription;
            }
          }
        if (!(this.selectedEntityIgnoreList.findIndex(id => selectedEntity.id.includes(id)) > 0)) {
          let entityID: string;
          // Special handling for R1400 tracks having alphanumeric sequences after an underscore in their track IDs
          if (selectedEntity.id.includes('gryphon')) {
            entityID = (selectedEntity.id.split('-')[0]).split('_')[0];
          }
          else {
            entityID = selectedEntity.id.split('-')[0];
          }
          if (trackList.indexOf(entityID) >= 0) {
            this.trackEntity = selectedEntity;
            /** First we grab the unique_id for the infobox generator URL
             * and make it the opened iframe's src
             * to access the description select this: selectedEntity.description._intervals._intervals[0].data
             * The scrolling attribute is deprecated, but it's what the Cesium infobox iframes use
             */
            viewer.infoBox.frame.scrolling = 'no';
            /** Use this link for local development (it references the local, deployed infobox service)
             * viewer.infoBox.frame.src =
             * 'https://safire.chaos.axenterprize.com/api/infobox-service/infobox?type=infobox&identifier='
             * + selectedEntity._id;
             */
            const cleanID = selectedEntity.id.replace('_tail', '').replace('_heading', '').replace('-track', '');
            viewer.infoBox.frame.src = '/api/infobox-service/infobox?type=infobox&identifier=' + cleanID;
            viewer.infoBox.frame.onload = () => {
              loader.style.display = 'none';
            };
            const tmpId: string = selectedEntity.id.split('-');
            this.trackID = selectedEntity.id.replace(tmpId[0] + '-', '');
              // .replace('_heading', '')
              // .replace('_tail', '')
              // .replace('-track', '');
            if (StompListenerComponent.dartActive) {
              console.debug('LOADING AFFILIATION MENU');
              this.showAfil = true;
              /** Determine whether the currently selected entity's track ID is included in the DART service's audible alerts ignore
               * list. If it is, it will return an index > -1, otherwise it will return a -1 as false. Assigns it to the private index and
               * updates the mute/unmute iconography accordingly
               */
              this.muteIndex = DartService.mutedAlerts.findIndex(id => id === this.trackID
                .replace('_tail', '')
                .replace('_heading', '')
                .replace('-track', ''));
              if (this.muteIndex > -1) {
                if (this.localMute === false) {
                  this.muteService.toggleisMuted(true);
                }
              } else {
                // DartService.mutedAlerts.push(this.trackID);
                if (this.localMute === true) {
                  this.muteService.toggleisMuted(false);
                }
              }
            } else {
              this.showAfil = false;
            }
            /** Proof of concept for ninja countermeasures implementation
             * if (!this.trackEntity.id.includes('ninja')) {
             *   document.getElementById('kill-but').style.display = 'none';
             * } else {
             *   document.getElementById('kill-but').style.display = 'inline-block';
             * }
             */
          } else {
            if ((selectedEntity.description !== '') && (selectedEntity.description !== undefined)) {
              changedData.src = '';
              /**
               * The scrolling attribute is deprecated, but it's what the Cesium infobox iframes use
               */
              changedData.scrolling = 'auto';
              viewer.infoBox.frame.onload = () => {
              loader.style.display = 'none';
              };
            }
            this.showAfil = false;
          }
        } else {
          viewer.selectedEntity = undefined;
          this.showAfil = false;
        }
      }
      else {
        this.showAfil = false;
      }
    });
  }

  constructor(cesiumService: CesiumService,
              private http: HttpClient,
              private noticeService: NoticeService,
              private muteService: MuteBoolService) {
    super(cesiumService);
    this.id = uuidv4();
    console.debug(`Initing movable-infobox: id: ${this.id}`);


  }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.dartNoticeSub?.unsubscribe();
    this.htNoticeSub?.unsubscribe();
    this.muteSub?.unsubscribe();
  }

}



import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable, of, ReplaySubject} from 'rxjs';
//import geoidCoords from '../../../assets/terrain/geoidCoords.json';
import {Subscription} from "rxjs/internal/Subscription";


const geoidCols = 1441
const geoidRows = 721
const scaleFactor = 4

//const geoidArray: number[] = geoidCoords;

@Injectable({
  providedIn: 'root'
})
export class GeoidHeightService {
  private static heightSub: Subscription;
  private static geoidArray: number[];
  private static geoidSubject: ReplaySubject<number[]> = new ReplaySubject(1)

  constructor(private http: HttpClient) {
    if (!GeoidHeightService.heightSub && !GeoidHeightService.geoidArray) {
      GeoidHeightService.heightSub = this.http.get('/assets/terrain/geoidCoords.json').subscribe((data:number[]) => {
        GeoidHeightService.geoidArray = data;
        GeoidHeightService.geoidSubject.next(data);
      });
    }
  }

  getGeoidHeight(lon: number, lat: number): Observable<number> {
    const haeLon = lon;
    const haeLat = lat;
    const url = `http://${window.location.host}/hae_to_geoid?lat=${haeLat}&lon=${haeLon}`;
    return this.http.get(url).pipe(map((geoHeightResponse: any) => {
      return parseFloat(geoHeightResponse.Response.GeoHeight);
    }));
  }

  getGeoidHeightLocal(lon: number, lat: number): number | null {
    if (!GeoidHeightService.geoidArray) {
      return null;
    }

    const geoidArray = GeoidHeightService.geoidArray;

    if (lon < 0) {
      lon = lon + 360;
    }
    const offsetX = lon * scaleFactor;
    const offsetY = (90 - lat) * scaleFactor;

    const postX = Math.min(Math.floor(offsetX), geoidCols - 2);
    const postY = Math.min(Math.floor(offsetY), geoidRows - 2);

    let index: number = parseInt(String(postY * geoidCols + postX));
    const elevationNW = geoidArray[index];
    const elevationNE = geoidArray[index + 1];

    index = parseInt(String((postY + 1) * geoidCols + postX));
    const elevationSW = geoidArray[index];
    const elevationSE = geoidArray[index + 1];

    const deltaX = offsetX - postX;
    const deltaY = offsetY - postY;

    const upperY = elevationNW + deltaX * (elevationNE - elevationNW);
    const lowerY = elevationSW + deltaX * (elevationSE - elevationSW);

    const response = upperY + deltaY * (lowerY - upperY);

    return response;
  }

}
